import React, { Fragment, useEffect, useState } from "react";
import { service, factories, models } from "powerbi-client";
import fetchWrapper from '../../Common/api/fetchWrapper';
import { API_URL } from '../../Common/Config/Config';
import { CardBody, Card, Button, Container, Row, Col } from "reactstrap";
import { H3 } from '../../AbstractElements';
import subscribeImg from '../../assets/images/subscribe.jpeg';
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { defaultUserType } from "../../Common/api/helper";


const PowerBiReport = (props) => {
    const [errStatus, setErrStatus] = useState(false);
    const [report, setReport] = useState();
    let reportContainer;
    let reportRef = React.createRef();
    const powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);
    let embedContainer = document.getElementById('reportContainer');
    const navigate = useNavigate();

    useEffect(() => {
        if (reportRef !== null) {
            reportContainer = reportRef['current'];
        }
        getEmbedReportDetails();
    }, []);

    const renderPowerBiReport = async (embedToken, embedURL, reportId) => {
        const embedConfiguration = {
            type: "report",
            tokenType: models.TokenType.Embed,
            accessToken: embedToken,
            embedUrl: embedURL,
            id: reportId,
            permissions: models.Permissions.All,
            settings: {
                // filterPaneEnabled: true,
                // navContentPaneEnabled: true
            }
        };
        const report = powerbi.embed(reportContainer, embedConfiguration);
        await new Promise(resolve => report.on('loaded', resolve));
        setReport(report);
    }

    const getEmbedReportDetails = async () => {
        const options = {
            method: 'POST',
            body: props.request_param
        }
        await fetchWrapper(`${API_URL}/reports/reportMetadata`, options)
            .then((res) => {
                if (res && res.json) {
                    renderPowerBiReport(res.json.embedId, res.json.embedUrl, res.json.reportId);
                    console.log(report)

                } else {
                    // console.log(res);
                }
            })
            .catch((err) => {
                setErrStatus(true)
                console.log(err)
            });
    }


    const exportPdf = async () => {
        
        const options = {
            method: 'POST',
            body: props.request_param
        }
        await fetchWrapper(`${API_URL}/reports/reportData/exportFile`, options)
            .then((res) => {
                console.log(res.json)
                if (res.status === 200) {
                    toast.success(`Please use the Report ID ${res.json?.reportId} as reference to download the file`)
                }
            }, (err) => console.log(err))
    }

    return (
        <Fragment>
            <Container fluid={true} >
                <Row>
                    <Col lg="12" >
                        {
                            !errStatus && <div className="mtop-1-hf mb-1">
                                <div>
                                    <Button color="primary" onClick={exportPdf}>PDF Export</Button>
                                </div>
                                {/* <div class="dropdown">
                                    <Button className="dropbtn" color="primary">export</Button>
                                    <div class="dropdown-content">
                                        <a onClick={()=>exportPdf('pdf')}>PDF</a>
                                        <a onClick={()=>exportPdf('pptx')}>PPTX</a>
                                        <a onClick={()=>exportPdf('png')}>PNG</a>
                                    </div>
                                </div> */}
                            </div>
                        }


                        <div
                            className="mtop-1"
                            id="reportContainer"
                            ref={reportRef} >
                            {
                                !errStatus && <div>Loading the report...</div>
                            }
                            {
                                errStatus && <div className="subscribe-card">
                                    <div className="w-55 dashboard-content">
                                        <img src={subscribeImg} width={350} height={350} />
                                        <H3 attrH3={{ className: 'credits-table text-center' }}>Currently  there is no data available to build your dashboard.</H3>

                                    </div>
                                </div>
                            }
                        </div>
                        {/* <iframe title="Test" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=a1b7eb5b-0f49-417a-97f6-41b4eef400b9&autoAuth=true&embeddedDemo=true" frameborder="0" allowFullScreen="true"></iframe> */}
                    </Col>
                </Row>

            </Container>
        </Fragment>
    );
};

export default PowerBiReport;