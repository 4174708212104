import React, { Fragment, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Badge, Label, Button, FormGroup, Spinner } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { defaultUserType, thousandSeparator } from '../../api/helper';
import { connect, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { GrFormUpload } from 'react-icons/gr';
import ButtonLoader from '../ButtonLoader';
import { H3 } from '../../../AbstractElements';
import ReactDatasheet from 'react-datasheet';
import Swal from 'sweetalert2';
import { Breadcrumbs } from '../../../AbstractElements';
import { defaultHeader, scope1, scope2, scope3, biomassEmissions, replaceYear, isNumber } from '../TargetSetting/TargetData';
import { Plus } from 'react-feather';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import { ValueViewer } from '../TargetSetting/TargetData';

const Offset = () => {
    const [offsetGrid, setOffsetGrid] = useState([]);
    const navigate = useNavigate();
    const userType = useSelector(state => state?.user?.userType);
    const [targetDetails, setTargetDetails] = useState();
    const [baseYear, setBaseYear] = useState();
    const [loading, setLoading] = useState();
    const [offsetData, setOffsetData] = useState()

    const defaultHeader = [
        'Description',
        'Base Year',
        'Year 2',
        'Year 3',
        'Year 4',
        'Year 5',
        'Year 6',
        'Year 7',
        'Year 8',
        'Year 9',
        'Year 10',
        'Year 11',
    ]

    const populateYearRows = (baseYear) => {
        let tempData = defaultHeader?.map((item) => {
            return {
                value: item,
                readOnly: true,
                width: 100,
                className: 'menu-style'
            }
        })
        tempData?.map((item, index) => {
            if (index > 0) {
                item.value = Number(baseYear) + (index - 1)
            } else {
                item.value = 'Description'
            }
        })
        return tempData;
    }

    const getTargetDetails = async () => {
        setLoading(true)
        await fetchWrapper(`${API_URL}/emissionTarget/orgSettings`)
            .then((res) => {
                const data = res.json;
                setTargetDetails(data);
                setBaseYear(data?.baseYear);
                const getYearData = populateYearRows(data?.baseYear);
                formatGrid(getYearData);
                setLoading(false)
            }, (err) => {
                setLoading(false)
                console.log(err)
            })
    }

    const loadOffsetData = (data, tableData) => {
        let tempArray = Array(11).fill({ value: '', width: 100, className: 'menu-style' });
        tempArray?.unshift({ value: '', width: 400, className: 'side-menu' });
        if (tableData?.length > 0) {
            tableData?.splice(1, 1);
            for (let i = 1; i <= data?.length; i++) {
                tableData?.splice(i, 0, [...tempArray])
            }
        }
        
        for (let i = 0; i < tableData?.length - 1; i++) {
            for (let j = 0; j < tableData[0]?.length; j++) {
                if (i > 0) {
                    tableData[i][0] = {
                        value: data[i-1]?.description,
                        width: 400
                    }
                    if(j>0) {
                        if(tableData[0][j]?.value === data[i-1]?.targetValues[j-1]?.year){
                            tableData[i][j] = {
                                value: data[i-1]?.targetValues[j-1]?.value,
                                width: 100
                            }
                        }
                    }
                }
            }
        }

        let total = new Array(tableData[0]?.length).fill(0);
        for (let i = 1; i < tableData?.length; i++) {
            for (let j = 1; j < tableData[0]?.length; j++) {
                if (i < (tableData?.length - 1)) {
                    total[j] = total[j] + Number(tableData[i][j]?.value);
                    tableData[tableData?.length - 1][j] = {
                        value: Number(total[j]),
                        width: 100,
                        readOnly: true,
                        className: 'text-right-imp'
                    }
                }

            }
        }
        setOffsetGrid(tableData)
    }

    const getOffsets = async () => {
        await fetchWrapper(`${API_URL}/offsets`)
            .then((res) => {
                console.log(res.json)
                setOffsetData(res?.json)
                loadOffsetData(res?.json, offsetGrid);
            }, (err) => { console.log(err) })
    }

    const addRow = () => {
        const newRow = Array(offsetGrid[0].length).fill({ value: '' });
        let arr = offsetGrid;
        arr.splice(offsetGrid?.length - 1, 0, [...newRow])
        setOffsetGrid([...arr])
    }

    const removeRow = () => {
        let arr = offsetGrid;
        arr.splice(offsetGrid?.length - 2, 1)
        setOffsetGrid([...arr])
    }

    const formatGrid = (populatedHeader) => {
        const headerRow = populatedHeader.map((item, index) => {
            if (index > 0)
                return {
                    value: item?.value,
                    readOnly: true,
                    width: 100,
                    className: 'menu-style'
                }
            else
                return {
                    value: item?.value,
                    readOnly: true,
                    width: 400,
                    className: 'side-menu'
                }
        })
        const tempArray = Array(headerRow.length).fill({ value: '' });
        const totalArray = tempArray.map((item, index) => {
            if (index > 0)
                return {
                    value: '',
                    readOnly: true,
                    width: 100,
                    className: 'menu-style'
                }
            else
                return {
                    value: 'Total reductions',
                    readOnly: true,
                    width: 400,
                    className: 'side-menu'
                }
        })
        setOffsetGrid([[...headerRow], [...tempArray], [...totalArray]])
    }

    useEffect(() => {
        // formatGrid()
        getTargetDetails();
        
    }, [])

    useEffect(()=>{
        getOffsets()
    }, [targetDetails])

    const getTableData = () => {
        const yearValue = offsetGrid[0];
        const offsetData = offsetGrid?.map((item, index) => {
            let itemData = {};
            if (index > 0) {
                itemData.description = item[0]?.value;
                itemData.targetValues = item?.map((i, key) => {
                    if (key > 0) {
                        return {
                            year: yearValue[key]?.value,
                            value: Number(i?.value)
                        }
                    }
                })
                itemData.targetValues?.shift();
                return itemData;
            }
        })
        offsetData?.shift();
        offsetData?.pop();
        return {
            offsetData: offsetData
        }
    }

    const handleSubmit = async () => {
        const options = {
            method: 'POST',
            body: getTableData()
        }
        await fetchWrapper(`${API_URL}/offsets`, options)
            .then(res => {
                if (res.status === 200) {
                    toast.success('Offset data saved Successfully!')
                }
            }, (err) => {
                if (err.status === 200) {
                    toast.success('Offset data saved Successfully!')
                } else {
                    console.log(err)
                    toast.error('Something went wrong!')
                }
            })
    }

    return (
        <Fragment>
            <LayoutLoader />
            <Breadcrumbs mainTitle={'Offsets'} />
            <Row>
                <Col lg='12'>
                    <Card className=''>
                        <CardBody>
                            <div className="d-flex-row">
                                <div className='d-flex mb-1rem'>
                                    <div>[Note: The values are in tCO2e]</div>
                                    <Button color='primary minus-button' onClick={() => navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/buy-offsets`)}>Buy Offsets</Button>
                                </div>
                                <div>
                                    <div style={{ width: '100%', height: '100%' }} className='mtop-1'>
                                        <ReactDatasheet
                                            data={offsetGrid}
                                            valueRenderer={(cell) => cell?.value}
                                            valueViewer={ValueViewer}
                                            onCellsChanged={(changes) => {
                                                let newData = offsetGrid.map((row) => [...row]);
                                                changes.forEach(({ cell, row, col, value }) => {
                                                    let total = 0;
                                                    if (col === 0) {
                                                        newData[row][col] = { ...offsetGrid[row][col], value }
                                                    }
                                                    if (col > 0 && isNumber(value)) {
                                                        newData[row][col] = { ...offsetGrid[row][col], value }
                                                        for (let i = 1; i < offsetGrid.length - 1; i++) {
                                                            total = total + Number(newData[i][col]?.value)
                                                        }
                                                        newData[offsetGrid?.length - 1][col] = { ...offsetGrid[offsetGrid?.length - 1][col], value: thousandSeparator(total), className: 'text-right-imp'}
                                                    }
                                                    setOffsetGrid(newData)
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='d-flex'>
                                    <button className='p-0' onClick={addRow}><div className='add-row'>+</div></button>
                                    <button className='p-0 minus-button' onClick={removeRow}><div className='add-row'>-</div></button>
                                    </div>
                                    
                                </div>
                                <div className='mtop-1'>
                                    <Button color='primary' onClick={handleSubmit}>Submit</Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default Offset;