import React, { Fragment, useState, useEffect, forwardRef, useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Form, FormGroup, Col, Row, Label } from 'reactstrap'
import { Btn } from '../../../AbstractElements'
import { Next, StartDate, EndDate, SaveasDraft, Submit } from '../../../Common/Constant'
import { countries } from '../../../Common/Data/CountryList/Defaultdata';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { DETAILS, PROJECTS_DATA } from '../../../Common/Constant';
import moment from 'moment';
import PeriodicAssesment from './PeriodicAssesment';
import { Link, useNavigate } from 'react-router-dom'
import { handleDraftData, defaultUserType, handleProjectData, defaultMonitoringURL, multiOptions, CustomOptionComponent } from '../../api/helper'
import fetchWrapper from '../../api/fetchWrapper'
import { API_URL } from '../../Config/Config'
import { toast } from 'react-toastify'
import { useParams } from "react-router-dom";
import SweetAlert from 'sweetalert2';
import Select from 'react-select';

const Details = ({ setSteps, setFormdata, formdata, orgData }) => {
    const [startDate, setStartDate] = useState('');
    const [region, setRegion] = useState();
    const [endDate, setendDate] = useState('');
    const [countryList, setCountryList] = useState();
    const [open, setOpen] = useState(false);
    const { register, handleSubmit, formState: { errors }, control, setValue, clearErrors, resetField } = useForm();
    const [assesmentType, setAssesmentType] = useState('PERIODIC');
    const [unitTypeList, setUnitTypeList] = useState([]);
    const [registryTypes, setRegistryTypes] = useState([]);
    const [periodicType, setPeriodicType] = useState();
    const [registry, setRegistry] = useState(formdata?.registry);
    const [registryId, setRegistryId] = useState(formdata?.registryId)
    const navigate = useNavigate();
    const { origin } = useParams();
    const [categoryTypes, setCategoryType] = useState([]);
    const [projectCategoryId, setProjectCategoryId] = useState(formdata?.projectCategoryId);
    const [unitType, setUnitType] = useState(formdata?.unitType);
    const [subCategory, setSubcategory] = useState([]);
    const [subcategoryName, setSubcategoryName] = useState();
    const [subCategoryId, setSubcategoryId] = useState(formdata?.projectSubCategoryId);
    const [subCategoryItemList, setSubcategoryItemList] = useState([]);
    const [subCategoryItemId, setSubcategoryItemId] = useState(formdata?.subCategoryItemId);
    const [asoList, setAsoList] = useState([]);
    const [subCategoryItemName, setSubcategoryItemName] = useState();
    const [loading, setLoading] = useState(false);
    const [monitoringLevel, setMonitoringLevel] = useState();
    const [frequency, setFrequency] = useState();
    const [dataFrequency, setDataFrequency] = useState();
    const days = Array.from({ length: 30 }, (_, i) => i + 1);
    const [sdgGoals, setGoals] = useState([]);
    const [sdgList, setSdgList] = useState([]);
    const [country, setCountry] = useState(formdata?.country)

    const getAllCountry = async () => {
        await fetchWrapper(`${API_URL}/country`)
            .then((res) => {
                setCountryList(res?.json);
            }, (err) => console.log(err))
    }

    const updateValue = ({ target }) => {
        const selectedItem = countryList.find((item) => item.name == target.value);
        setRegion(selectedItem.region);
        setValue('projectCountry', selectedItem?.name);
        setCountry(target.value)
        setValue('countryCode', selectedItem.countryCode);
        setValue('region', selectedItem.region)
        setValue('assetOwnerOrgName', orgData?.companyName)
        clearErrors("projectCountry");
    };

    useEffect(() => { setAssesmentType('PERIODIC'); setValue('assesmentType', 'PERIODIC'); }, [orgData])

    const loadAsoOrgList = async () => {
        await fetchWrapper(`${API_URL}/organization/assetmanager/mappedOrgs`)
            .then((res) => {
                const data = res.json;
                setAsoList(data?.mappedAssetOwners)
            }, (err) => { console.log(err) })
    }

    const loadAsoList = async () => {
        await fetchWrapper(`${API_URL}/organization/type/ASSET_OWNER`)
            .then((res) => {
                const data = res.json;
                setAsoList(data)
            }, (err) => { console.log(err) })
    }

    const dispatch = useDispatch();
    const userType = useSelector(state => state.user?.userType);

    const onSubmit = (data) => {
        setFormdata(prev => ({ ...prev, ...data }))
        setSteps(prev => prev + 1);
    }

    const handleStartDate = date => {
        setStartDate(date);
    };

    const handleToggle = (e) => {
        if (e.target.value === 'PERIODIC') {
            setOpen(true);
            setValue('assesmentType', 'PERIODIC')
            setValue('assesmentTypeValue', 'PERIODIC')
            setAssesmentType('PERIODIC')
        } else {
            setOpen(false);
            setValue('assesmentType', 'ONETIME')
            setValue('assesmentTypeValue', 'ONETIME')
            setAssesmentType('ONETIME')
        }
    }

    const handleSubcategoryItem = (val) => {
        setSubcategoryItemId(val);
        const item = subCategoryItemList.filter((item) => item.id === val);
        setSubcategoryItemName(item?.itemName)
    }

    const handleEndDate = date => {
        setendDate(date);
    };

    const fetchUnitTypes = async (registry) => {
        await fetchWrapper(`${API_URL}/unittypes/${registry}`)
            .then((res) => {
                setUnitTypeList(res.json);
                console.log(res.json)
            })
            .catch(error => console.error(error))
    }

    const getUnitTypes = async (value) => {
        const selectedRegistry = registryTypes.find(i => i.registryId === value);
        setRegistry(selectedRegistry?.registry);
        setRegistryId(value)
        setValue('registry', selectedRegistry?.registry)
        getSubCategoryItems(value)
        await fetchWrapper(`${API_URL}/unittypes/${selectedRegistry?.registry}`)
            .then((res) => {
                setUnitTypeList(res.json);
                console.log(res.json)
            })
            .catch(error => console.error(error))
    }

    const getResitryTypes = async (val) => {
        await fetchWrapper(`${API_URL}/registry/projectcategory/${val}`)
            .then((res) => {
                setRegistryTypes(res.json);
            })
            .catch(error => console.error(error))
    }

    const handleChangeCategory = () => {
        resetField('projectSubCategoryId');
        setSubcategoryId()
        setRegistryId();
        resetField('registryId')
        setSubcategoryItemId();
        resetField('subCategoryItemId')
        setSubcategoryItemId();
        resetField('unitType');
        setUnitType();
    }

    const getSubcategory = async (val) => {
        setProjectCategoryId(val)
        setValue('projectCategoryId', val);

        getResitryTypes(val)
        await fetchWrapper(`${API_URL}/subcategory/category/${val}`)
            .then((res) => {
                setSubcategory(res.json);
            })
            .catch(error => console.error(error))
    }

    const getSubCategoryItems = async (val) => {
        await fetchWrapper(`${API_URL}/subcategoryitems/subcategory/${subCategoryId}/registry/${val}`)
            .then((res) => {
                setSubcategoryItemList(res.json);
            })
            .catch(error => console.error(error))

    }

    const handleSubcategory = (val) => {
        const subcategoryData = subCategory?.filter((i) => i.id === val).map((item) => item?.name)
        setValue('projectSubcategory', subcategoryData[0])
        setSubcategoryName(subcategoryData[0])
        setSubcategoryId(val)
        setRegistryId();
        resetField('registryId')
        setSubcategoryItemId();
        resetField('subCategoryItemId')
        setSubcategoryItemId();
        resetField('unitType');
        setUnitType();
    }

    const fetchData = useCallback(async () => {
        if (formdata?.projectCategoryId) {
            await getSubcategory(formdata?.projectCategoryId)
            await getResitryTypes(formdata?.projectCategoryId)
        }
        if (formdata?.registryId && registryTypes) {
            await fetchUnitTypes(formdata?.registry);
        }
        if (formdata?.registryId && formdata?.projectSubCategoryId) {
            await getSubCategoryItems(formdata?.registryId);
        }
        setSubcategoryId(formdata?.projectSubCategoryId);
        setValue('projectSubCategoryId', formdata?.projectSubCategoryId)
        setRegistryId(formdata?.registryId);
        setValue('registryId', formdata?.registryId)
        setRegistry(formdata?.registry);
        setSubcategoryItemId(formdata?.subCategoryItemId);
        setValue('subCategoryItemId', formdata?.subCategoryItemId);
        setUnitType(formdata?.unitType);
        setValue('unitType', formdata?.unitType);
    }, [formdata]);


    const handleDraft = async (data) => {
        try {
            if (data) {
                let tempData = data;
                tempData.projectorigin = origin;
                const bodyData = handleDraftData(tempData)
                console.log(bodyData)
                const options = {
                    method: 'POST',
                    body: bodyData
                }
                await fetchWrapper(`${API_URL}/projects`, options)
                    .then((res) => {
                        console.log(res)
                        if (res.ok) {
                            toast.success('Project Saved Successfully!')
                            const userType = window.location.pathname.split('/')[1]
                            navigate(`${process.env.PUBLIC_URL}/${userType}/projects`)
                        }
                    })
                    .catch(error => console.error(error))
            }
        } catch (error) {
            if (error.status === 412) {
                console.log(error)
                toast.error(`Please check the Details..`)
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, [fetchData])

    useEffect(() => {
        loadSdgList();
    }, [formdata])

    useEffect(() => {
        if (formdata && formdata?.periodicAssesmentType) {
            setValue('periodicassessmenttype', formdata?.periodicAssesmentType)
            setPeriodicType(formdata?.periodicAssesmentType)
            setValue('frequency', formdata?.frequency)
        }
        console.log(formdata)
        if (formdata) {
            setAssesmentType(formdata?.assesmentType);
            setValue('city', formdata?.city);
            setValue('zipCode', formdata?.zipCode);
            setValue('assesmentType', formdata?.assesmentType);
            setValue('carbonCreditsCalculationDay', formdata?.carbonCreditsCalculationDay)
        }
    }, [formdata])


    const CustomInput = forwardRef((props, ref) => {
        return (
            <div className='form-control' onClick={props.onClick}>
                <label ref={ref} >
                    {props.value || props.placeholder}
                </label>
                <i className="fa fa-calendar input-calendar-ico" style={{ marginTop: '0.1rem' }}></i>
            </div>
        );
    });

    const getProjectCategory = async () => {
        await fetchWrapper(`${API_URL}/projectcategory`)
            .then((res) => {
                setCategoryType(res.json);
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getProjectCategory();
        getAllCountry()
        if (formdata) {
            setValue('projectCategoryId', formdata?.projectCategoryId)
            setValue('registry', formdata?.registry)
            setValue('unitType', formdata?.unitType)
        }
    }, [])

    useEffect(() => {
        if (['ASM_ADMIN', 'ASM_USER'].includes(userType)) {
            loadAsoOrgList()
        }
        if (['SYS_ADMIN', 'SYS_USER'].includes(userType)) {
            loadAsoList()
        }
    }, [])

    const handleDetailSubmit = async (data) => {
        setLoading(true);
        if (data) {
            // setFormdata(prev => ({ ...prev, ...data }))
            const projectData = {
                ...data,
                ...formdata,
                projectorigin: origin
            }
            const bodyData = handleProjectData(projectData, "SUBMITTED")
            const options = {
                method: 'POST',
                body: bodyData
            }
            await fetchWrapper(`${API_URL}/projects`, options)
                .then((res) => {
                    setLoading(false)
                    const data = res.json;
                    sessionStorage.setItem('projectId', data.id)
                    SweetAlert.fire({ title: 'Congratulations!', text: 'Your Project Created Successfully!', icon: 'success' })
                    navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/${defaultMonitoringURL[monitoringLevel]}/${data.id}/${data?.name}`)
                }, (error) => {
                    if (error.status === 412) {
                        setLoading(false)
                        toast.error(`Please check the Details..${JSON.stringify(error.json)}`)
                    }
                })
        }

    }

    const loadSdgList = async () => {
        await fetchWrapper(`${API_URL}/projectsustainablegoals`)
            .then((res) => {
                const data = res.json?.map((ele) => {
                    return {
                        value: ele.id,
                        label: ele.name
                    }
                });
                setGoals(data)
            }, (err) => console.log(err))
    }

    const handleSdgList = (data) => {
        if (data?.length < 6) {
            setSdgList(data)
            let temList = data?.map(i => i.value)
            setValue('sustainableDevelopmentGoals', temList?.toString())
        }
    }

    const handlePeriodic = (val) => {
        setPeriodicType(val)
        setValue('periodicAssesmentType', val)
    }

    // console.log(assesmentType)
    return (
        <Fragment>
            <h2 className="pb-3 text-color">Details</h2>
            <Form onSubmit={handleSubmit(onSubmit)} className="form-bookmark needs-validation">
                <Row>
                    <Col sm='6'>
                        <FormGroup className="mb-3">
                            <label>Project Name <span className='required'>*</span></label>
                            <input className={`form-control mb-1`} placeholder='' type="text"
                                name='projectName' defaultValue={formdata.projectName || ""} {...register('projectName', { required: true })} />
                            <span className='text-danger'>{errors.projectName && errors.projectName.type === 'required' && 'Project Name is required'}</span>
                            {/* {console.log(errors)}                        */}
                        </FormGroup>
                        {console.log(errors)}
                    </Col>
                    <Col sm='6'>
                        <FormGroup className="mb-3">
                            <label>Asset Owner Organization</label>
                            {
                                (['ASO_ADMIN', 'ASO_USER'].includes(userType)) && <input className={`form-control mb-1`}
                                    value={orgData?.companyName} type="text" name='assetCreator'
                                    {...register('assetOwnerOrg')} disabled />
                            }
                            {
                                (['ASM_ADMIN', 'ASM_USER', 'SYS_ADMIN', 'SYS_USER'].includes(userType)) && <select className="form-select"
                                    {...register('selectedAsoOrgId', { required: true })}
                                // onChange={(e) => updateValue(e)} 
                                >
                                    <option value=''>Select Asset Owner Organization</option>
                                    {
                                        ['ASM_ADMIN', 'ASM_USER'].includes(userType) && asoList?.map((item) => {
                                            return (
                                                <option value={item?.assetOwnerId}>{item?.name}</option>
                                            )
                                        })
                                    }
                                    {
                                        ['SYS_ADMIN', 'SYS_USER'].includes(userType) && asoList?.map((item) => {
                                            return (
                                                <option value={item?.id}>{item?.companyName}</option>
                                            )
                                        })
                                    }
                                </select>
                            }
                            <span className="text-danger">{errors.selectedAsoOrgId && 'Asset Owner Organiztion is Required'}</span>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm='6'>
                        <FormGroup className="mb-3">
                            <label>{StartDate}</label>
                            <div className="">
                                <Controller
                                    name='startDate'
                                    control={control}
                                    onChange={handleStartDate}
                                    defaultValue={formdata.startDate || ""}
                                    render={({ field: { onChange, value } }) => {
                                        return (
                                            <DatePicker
                                                className="datepicker-here form-control"
                                                selected={value}
                                                onChange={e => {
                                                    handleStartDate(e);
                                                    onChange(e);
                                                }}
                                                showMonthDropdown
                                                dateFormatCalendar="MMMM"
                                                showYearDropdown
                                                yearDropdownItemNumber={15}
                                                dropdownMode="select"
                                                dateFormat="dd MMM, yyyy"
                                                customInput={<CustomInput />}
                                            // minDate={new Date(moment(new Date()).add(1, 'day'))}
                                            />
                                        )
                                    }}
                                />
                            </div>
                            <span className='text-danger'>{errors.startDate && errors.startDate.type === 'required' && 'Start Date is required'}</span>
                        </FormGroup>
                    </Col>
                    <Col sm='6'>
                        <FormGroup className="mb-3">
                            <label>{EndDate} </label>
                            <Controller
                                name='endDate'
                                control={control}
                                // rules={{ required: true }}
                                onChange={handleEndDate}
                                defaultValue={formdata.endDate || ""}
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <DatePicker
                                            className="datepicker-here form-control"
                                            selected={value}
                                            onChange={e => {
                                                handleEndDate(e);
                                                onChange(e)
                                            }}
                                            showMonthDropdown
                                            dateFormatCalendar="MMMM"
                                            showYearDropdown
                                            yearDropdownItemNumber={15}
                                            dropdownMode="select"
                                            dateFormat="dd MMM, yyyy"
                                            customInput={<CustomInput />}
                                            minDate={new Date(moment(startDate ? startDate : new Date()).add(1, 'day'))}
                                        />
                                    )
                                }}
                            />
                            <span className='text-danger'>{errors.endDate && errors.endDate.type === 'required' && 'End Date is required'}</span>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg='12'>
                        <label>Address <span className="text-danger">*</span></label>
                        <input className={`form-control mb-1`} placeholder='' type="text" name='address'
                            {...register('address', { required: true })} />
                        <span className='text-danger'>{errors.address && errors.address.type === 'required'
                            && 'Address is Required'}</span>
                    </Col>
                </Row>
                <Row>
                    <Col sm='6'>
                        <FormGroup className="mb-3">
                            <label>City <span className="text-danger">*</span></label>
                            <input className={`form-control mb-1`} placeholder='' type="text" name='city'
                                {...register('city', { required: true })} />
                            <span className='text-danger'>{errors.city && errors.city.type === 'required'
                                && 'City is Required'}</span>
                        </FormGroup>
                    </Col>
                    <Col sm='6'>
                        <FormGroup className="mb-3">
                            <label>Zipcode <span className="text-danger">*</span></label>
                            <input className={`form-control mb-1`} placeholder='' type="text" name='zipCode'
                                {...register('zipCode', { required: true, pattern: /[+-]?([0-9]*[.])?[0-9]+$/ })} />
                            <span className='text-danger'>{errors.zipCode && errors.zipCode.type === 'required'
                                && 'Zipcode is Required'}</span>
                            <span className='text-danger'>{errors.zipCode && errors.zipCode.type === 'pattern'
                                && 'Zipcode Value must be decimal'}</span>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm='6'>
                        <FormGroup className="mb-3">
                            <label>Country <span className='required'>*</span></label>
                            <select className="form-select" value={country}
                                {...register('projectCountry', { required: true })} 
                                onChange={(e) => updateValue(e)} >
                                <option value=''>-Select Country-</option>
                                {
                                    countryList?.map((item) =>
                                        <option value={item.name} >{item.name}</option>
                                    )
                                }
                            </select>
                            <span className='text-danger'>{errors.projectCountry && errors.projectCountry.type === 'required' && 'Country is required'}</span>
                        </FormGroup>
                    </Col>
                    <Col sm='6'>
                        <FormGroup className="mb-3">
                            <label>Region</label>
                            <input className={`form-control mb-1`} value={region} type="text"
                                defaultValue={formdata.region || ""} {...register('region')} disabled />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm='6'>
                        <FormGroup className="mb-3">
                            <label>Credits Issuance Method</label>
                            <select className='form-select' {...register('creditsIssuance')} defaultValue={formdata?.creditsIssuance}>
                                <option value=''>Select Credits Issuance Method</option>
                                <option value={'Manual'}>Manual</option>
                                <option value={'Automatic'}>Automatic</option>
                            </select>
                        </FormGroup>
                    </Col>
                    <Col sm='6'>
                        <FormGroup className="mb-3">
                            <label>Assesment Type</label>
                            <div className='radio-but'>
                                <input className="form-check-input radio_animated" type='radio' value={'ONETIME'} name='assessmentType'
                                    {...register('assesmentType')} checked={assesmentType === 'ONETIME' ? true : false}
                                    onChange={(e) => handleToggle(e)}
                                /> <lable>One Time Assessment</lable>
                                <input className="form-check-input radio_animated ml-2" type='radio' value={'PERIODIC'} name='assessmentType'
                                    {...register('assesmentType')} checked={assesmentType === 'PERIODIC' ? true : false}
                                    onChange={(e) => handleToggle(e)}
                                /> <lable>Periodic Assessment</lable>
                            </div>
                        </FormGroup>
                    </Col>
                    {open === true && <PeriodicAssesment value={open} setOpenModal={setOpen} formdata={formdata} setFormdata={setFormdata} />}
                </Row>
                {
                    (assesmentType === 'PERIODIC') && <Row>
                        <Col lg='6'>
                            <FormGroup>
                                <label>Periodic Assesment Type <span className="text-danger">*</span></label>
                                <select className="form-select" name='periodicAssesmentType'
                                    defaultValue={formdata?.periodicAssesmentType}
                                    {...register('periodicassessmenttype', { required: true })}
                                    onChange={(e) => handlePeriodic(e.target.value)}
                                >
                                    <option value={''}>-Select Type-</option>
                                    <option value={'FREQUENCY'}>Frequency</option>
                                    <option value={'VOLUME'}>Volume</option>
                                </select>
                                <span className="text-danger">{errors.periodicassessmenttype && errors.periodicassessmenttype.type === 'required'
                                    && 'Select Periodic Assesment Type'}</span>
                            </FormGroup>
                        </Col>
                        {
                            periodicType == "FREQUENCY" &&
                            <Col md='3'>
                                <FormGroup>
                                    <label>Frequency <span className="text-danger">*</span></label>
                                    <select className="form-select" name="frequency"
                                        defaultValue={'YEARLY'}
                                        {...register('frequency', { required: true })}
                                        onChange={(e) => setFrequency(e.target.value)}>
                                        <option value={''}>-Select Frequency-</option>
                                        <option value={'MONTHLY'}>Monthly</option>
                                        <option value={'QUARTERLY'}>Quarterly</option>
                                        <option value={'HALFYEARLY'}>Half yearly</option>
                                        <option value={'YEARLY'}>Yearly</option>
                                    </select>
                                    <span className="text-danger">{errors.frequency && errors.frequency.type === 'required'
                                        && 'Select Frequency'}</span>
                                </FormGroup>
                            </Col>
                        }
                        {
                            periodicType == 'FREQUENCY' && <Col md='3'>
                                <FormGroup>
                                    <label>Data Collection Frequency <span className="text-danger">*</span></label>
                                    <select className="form-select" name="dataCollectionFrequency" defaultValue={formdata?.dataCollectionFrequency}
                                        {...register('dataCollectionFrequency', { required: true })}
                                        onChange={(e) => setDataFrequency(e.target.value)}>
                                        <option value={''}>-Select Data Collection Frequency-</option>
                                        <option value={'DAILY'}>Daily</option>
                                        <option value={'MONTHLY'}>Monthly</option>
                                        <option value={'QUARTERLY'}>Quarterly</option>
                                        <option value={'HALFYEARLY'}>Half yearly</option>
                                        <option value={'YEARLY'}>Yearly</option>
                                    </select>
                                    <span className="text-danger">{errors.dataCollectionFrequency && errors.dataCollectionFrequency.type === 'required'
                                        && 'Select Frequency'}</span>
                                </FormGroup>
                            </Col>
                        }
                        {
                            periodicType == "VOLUME" && <Col md='6' >
                                <FormGroup>
                                    <label>Volume(In Metric tons) <span className="text-danger">*</span></label>
                                    <input className={`form-control mb-1`} placeholder='' type="text"
                                        defaultValue={formdata?.volume}
                                        name='Volume' {...register('volume', { required: true })} onChange={(e) => setVolume(e.target.value)} />
                                    <span className="text-danger">{errors.volume && errors.volume.type === 'required'
                                        && 'Volume Required'}</span>
                                </FormGroup>
                            </Col>
                        }
                    </Row>
                }
                <Row>
                    <Col sm='6'>
                        <FormGroup className="mb-3">
                            <label>Project Category <span className="text-danger">*</span></label>
                            <select className='form-select' value={projectCategoryId}
                                {...register('projectCategoryId', { required: true })}
                                onChange={(e) => {
                                    getSubcategory(e.target.value);
                                    handleChangeCategory()
                                }}
                            >
                                <option value=''>Select Project Category</option>
                                {
                                    categoryTypes?.length > 0 && categoryTypes?.map((i) => {
                                        return (
                                            <option value={i.id}>{i.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <span className='text-danger'>{errors.projectCategoryId && errors.projectCategoryId.type === 'required' && 'Project Category is required'}</span>
                        </FormGroup>
                    </Col>
                    <Col sm='6'>
                        <FormGroup className="mb-3">
                            <label>Project Subcategory <span className="text-danger">*</span></label>
                            <select className='form-select' value={subCategoryId}
                                {...register('projectSubCategoryId', { required: true })}
                                onChange={(e) => handleSubcategory(e.target.value)}
                            >
                                <option value=''>Select Project Subcategory</option>
                                {
                                    subCategory?.length > 0 && subCategory?.map((i) => {
                                        return (
                                            <option value={i.id}>{i.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <span className='text-danger'>{errors.projectSubCategoryId && errors.projectSubCategoryId.type === 'required' && 'Project Subcategory is required'}</span>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm='6'>
                        <FormGroup className="mb-3">
                            <label>Registry Type <span className="text-danger">*</span></label>
                            <select className='form-select' value={registryId}
                                {...register('registryId', { required: true })}
                                onChange={(e) => getUnitTypes(e.target.value)}
                            >
                                <option value=''>Select Registry Type</option>
                                {registryTypes && registryTypes.length && registryTypes.map((item) =>
                                    <option value={item.registryId}>{item.registry}</option>
                                )}
                            </select>
                            <span className="text-danger">{errors.registryId && errors.registryId.type === 'required'
                                && 'Registry Type is Required'}</span>
                        </FormGroup>
                    </Col>
                    <Col sm='6'>
                        <FormGroup className="mb-3">
                            <label>Project Subcategory Items <span className="text-danger">*</span></label>
                            <select className='form-select' value={subCategoryItemId}
                                {...register('subCategoryItemId', { required: true })}
                                onChange={(e) => handleSubcategoryItem(e.target.value)}
                            >
                                <option value=''>Select Project Subcategory Items</option>
                                {
                                    subCategoryItemList?.length > 0 && subCategoryItemList?.map((i) => {
                                        return (
                                            <option value={i.id}>{i.itemName}</option>
                                        )
                                    })
                                }
                            </select>
                            <span className='text-danger'>{errors.subCategoryItemId && errors.subCategoryItemId.type === 'required'
                                && 'Project Subcategory Item is required'}</span>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm='6'>
                        <FormGroup className="mb-3">
                            <label>Registry Unit Type <span className="text-danger">*</span></label>
                            <select className='form-select' value={unitType}
                                {...register('unitType', { required: true })}
                                onChange={(e) => setUnitType(e.target.value)}>
                                <option value=''>Select Registry Unit Type</option>
                                {
                                    unitTypeList && unitTypeList.length > 0 && unitTypeList.map((item) => {
                                        return (
                                            <option value={item.type}>{item.type}</option>
                                        )
                                    })
                                }
                            </select>
                            <span className="text-danger">{errors.unitType && errors.unitType.type === 'required'
                                && 'Registry Unit Type is Required'}</span>
                        </FormGroup>
                    </Col>
                    <Col sm='6'>
                        <FormGroup className="mb-3">
                            <label>Carbon Credits Calculation Day <span className="text-danger">*</span></label>
                            <select className='form-select' {...register('carbonCreditsCalculationDay', { required: true })}
                            >
                                <option value="">Select Calculation Day</option>
                                {
                                    days?.map((i) => <option value={i}>{i}</option>)
                                }
                            </select>
                            <span className="text-danger">{errors.carbonCreditsCalculationDay && 'Carbon Credits Calculation Day is Required'}</span>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg='6'>
                        <FormGroup className='proj-details'>
                            <Label>UN Sustainable Development Goals</Label>
                            <Select
                                isMulti={true}
                                options={sdgGoals}
                                placeholder="Select UN SDG Goals"
                                value={sdgList}
                                components={{
                                    Option: CustomOptionComponent
                                }}
                                onChange={(e) => handleSdgList(e)}
                                styles={{
                                    option: (base, { isFocused, isSelected }) => {
                                        return {
                                            ...base,
                                            background: isSelected ? '#003868' : isFocused ? '#0038681a' : undefined,
                                            color: isSelected ? '#fff' : isFocused ? '#000' : undefined
                                        }
                                    }
                                }}
                            />
                            {/* <span className="text-danger">{sdgList?.length > 3 && 'Please select lessthan 3 Items'}</span> */}
                        </FormGroup>
                    </Col>
                    {
                        ((subcategoryName === 'Plastic Credit') || (subCategoryItemId === '48bb69b1-20a9-4d0d-a595-a38ac4450381')) && origin === 'External' &&
                        <Col sm='6'>
                            <FormGroup className="mb-3">
                                <label>Baseline (MtCO2e) <span className="text-danger">*</span></label>
                                <input className={`form-control mb-1`} placeholder='' type="text" name='baseline'
                                    {...register('baseline', { required: true, pattern: /[+-]?([0-9]*[.])?[0-9]+$/ })} />
                                <span className='text-danger'>{errors.baseline && errors.baseline.type === 'required'
                                    && 'Baseline is Required'}</span>
                                <span className='text-danger'>{errors.baseline && errors.baseline.type === 'pattern'
                                    && 'Baseline Value must be decimal'}</span>
                            </FormGroup>
                        </Col>
                    }
                    {
                        (subCategoryItemId === '491d0d8c-0502-4b23-8db7-12fd83b416cc') &&
                        <Col sm='6'>
                            <FormGroup className='mb-3'>
                                <label>Monitoring Level <span className="text-danger">*</span></label>
                                <select className='form-select' name="monitoringLevel" value={monitoringLevel}
                                    {...register('monitoringLevel', { required: true })}
                                    onChange={(e) => setMonitoringLevel(e.target.value)}
                                >
                                    <option value=''>-Select Monitoring Level-</option>
                                    <option value="STATION">Station</option>
                                    <option value="CHARGER">Charger</option>
                                </select>
                                <span className="text-danger">{errors.monitoringLevel && 'Monitoring Level is Required'}</span>
                            </FormGroup>
                        </Col>
                    }
                </Row>

                <Row>
                    <Col sm='12'>
                        <FormGroup className="mb-3">
                            <label>Description</label>
                            <textarea rows="3" cols="50" className="form-control" name="description" defaultValue={formdata.description || ''}
                                {...register('description', { maxLength: { value: 4000, message: 'No of characters we can allow 4000 characters.' } })}></textarea>
                            <span className='text-danger'>{errors.description && errors.description.type === 'maxLength' && errors.description.message}</span>
                        </FormGroup>
                    </Col>
                </Row>
                <div className="btn-mb">
                    <Btn attrBtn={{ color: 'light', className: 'btn-font but-sec', onClick: handleSubmit(handleDraft) }}>{SaveasDraft}</Btn>
                    {
                        subCategoryItemId !== '491d0d8c-0502-4b23-8db7-12fd83b416cc' && <Btn attrBtn={{ color: 'primary', className: 'btn-font ml-1' }}>{Next}</Btn>
                    }
                    {
                        subCategoryItemId === '491d0d8c-0502-4b23-8db7-12fd83b416cc' && <Btn attrBtn={{ color: 'primary', className: 'btn-font ml-1', onClick: handleSubmit(handleDetailSubmit) }}>{'Submit'}</Btn>
                    }
                </div>
            </Form>
        </Fragment>
    )
}
export default Details