import React, { Fragment, useEffect, useState, useRef } from 'react';
import ReactDatasheet from 'react-datasheet';
import { ValueViewer } from './TargetData';

const DataSheet = ({ dataRef, dataGrid, onCellChanged }) => {
    return (
        <ReactDatasheet
            ref={dataRef}
            data={dataGrid}
            valueRenderer={(cell) => cell?.value}
            valueViewer={ValueViewer}
            onCellsChanged={(changes) => onCellChanged(changes)}
        />
    )
}

export default DataSheet;