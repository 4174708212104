import React, { Fragment, useState } from "react";
import { Progress, Modal, ModalBody, ModalHeader, Form, UncontrolledTooltip } from "reactstrap";
import { H3, P, } from "../../../AbstractElements";
import { X } from "react-feather";
import { useEffect } from "react";
import RegistrySubstates from "../RegistrySubstates";

const ProgressBarModel = ({ status, registrySubstate }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const statusValue = {
        DRAFT: 1,
        SUBMITTED: 1,
        REJECTED: 2,
        VALIDATED: 2,
        INASSESSMENT_UNALLOCATED: 2,
        INASSESSMENT_ALLOCATED: 3,
        RETURNED: 4,
        ACCEPTED: 4,
        SUBMITTED_TO_REGISTRY: 5,
        APPROVED_BY_REGISTRY: 7,
        ACTIVE: 8,
        SUSPENDED: 9,
        RETIRED: 10
    }
    
    const progressWidth = ['4%', '16%',
        '27%', '38%', "50%", '56%', '61%', '71%', '83%', '96%'];

    const [steps, setSteps] = useState(statusValue[status]);
    useEffect(() => {
        if (status === 'SUBMITTED_TO_REGISTRY' && registrySubstate) {
            setSteps(statusValue[status] + 1);
        } else {
            setSteps(statusValue[status]);
        }
    }, [status, registrySubstate])

    
    return (
        <Fragment>

            <div className="project-progress">
                <Form className='f1' method='post'>
                    <div className="f1-steps row-eq-height h-11 rm-margin">
                        <div className="f1-progress progress-bar-align">
                            <div className="f1-progress-line" data-now-value="49" style={{ width: progressWidth[steps - 1] }} data-number-of-steps={10} />
                        </div>
                        <div className={`f1-step activated ${steps >= 1 && "active"}`}>
                            <div className="f1-step-icon">
                                {steps >= 1 ? <i className="fa fa-check fa-2x" /> : <h2 className="mt-neg-3">1</h2>}
                            </div>
                            <P attrPara={{ className: 'fw-4 mt-10 no-wrap' }}>{'Draft / Submitted'}</P>
                        </div>
                        <div className={`f1-step activated ${steps >= 2 && "active"}`}>
                            <div className="top-p"><P attrPara={{ className: 'mb-0 fw-4 no-wrap' }}>{'Validated / Returned'}</P></div>
                            <div className="f1-step-icon mt-10">
                                {steps >= 2 ? <i className="fa fa-check fa-2x" /> : <h2 className="mt-neg-3">2</h2>}
                            </div>
                        </div>
                        {/* <div className={`f1-step activated ${steps >= 3 && "active"}`}>
                            <div className="f1-step-icon">
                                {steps >= 3 ? <i className="fa fa-check fa-2x" /> : <h2 className="mt-neg-3">3</h2>}
                            </div>
                            <P attrPara={{ className: 'fw-4' }}>{'In Assessment - WA'}</P>
                        </div> */}
                        <div className={`f1-step activated ${steps >= 3 && "active"}`}>
                            <div className="f1-step-icon">
                                {steps >= 3 ? <i className="fa fa-check fa-2x" /> : <h2 className="mt-neg-3">3</h2>}
                            </div>
                            <P attrPara={{ className: 'fw-4 mt-10' }}>{'In Assessment'}</P>
                        </div>
                        <div className={`f1-step activated ${steps >= 4 && "active"}`}>
                        <div className="top-p"><P attrPara={{ className: 'mb-0 fw-4 no-wrap' }}>{'Accepted / Rejected'}</P></div>
                            <div className="f1-step-icon mt-10">
                                {steps >= 4 ? <i className="fa fa-check fa-2x" /> : <h2 className="mt-neg-3">4</h2>}
                            </div>
                        </div>
                        <div className={`f1-step activated ${steps >= 5 && "active"}`} id="substate">
                            <div className="f1-step-icon">
                                {steps >= 5 ? <i className="fa fa-check fa-2x" /> : <h2 className="mt-neg-3">5</h2>}
                            </div>
                            <P attrPara={{ className: 'fw-4 no-wrap mt-10' }}>{'Submitted To Registry'}</P>
                        
                        </div>
                        {
                            registrySubstate && <UncontrolledTooltip
                            placement="bottom"
                            target="substate"
                            className="substate-tooltip"
                            // toggle={() => setTooltipOpen(!tooltipOpen) }
                        >
                            {registrySubstate}
                        </UncontrolledTooltip>
                        }
                        
                        <div className={`f1-step activated ${steps >= 7 && "active"}`}>
                        <div className="top-p"><P attrPara={{ className: 'mb-0 fw-4 no-wrap' }}>{'Approved By Registry'}</P></div>
                            <div className="f1-step-icon mt-10">
                                {steps >= 7 ? <i className="fa fa-check fa-2x" /> : <h2 className="mt-neg-3">6</h2>}
                            </div>
                        </div>
                        <div className={`f1-step activated ${steps >= 8 && "active"}`}>
                            <div className="f1-step-icon">
                                {steps >= 8 ? <i className="fa fa-check fa-2x" /> : <h2 className="mt-neg-3">7</h2>}
                            </div>
                            <P attrPara={{ className: 'fw-4 mt-10' }}>{'Active'}</P>
                        </div>
                        <div className={`f1-step activated ${steps >= 9 && "active"}`}>
                        <div className="top-p"><P attrPara={{ className: 'mb-0 fw-4' }}>{'Suspended'}</P></div>
                            <div className="f1-step-icon mt-10">
                                {steps >= 9 ? <i className="fa fa-check fa-2x" /> : <h2 className="mt-neg-3">8</h2>}
                            </div>
                        </div>
                        <div className={`f1-step activated ${steps >= 10 && "active"}`}>
                            <div className="f1-step-icon">
                                {steps >= 10 ? <i className="fa fa-check fa-2x" /> : <h2 className="mt-neg-3">9</h2>}
                            </div>
                            <P attrPara={{ className: 'fw-4 mt-10' }}>{'Retired'}</P>
                        </div>
                    </div>
                </Form>
            </div>


        </Fragment>
    )
}

export default ProgressBarModel
