import React, { useEffect, useRef, useState } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import fetchWrapper from '../../api/fetchWrapper';

const MapComponent = (props) => {
  const mapStyles = {
    width: '100%',
    height: '400px',
  };

  const mapRef = useRef(null);
  const [markerPosition, setMarkerPosition] = useState(props.position);

  const getCoordinatesFromZip = async (zipcode) => {
    const geocoder = new props.google.maps.Geocoder();
    await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode}&key=AIzaSyBEX6NVMxAcLWmMEbPYTPcfWLGnPXrzrtM`)
    .then(res => {
      res.json()
        .then((response) => {
          if(response?.status === 'OK') {
            const geoData = response?.results[0]?.geometry?.location
          setMarkerPosition(geoData);
          props.setMarker(geoData);
          }
          
        }, (err) => {
          console.log(err)
        })
    })
    
  };


  useEffect(() => {
    const google = props.google;
    const maps = google.maps;

    const map = new maps.Map(mapRef.current, {
      zoom: 14,
      center: markerPosition,
    });

    const marker = new maps.Marker({
      position: markerPosition,
      map: map,
      draggable: true, // Make the marker draggable
    });

    // Add an event listener for marker dragend
    marker.addListener('dragend', (e) => {
      const newPosition = {
        lat: e.latLng?.lat(),
        lng: e.latLng?.lng(),
      };
      setMarkerPosition(newPosition);
      props.setMarker(newPosition);
    });
  }, [props.google, markerPosition]);

  useEffect(()=>{
    if(props.position) {
      setMarkerPosition(props.position)
    }
  },[props.position])

  useEffect(()=>{
    console.log(props.zipcode)
    if(props.zipcode) {
      getCoordinatesFromZip(props.zipcode)
    }
    
  }, [props.zipcode])
  
  return (
    <div>
      <div ref={mapRef} style={props.styles}>
        Loading Map...
      </div>
      <div>
        Marker Position: Lat {markerPosition?.lat?.toFixed(6)}, Lng {markerPosition?.lng?.toFixed(6)}
      </div>
    </div>
  );
};


export default GoogleApiWrapper({
  apiKey: 'AIzaSyBEX6NVMxAcLWmMEbPYTPcfWLGnPXrzrtM', // Replace with your Google Maps API key
})(MapComponent);
