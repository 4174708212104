import { Grid, File, UserPlus } from 'react-feather';
import { HiOutlineShoppingCart, HiUsers, HiOutlineLocationMarker } from 'react-icons/hi'
import { IoWalletOutline } from 'react-icons/io5'
import { HiOutlineOfficeBuilding, HiOutlineCurrencyDollar } from 'react-icons/hi';
import { FiGrid, FiBox, FiBriefcase, FiSlack, FiUserPlus, FiWind, FiCloudLightning, FiMapPin, FiSettings } from 'react-icons/fi';
import { GrCloudUpload, GrLineChart, GiChart } from 'react-icons/gr';
import { SlLayers, SlEnergy } from 'react-icons/sl';
import { AiOutlineLineChart, AiOutlineBarChart } from 'react-icons/ai'
import { IoBarChartOutline, IoNewspaperOutline, IoLayersOutline, 
    IoBagHandleOutline, IoCloudUploadOutline, IoSettingsOutline, IoCloudDownloadOutline     } from 'react-icons/io5';
import { GiGreenhouse } from "react-icons/gi";
import { HiOutlineBolt } from "react-icons/hi2";
import { FaRegChartBar, FaFileDownload  } from "react-icons/fa";

const ASO_ADMIN = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            {
                title: 'Carbon Credits', id: 1, icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Projects', icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/aso-admin/projects`, title: 'Project List', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/aso-admin/project-initiation/Internal`, title: 'New Project', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/aso-admin/project-initiation/External`, title: 'Existing Project', type: 'link' },
                        ]
                    },
                    {
                        title: 'Wallet', icon: IoWalletOutline, badge: 'true', type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/wallet-summary`
                    },
                    {
                        title: 'Trading', icon: HiOutlineCurrencyDollar , badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/aso-admin/carbon-trading/trading`, title: 'Trading', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/aso-admin/carbon-trading/orders`, title: 'Orders', type: 'link' },
                        ]
                    },
                    {
                        title: 'Dashboard', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            // {  title: 'Baseline Calculation', type: 'link' },
                            {  title: 'Monthly Reports', type: 'link' },
                        ]
                    }
                ]
            },
            {
                title: 'GHG Emission', id: 2, icon: FiWind, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Groups', icon: IoLayersOutline, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/group-list`
                    },
                    {
                        title: 'Location', icon: HiOutlineLocationMarker, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/location-list`
                    },
                    {
                        title: 'Data Upload', icon: IoCloudUploadOutline, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/carbon-emission-list`
                    },
                    {
                        title: 'Target settings', icon: FiSettings, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/target-settings`
                    },
                    {
                        title: 'Buy Offsets', icon: IoBagHandleOutline , type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/buy-offsets`
                    },
                    {
                        title: 'Dashboard', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            {   
                                title: 'GHG Accounting', icon: GiGreenhouse, type: 'link', badge: 'true', active: false, 
                                path: `${process.env.PUBLIC_URL}/aso-admin/ghg-accounting`
                            },
                            
                            // { path: `${process.env.PUBLIC_URL}/aso-admin/gri-dashboard`, title: 'Sustainability Reports', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/aso-admin/target-net-zero-emission`, title: 'Target vs Actual', type: 'link' },
                        ]
                    },
                ]
            },
            {
                title: 'Decarbonization Analytics', id: 3, icon: FiCloudLightning, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Forecasted Dimming', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/aso-admin/forecasted-dimming-dashboard`
                    },
                    {
                        title: 'Saved Energy', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/aso-admin/saved-energy-dashboard`
                    },
                    {
                        title: 'Dimming Manager', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/aso-admin/dimming-manager-dashboard`
                    },
                    {
                        title: 'Forecasted Lifespan', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/aso-admin/forecasted-lifespan-dashboard`
                    }
                ]
            },
            {
                title: 'HVAC Insights', id: 4, icon: GrLineChart, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: AiOutlineLineChart, type: 'link', path: `${process.env.PUBLIC_URL}/aso-admin/hvac-dashboard`
                    },
                ]
            },
            {
                title: 'AQI Insights', id: 5, icon: IoBarChartOutline, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: IoBarChartOutline, type: 'link', path: `${process.env.PUBLIC_URL}/aso-admin/aqi-dashboard`
                    },
                ]
            },
        ],
        subOrgMenu: [
            {
                title: 'Sub Organizations', icon: HiOutlineOfficeBuilding, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/sub-organization-list`
            },
            {
                title: 'Subscriptions', icon: FiBriefcase, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/module-origanization`
            },
        ],
        defaultMenu: [
            {
                title: 'Users', icon: UserPlus, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/user-list`
            },
            {
                title: 'Downloads', icon: IoCloudDownloadOutline , type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/downloads`
            },
            {
                title: 'Settings', icon: IoSettingsOutline , badge: 'true', type: 'link', active: false, path: `${process.env.PUBLIC_URL}/aso-admin/settings/landing-page`
            }
        ]
    }
]

const ASO_USER = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            {
                title: 'Carbon Credits', id: 1, icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Projects', icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/user/projects`, title: 'Project List', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/user/project-initiation/Internal`, title: 'Create Project', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/user/project-initiation/External`, title: 'Existing Project', type: 'link' },
                        ]
                    },
                    {
                        title: 'Wallet', icon: IoWalletOutline, badge: 'true', type: 'link', active: false, 
                        path: `${process.env.PUBLIC_URL}/user/wallet-summary` 
                    },
                    {
                        title: 'Trading', icon: HiOutlineCurrencyDollar , badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/user/carbon-trading/trading`, title: 'Trading', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/user/carbon-trading/orders`, title: 'Orders', type: 'link' },
                        ]
                    },
                    {
                        title: 'Dashboard', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/user/baseline-calculation`, title: 'Baseline Calculation', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/user/monthly-reports`, title: 'Monthly Reports', type: 'link' },
                        ]
                    }
                ]
            },
            {
                title: 'GHG Emissions', id: 2, icon: FiWind, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Groups', icon: IoLayersOutline, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/user/group-list`
                    },
                    {
                        title: 'Location', icon: HiOutlineLocationMarker, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/user/location-list`
                    },
                    {
                        title: 'Data Upload', icon: GrCloudUpload, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/user/carbon-emission-list`
                    },
                    {
                        title: 'Target settings', icon: FiSettings, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/user/target-settings`
                    },
                    {
                        title: 'Buy Offsets', icon: IoBagHandleOutline , type: 'link', active: false, path: `${process.env.PUBLIC_URL}/user/buy-offsets`
                    },
                    {
                        title: 'Dashboard', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            {   
                                title: 'GHG Accounting', icon: GiGreenhouse, type: 'link', badge: 'true', active: false, path: `${process.env.PUBLIC_URL}/user/ghg-accounting`
                            },
                            // { path: `${process.env.PUBLIC_URL}/user/gri-dashboard`, title: 'GRI Reports', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/user/target-net-zero-emission`, title: 'Target vs Actual', type: 'link' },
                        ]
                    },
                ]
            },
            {
                title: 'Decarbonization Analytics', id: 3, icon: FiCloudLightning, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Forecasted Dimming', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/user/forecasted-dimming-dashboard`
                    },
                    {
                        title: 'Saved Energy', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/user/saved-energy-dashboard`
                    },
                    {
                        title: 'Dimming Manager', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/user/dimming-manager-dashboard`
                    },
                    {
                        title: 'Forecasted Lifespan', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/user/forecasted-lifespan-dashboard`
                    }
                ]
            },
            {
                title: 'HVAC Insights', id: 4, icon: GrLineChart, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: AiOutlineLineChart, type: 'link', path: `${process.env.PUBLIC_URL}/user/hvac-dashboard`
                    },
                ]
            },
            {
                title: 'AQI Insights', id: 5, icon: FaRegChartBar, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: IoBarChartOutline, type: 'link', path: `${process.env.PUBLIC_URL}/user/aqi-dashboard`
                    },
                ]
            }
        ],
        defaultMenu: [
            {
                title: 'Downloads', icon: IoCloudDownloadOutline , type: 'link', active: false, path: `${process.env.PUBLIC_URL}/user/downloads`
            },
            {
                title: 'Settings', icon: IoSettingsOutline , badge: 'true', type: 'link', active: false, 
                path: `${process.env.PUBLIC_URL}/user/settings/landing-page`
            }
        ]
    },
];

const SYS_USER = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            {
                title: 'Dashboard', icon: FiGrid, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/back-office/dashboard`
            },
            {
                title: 'Projects', icon: FiBox, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/back-office/projects`
            },
            {
                title: 'Organizations', icon: HiOutlineOfficeBuilding, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/back-office/customer-list`
            },
            {
                title: 'Orders', icon: HiOutlineShoppingCart, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/back-office/orders`
            },
            {
                title: 'Subscriptions', icon: FiBriefcase, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/back-office/module-origanization`
            },
        ]
    },
];

const SYS_ADMIN = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            {
                title: 'Dashboard', icon: FiGrid, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/dashboard`
            },
            {
                title: 'Projects', icon: FiBox, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/projects`
            },
            // {
            //     title: 'Organization Managment', id: 1, icon: HiOutlineBuildingOffice2, badge: 'true', type: 'sub', active: false, children: [
            //         {
            //             title: 'Organizations', icon: HiOutlineOfficeBuilding, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/customer-list`
            //         },
            //         {
            //             title: 'Sub Organization', icon: HiOutlineOfficeBuilding, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/sub-organization-list`
            //         },
            //     ]
            // },
            {
                title: 'Organizations', icon: HiOutlineOfficeBuilding, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/customer-list`
            },
            // {
            //     title: 'User Management', icon: Users, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/user-management`
            // },
            {
                title: 'Carbon Trades', icon: HiOutlineShoppingCart, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/orders`
            },
            {
                title: 'Subscriptions', icon: FiBriefcase, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/module-origanization`
            },
            {
                title: 'Credit Rates', icon: FiSlack, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/credit-rates`
            },
            {
                title: 'Exchange Rates', icon: HiOutlineCurrencyDollar, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/exchange-rate-list`
            },
        ]
    }
]

const ASM_ADMIN = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            {
                title: 'Carbon Credits', id: 1, icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Projects', icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/asm-admin/projects`, title: 'Project List', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-admin/project-initiation/Internal`, title: 'New Project', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-admin/project-initiation/External`, title: 'Existing Project', type: 'link' },
                        ]
                    },
                    {
                        title: 'Wallet Details', icon: IoWalletOutline, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-admin/wallet-details`

                    },
                    {
                        title: 'Trading', icon: HiOutlineCurrencyDollar , badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/asm-admin/carbon-trading/trading`, title: 'Trading', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-admin/carbon-trading/orders`, title: 'Orders', type: 'link' },
                        ]
                    },
                    {
                        title: 'Dashboard', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/asm-admin/baseline-calculation`, title: 'Baseline Calculation', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-admin/monthly-reports`, title: 'Monthly Reports', type: 'link' },
                        ]
                    }
                ]
            },
            {
                title: 'GHG Emission', id: 2, icon: FiWind, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Groups', icon: IoLayersOutline, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-admin/group-list`
                    },
                    {
                        title: 'Location', icon: FiMapPin, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-admin/location-list`
                    },
                    {
                        title: 'Data Upload', icon: GrCloudUpload, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-admin/carbon-emission-list`
                    },
                    {
                        title: 'Target settings', icon: FiSettings, type: 'link', active: false
                    },
                    {
                        title: 'Buy Offsets', icon: IoBagHandleOutline , type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-admin/buy-offsets`
                    },
                    {
                        title: 'Dashboard', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            // { path: `${process.env.PUBLIC_URL}/asm-admin/dashboard`, title: 'ESG', type: 'link' },
                            {   
                                title: 'GHG Accounting', icon: GiGreenhouse, type: 'link', badge: 'true', active: false, path: `${process.env.PUBLIC_URL}/user/ghg-accounting`
                            },
                            { path: `${process.env.PUBLIC_URL}/asm-admin/gri-dashboard`, title: 'GRI Reports', type: 'link' },
                        ]
                    },
                ]
            },
            {
                title: 'Decarbonization Analytics', id: 3, icon: FiCloudLightning, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Forecasted Dimming', icon: SlEnergy, type: 'link', path: `${process.env.PUBLIC_URL}/asm-admin/forecasted-dimming-dashboard`
                    },
                    {
                        title: 'Saved Energy', icon: SlEnergy, type: 'link', path: `${process.env.PUBLIC_URL}/asm-admin/saved-energy-dashboard`
                    },
                    {
                        title: 'Dimming Manager', icon: SlEnergy, type: 'link', path: `${process.env.PUBLIC_URL}/asm-admin/dimming-manager-dashboard`
                    },
                    {
                        title: 'Forecasted Lifespan', icon: SlEnergy, type: 'link', path: `${process.env.PUBLIC_URL}/asm-admin/forecasted-lifespan-dashboard`
                    }
                ]
            },
            {
                title: 'HVAC Insights', id: 4, icon: GrLineChart, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: AiOutlineLineChart, type: 'link', path: `${process.env.PUBLIC_URL}/asm-admin/hvac-dashboard`
                    },
                ]
            },
            {
                title: 'AQI Insights', id: 5, icon: FaRegChartBar, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: IoBarChartOutline, type: 'link', path: `${process.env.PUBLIC_URL}/asm-admin/aqi-dashboard`
                    },
                ]
            },
            {
                title: 'Users', icon: UserPlus, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-admin/user-list`
            }
        ]
    }
]


const ASM_USER = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            {
                title: 'Carbon Credits', id: 1, icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Projects', icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/asm-user/projects`, title: 'Project List', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-user/project-initiation/Internal`, title: 'New Project', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-user/project-initiation/External`, title: 'Existing Project', type: 'link' },
                        ]
                    },
                    {
                        title: 'Wallet', icon: IoWalletOutline, badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/asm-user/wallet-summary`, title: 'Summary', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-user/wallet-details`, title: 'Detail View', type: 'link' },
                        ]
                    },
                    {
                        title: 'Trading', icon: HiOutlineCurrencyDollar , badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/asm-user/carbon-trading/trading`, title: 'Trading', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-user/carbon-trading/orders`, title: 'Orders', type: 'link' },
                        ]
                    },
                ]
            },
            {
                title: 'GHG Emission', id: 2, icon: FiWind, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Groups', icon: IoLayersOutline, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-user/group-list`
                    },
                    {
                        title: 'Location', icon: HiOutlineLocationMarker, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-user/location-list`
                    },
                    {
                        title: 'Data Upload', icon: GrCloudUpload, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-user/carbon-emission-list`
                    },
                    {
                        title: 'Target settings', icon: FiSettings, type: 'link', active: false
                    },
                    {
                        title: 'Buy Offsets', icon: IoBagHandleOutline , type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-user/buy-offsets`
                    },
                    {
                        title: 'Dashboard', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            // { path: `${process.env.PUBLIC_URL}/asm-user/dashboard`, title: 'ESG', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-user/ghg-emission-dashboard`, title: 'ESG', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-user/gri-dashboard`, title: 'GRI Reports', type: 'link' },
                        ]
                    },
                ]
            },
            {
                title: 'Decarbonization Analytics', id: 3, icon: FiCloudLightning, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Forecasted Dimming', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/asm-user/forecasted-dimming-dashboard`
                    },
                    {
                        title: 'Saved Energy', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/asm-user/saved-energy-dashboard`
                    },
                    {
                        title: 'Dimming Manager', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/asm-user/dimming-manager-dashboard`
                    },
                    {
                        title: 'Forecasted Lifespan', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/asm-user/forecasted-lifespan-dashboard`
                    }
                ]
            },
            {
                title: 'HVAC Insights', id: 4, icon: GrLineChart, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: AiOutlineLineChart, type: 'link', path: `${process.env.PUBLIC_URL}/asm-user/hvac-dashboard`
                    },
                ]
            },
            {
                title: 'AQI Insights', id: 5, icon: FaRegChartBar, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: IoBarChartOutline, type: 'link', path: `${process.env.PUBLIC_URL}/asm-user/aqi-dashboard`
                    },
                ]
            },
        ]
    }
]

const ASR_USER = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            // {
            //     title: 'Dashboard', icon: FiGrid, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/dashboard`
            // },
            {
                title: 'Project List', icon: FiBox, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/assessor/projects`
            }
        ]
    }
]




const ASR_ADMIN = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            // {
            //     title: 'Dashboard', icon: FiGrid, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asr-admin/dashboard`
            // },
            {
                title: 'Project List', icon: FiBox, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asr-admin/projects`
            },
            {
                title: 'Users', icon: UserPlus, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asr-admin/user-list`
            }
        ]
    }
]

export const MenuItem = {
    ASO_USER, ASO_ADMIN, SYS_USER, SYS_ADMIN, ASM_USER, ASR_ADMIN, ASR_USER, ASM_ADMIN
}