import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';
import { MenuItem } from '../../Sidebar/Menu';
import { Search } from 'react-feather';
import { Col, Input } from 'reactstrap';
import CustomizerContext from '../../../../Common/_helper/Customizer';
import ListOfMenu from '../RightBar/Bookmark/ListOfMenu';
import EmpltyClass from '../RightBar/Bookmark/EmptyClass';
import { H3 } from '../../../../AbstractElements';
import { useSelector } from 'react-redux';
import hand from '../../../../assets/images/hand.gif';
import moment from 'moment';
import { capitalize } from '../../../api/helper';

const Searchbar = () => {
    const mainmenu = MenuItem.ASM_ADMIN;
    const [searchValue, setSearchValue] = useState('');
    const [searchIcon, setSearchIcon] = useState(false);
    const [searchToggle, setSearchToggle] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const { setIsClose } = useContext(CustomizerContext);
    const [IsOpen, setIsOpen] = useState(false);
    const userDetails = useSelector(state => state?.user?.userDetails);

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setSearchValue('');
            setSearchResult([]);
            setIsClose(false);
        }
    }, []);
    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);
        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, [escFunction, searchValue]);

    const checkSearchResultEmpty = (items) => {
        if (!items.length) {
            setSearchIcon(true);
        } else {
            setSearchIcon(false);
        }
    };
    const addFix = () => {
        setSearchToggle(true);
    };
    const removeFix = useCallback(() => {
        setSearchValue('');
        setSearchToggle(false);
        setIsClose(false);
    }, []);
    useEffect(() => {
        if (!IsOpen) {
            setSearchValue('');
            setSearchToggle(false);
            setIsClose(false);
        }
    }, [IsOpen])
    const handleSearchKeyword = (keyword) => {
        keyword ? addFix() : removeFix();
        const items = [];
        setSearchValue(keyword);
        mainmenu.map(menuItems => {
            menuItems.Items.filter(mItems => {
                if (mItems.title.toLowerCase().includes(keyword) && mItems.type === 'link') {
                    items.push(mItems);
                }
                if (!mItems.children) return false;
                mItems.children.filter(subItems => {
                    if (subItems.title.toLowerCase().includes(keyword) && subItems.type === 'link') {
                        subItems.icon = mItems.icon;
                        items.push(subItems);
                    }
                    if (!subItems.children) return false;
                    subItems.children.filter(suSubItems => {
                        if (suSubItems.title.toLowerCase().includes(keyword)) {
                            suSubItems.icon = mItems.icon;
                            items.push(suSubItems);
                        }
                        return suSubItems;
                    });
                    return subItems;
                });
                checkSearchResultEmpty(items);
                setSearchResult(items);
                return mItems;
            });
            return menuItems;
        });
    };
    return (
        <Fragment>
            <Col className="left-header horizontal-wrapper ps-0">
                {/* <div>
                    <div className="d-flex align-center gap-2">
                        <h4 className='f-w-500 f-s-20 mb-0'>Welcome {capitalize(userDetails?.firstName)} {capitalize(userDetails?.lastName)}</h4>
                        <img src={hand} height={26} className='mtop-0' />
                    </div>
                </div>
                <div className='welcome-content'>
                    <div className='col-12'>
                        <span className='text-truncate'>{moment(Date.now()).format('DD MMM, YYYY dddd')}</span>
                    </div>
                </div> */}
            </Col>
        </Fragment >
    );
};
export default Searchbar;