import moment from "moment"
import { thousandSeparator } from "../../api/helper"

export const defaultHeader = [
    'Description',
    'Base Year',
    'Year 2',
    'Year 3',
    'Year 4',
    'Year 5',
    'Year 6',
    'Year 7',
    'Year 8',
    'Year 9',
    'Year 10',
    'Year 11',
];

export const totalScopes = {
    1: 'Total Scope 1 Direct Emissions',
    2: 'Total Scope 2 Indirect Emissions',
    3: 'Total Scope 3 Indirect Emissions'
}

export const scope1 = [
    'Description',
    'Stationary Combustion Sources',
    'Mobile Combustion Sources',
    'Refrigeration / AC Equip. Use',
    'Leaks from Air-conditining and HVAC',
    'Total Scope 1 Direct U.S. Emissions',
]

export const scope2 = [
    'Description',
    'Purchased Electricity',
    'Purchased Steam',
    'Purchased Hot Water',
    'Purchased Chilled Water',
    'Total Scope 2 Indirect U.S. Emissions',
]

export const scope3 = [
    'Description',
    'Purchased goods and services',
    'Capital goods',
    'Fuel- and energy-related activities not included in Scope 1 or 2',
    'Upstream transportation and distribution',
    'Waste generated in operations',
    'Business travel',
    'Employee commuting',
    'Leased Assets',
    'Downstream transportation and distribution',
    'Processing of sold products',
    'Use of Sold Products',
    'End-of-life treatment of sold products',
    'Total Scope 3 Indirect U.S. Emissions',
]

export const biomassEmissions = [
    'Description',
    'Stationary Biomass CO2',
    'Mobile Biomass CO2',
    'Indirect Biomass CO2',
    'Total Metric Tons - CFC',
    'Total Metric Tons - HCFC'
];

export const totalScopesDescription = [
    'Description',
    'Total Scope 1 Emissions',
    'Total Scope 2 Emissions',
    'Total Scope 3 Emissions',
    'Total Emissions',
    'Offset Reductions',
    'Revised Total'
]

export const replaceYear = (tempData, year) => {
    let yearValue = typeof year === 'object' ? moment(year).format('yyyy') : year;
    tempData[0]?.map((item, index) => {
        if (index > 1) {
            item.value = Number(yearValue) + (index - 2)
        }
    })
    return tempData;
}

export const ValueViewer = ({ row, col, value }) => {
    // Customize the rendering logic based on your requirements
    return <span>{row > 0 ? thousandSeparator(value) : value}</span>;
};

export const isNumber = (value) => {
    if (!isNaN(value)) {
        return true
    }
}



export function isIterable(obj) {
    // checks for null and undefined
    if (obj == null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
}


const sampleData = {
    baseYear: 2023,
    scope1Data: [
        {
            code: 'LAH',
            year: 2023,
            value: 50
        }, 
        {
            code: 'MCS',
            year: 2023,
            value: 50
        },
        {
            code: 'REU',
            year: 2023,
            value: 50
        },
        {
            code: 'SCS',
            year: 2023,
            value: 50
        }
    ],
    scope2: [
        {
            code: 'PE',
            year: 2023,
            value: 50
        },
        {
            code: 'PS',
            year: 2023,
            value: 50
        },
    ],
    scope3: [
        {
            code: 'BT',
            year: 2023,
            value: 50
        }, 
        {
            code: 'EC',
            year: 2023,
            value: 50
        },
        {
            code: 'WGO',
            year: 2023,
            value: 50
        },
        {
            code: 'UTD',
            year: 2023,
            value: 50
        }
    ], 
    biomass: [
        {
            code: 'IBC',
            year: 2023,
            value: 50
        },
        {
            code: 'MBC',
            year: 2023,
            value: 50
        },
        {
            code: 'SBC',
            year: 2023,
            value: 50
        },
        {
            code: 'CFC',
            year: 2023,
            value: 50
        },
        {
            code: 'HCFC',
            year: 2023,
            value: 50
        },
    ]
} 