import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Card, Row, Col, FormGroup, Label, Button, Table, Spinner, CardBody } from "reactstrap";
import fetchWrapper from "../../Common/api/fetchWrapper";
import { API_URL } from "../../Common/Config/Config";
import { Download } from 'react-feather';
import moment from "moment";
import { Breadcrumbs } from '../../AbstractElements';
import AgGridTable from '../../Common/Component/AgGridTable';

const ReportDownload = () => {
    const [userReportData, setUserReportData] = useState([]);
    const [loading, setLoading] = useState(false);
    const downloadRef = useRef(null);

    const getAllDownloadFiles = async () => {
        downloadRef?.current?.api.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/reports/user`)
            .then((res) => {
                setUserReportData(res.json)
                downloadRef?.current.api.hideOverlay();
            }, (err) => {
                console.log(err);
                if (err.status === 404) {
                    downloadRef?.current?.api?.showNoRowsOverlay();
                }
            })
    }

    const downloadFile = async (id, name) => {
        setLoading(true)
        const options = {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('accessToken')}`
            }
        }
        await fetch(`${API_URL}/reports/reportData/reportId/${id}/downloadFile`, options)
            .then((response) => response.blob())
            .then((res) => {
                const blob = new Blob([res], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${name}.pdf`);
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
                document.body.removeChild(link);
                setLoading(false)
            })
            .catch(err => console.log(err))
    }

    const columnData = [
        {
            headerName: 'Report ID',
            field: 'reportId',
            filter: true,
            flex: 0.3,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Name',
            field: 'name',
            filter: true,
            flex: 0.5,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Status',
            field: 'downloadStatus',
            filter: true,
            flex: 0.5,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Downloads',
            cellRenderer: (params) => {
                return (
                    <a className="d-flex justify-center" onClick={() => downloadFile(params.data?.id, params.data?.name)}>
                        <Download className={params.data.expirationTime ? 'document-icon' : 'disable-download'} />
                    </a>
                )
            },
            width: 150,
            sortable: true,
            suppressMovable: true,
        },
        {
            headerName: 'Expiration Time',
            valueGetter: (params) => {
                return params.data?.expirationTime ? moment(params.data?.expirationTime).format('DD MMM, YYYY HH:MMA') : 'Download in progress'
            },
            width: 200,
            sortable: true,
            suppressMovable: true,
        }
    ]

    const onGridReady = useCallback(async () => {
        getAllDownloadFiles()
    })

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Downloads" />
            <Card >
                <CardBody className="ptop-0">
                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                        <AgGridTable
                            columnData={columnData}
                            tableData={userReportData}
                            agRef={downloadRef}
                            onGridReady={onGridReady}
                        />
                    </div>
                </CardBody>
            </Card>


        </Fragment>
    )
}

export default ReportDownload;