import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardFooter, Button } from 'reactstrap';
import { Breadcrumbs, H3 } from '../../../AbstractElements';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { toast } from 'react-toastify';
import Select from 'react-select';
import ButtonLoader from '../ButtonLoader';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { defaultUserType } from '../../api/helper';
import Swal from 'sweetalert2';

let moduleList;
let subscription_list;
let selectedOrgId;

const ModuleSubscribe = () => {
  const [tableData, setTableData] = useState([]);
  const [organization, setOrganizations] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const userType = useSelector(state => state?.user?.userType);
  const navigate = useNavigate();

  const loadData = async () => {
    setLoading(true)
    const url = userType === 'ASO_ADMIN' ? 'organization/subOrgs' : 'organization/type/ASSET_OWNER';
    await fetchWrapper(`${API_URL}/${url}`)
      .then((res) => {
        console.log(res)
        setLoading(false)
        const orgList = res.json.map((item) => {
          return {
            label: item.companyName,
            value: item.id
          }
        });
        setOrganizations(orgList)
      }).catch((err) => {
        setLoading(false)
        console.log(err)
      });
  }

  const onChangeOrganization = async (event) => {
    selectedOrgId = event.value;
    getSubscription();

  }

  const getSubscription = async () => {
    setLoading(true)
    await fetchWrapper(`${API_URL}/subscriptions/customer/${selectedOrgId}`)
      .then((res) => {
        setLoading(false)
        const subscribeList = res.json.subscribed_features.map((item) => {
          item.issubscribed = true;
          return item;
        });
        const unsubscribeList = res.json.unsubscribed_features.map((item) => {
          item.issubscribed = false;
          return item;
        });
        subscription_list = JSON.parse(JSON.stringify(res.json));
        const subscriptionList = subscribeList.concat(unsubscribeList).sort((current, next) => current.moduleId - next.moduleId);
        moduleList = JSON.parse(JSON.stringify(subscriptionList));
        setSelectedAll(subscribeList.length === moduleList.length)
        setTableData([...subscriptionList]);
      }).catch((err) => {
        setLoading(false)
        console.log(err)
      });
  }

  const submitSubscription = async () => {
    const request = JSON.parse(JSON.stringify(tableData)).reduce((newArr, item) => {
      const changedObj = subscription_list.subscribed_features.find(obj => obj.moduleId === item.moduleId);
      if ((subscription_list.unsubscribed_features.some(obj => item.moduleId === obj.moduleId) && item.issubscribed) ||
        (changedObj && changedObj.issubscribed !== item.issubscribed)) {
        delete item.module_description;
        newArr.push(item);
      }
      return newArr;
    }, []);
    setLoading(true);
    const options = {
      method: 'POST',
      body: request
    }
    await fetchWrapper(`${API_URL}/subscriptions/customer/${selectedOrgId}`, options)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          getSubscription();
          toast.success('Module subscription updated Successfully!')
        }
      }).catch((err) => {
        setLoading(false)
        if (err.status === 200) {
          getSubscription();
          const checkGhg = tableData.find(obj => obj.moduleId === 2);
          if(checkGhg?.issubscribed) {
            Swal.fire({
              text:'Redirecting to Organization screen, please update the GHG reporting frequency...',
              timer: 1000
            })
            setTimeout(()=> {
              navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/edit-customer/${selectedOrgId}`)
            }, 1000)
            
          }
          toast.success('Module subscription updated Successfully!')
        }
        console.log(err)
      });
  }

  const resetBtnClick = () => {
    const dbList = JSON.parse(JSON.stringify(moduleList));
    const subscribedList = dbList.filter(item => item.issubscribed);
    setSelectedAll(subscribedList.length === dbList.length);
    setTableData([...dbList]);
  }

  const handleAllSelect = (event) => {
    setSelectedAll(event.target.checked);
    setTableData(oldList => oldList.map((item) => {
      item.issubscribed = event.target.checked;
      return item;
    }));
  }

  const handleOnChange = (event, moduleId) => {
    const tempData = JSON.parse(JSON.stringify(tableData));
    const udpatedList = tempData.map((item) => {
      if (item.moduleId === moduleId) {
        item.issubscribed = event.target.checked;
      }
      return item;
    })
    setTableData([...udpatedList]);
    const subscribedList = tempData.filter(item => item.issubscribed);
    setSelectedAll(tempData.length === subscribedList.length);
  }

  useEffect(() => {
    loadData();
  }, [])

  return (
    <Fragment>
      <LayoutLoader />
      <div className="page-title pb-0">
      <Row>
        <Col md='8'>
        <H3 attrH3={{ className: 'title-color' }}>Organization Module Subscriptions</H3>
        </Col>
        <Col md="4" className="mb-4">
          <Select 
            options={organization} 
            placeholder="Select Organization" 
            styles={{
              option:(base, { isFocused, isSelected }) => {
                return {
                  ...base,
                  background: isSelected ? '#006666' : isFocused ? '#0066661a' :  undefined,
                  color: isSelected ? '#fff' : isFocused ? '#000' :  undefined
                }
              }
            }}
            onChange={(e) => onChangeOrganization(e)} 
          />
        </Col>
      </Row>
      </div>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className=" newtable">
              <CardBody className='data-tables '>
                <H3>Module Subscriptions</H3>
                <table className='table mtop-1' border={true}  id="org-table">
                  <thead>
                    <tr className='table-header-bg'>
                      <th className='text-left'>Module Name</th>
                      <th className='module-subscribe'>
                        {tableData && tableData.length ?
                          <label className="switch">
                            <input type="checkbox" id="togAllBtn" checked={selectedAll} onChange={(event) => handleAllSelect(event)} />
                            <div className="slider round">
                              <span className="on"></span>
                              <span className="off"></span>
                            </div>
                          </label> : 'Status'
                        }
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData && tableData.length ? tableData.map((item, index) =>
                      <tr key={index}>
                        <td>{item.module_description}</td>
                        <td >
                          <div className='pl-4'>
                            <label className="switch">
                              <input type="checkbox" checked={item.issubscribed} onChange={(event) => handleOnChange(event, item.moduleId)} />
                              <div className="slider round">
                                <span className="on"></span>
                                <span className="off"></span>
                              </div>
                            </label>
                          </div>

                        </td>
                      </tr>
                    ) : <tr>
                      <td colSpan={2}>
                        <h4 style={{ textAlign: 'center' }}>No data available</h4>
                      </td>
                    </tr>
                    }
                  </tbody>
                </table>
              </CardBody>
              {tableData && tableData.length ?
                <CardFooter>
                  {
                    loading ? <ButtonLoader loaderText={'Submitting'} color='primary'/> : <Button color='primary' onClick={submitSubscription}>Submit</Button>
                  }
                  <Button className="ml-1"  color="secondary"  onClick={resetBtnClick}>Reset</Button>
                </CardFooter> : <></>}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default ModuleSubscribe;