import React, { Fragment, useState, forwardRef, useRef, useEffect } from "react";
import { Card, Row, Col, FormGroup, Label, Button, CardBody } from "reactstrap";
import { H3, Breadcrumbs } from "../../../AbstractElements";
import { Download, X } from "react-feather";
import Dropzone from "react-dropzone-uploader";
import { useForm } from "react-hook-form";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import DatePicker from 'react-datepicker';
import moment from "moment";
import pdfImg from '../../../assets/images/document/pdf.png'
import { useParams } from "react-router";
import LayoutLoader from "../LayoutLoader/LayoutLoader";
import AgGridTable from "../AgGridTable";


const MonitoringDocUpload = () => {
    const [selectedDate, setSelected] = useState(new Date())
    const projectId = useParams()?.id;
    const projectName = useParams()?.name;
    const gridRef = useRef(null);
    const [tableData, setTableData] = useState([]);
    
    const CustomInput = forwardRef((props, ref) => {
        return (
            <div className={`${'form-control'}`} onClick={props.onClick}>
                <label ref={ref} >
                    {props.value || props.placeholder}
                </label>
                <i className="fa fa-calendar input-calendar-ico" style={{ marginTop: '0.1rem' }}></i>
            </div>
        );
    });

    const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

    const CustomPreview = ({ meta }) => {
        const { name, previewUrl, status, percent } = meta;
        return (
            <div className="custom-preview">
                <div>
                    <img src={previewUrl ? previewUrl : pdfImg} alt={name} height={50} width={50} />
                    <p className="mb-0">{Math.round(percent)}%</p>
                    <p>{status}</p>
                </div>
            </div>
        );
    };

    const loadDocumentData = async () => {
        await fetchWrapper(`${API_URL}/document/project/${projectId}/collectionList`)
            .then((res) => {
                setTableData(res.json)
            }, (err) => { console.log(err) })
    }

    const columnsData = [
        {
            headerName: 'Document Name',
            field: 'originalFileName',
            filter: true,
            sortable: true,
            resizable: false,
            minWidth: 300,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true
        },
        {
            headerName: 'Period',
            field: 'period',
            filter: true,
            sortable: true,
            resizable: false,
            maxWidth: 125,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true
        },
        {
            headerName: 'Type',
            field: 'mimeType',
            filter: true,
            sortable: true,
            resizable: false,
            maxWidth: 125,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true
        },
        {
            headerName: 'Size',
            field: 'size',
            filter: true,
            sortable: true,
            resizable: false,
            maxWidth: 125,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true
        },
        {
            headerName: 'Download',
            filter: true,
            sortable: true,
            resizable: false,
            maxWidth: 125,
            cellRenderer: (params) => {
                return (
                    <div>
                        <a href={params.data.path} target='_blank'><Download className='document-icon' /></a>
                    </div>
                )
            },
            suppressMovable: true
        },
    ]

    useEffect(() => {
        loadDocumentData()
    }, [])

    const handleChange = async (files, allFiles) => {
        const authToken = sessionStorage.getItem('accessToken')
        let tempFile;
        let data = new FormData();
        if (files?.length > 1) {
            files?.map((item, index) => {
                let originalName = item?.file?.name?.split('.')[1]
                let tempName = `${moment(selectedDate).format('YYYY-MM-DD')}_${index + 1}.${originalName}`;
                let tempType = item?.file?.type;
                tempFile =  new File([item?.file], tempName, {
                    type: tempType
                })
                data.append('files', tempFile)
            })
        } else if (files?.length === 1) {
            let originalName = files[0]?.file?.name?.split('.')[1]
            let tempName = `${moment(selectedDate).format('YYYY-MM-DD')}.${originalName}`
            tempFile = new File([files[0]?.file], tempName, {
                type: files[0]?.file?.type,
                size: files[0]?.file?.size
            })
            data.append('files', tempFile)
        }
        data.append('frequency', 'DAILY')
        data.append('period', moment(selectedDate).format('DD MMM, YYYY'))
        data.append('projectId', projectId);
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${authToken}`
            },
            body: data
        }
        await fetchWrapper(`${API_URL}/document/uploadCollection`, options)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    toast.success('Document Uploaded successfully!')
                    loadDocumentData();
                }
            })
            .catch((error) => {
                if (error.status === 200) {
                    allFiles.forEach(ele => ele.remove())
                    toast.success('Document Uploaded successfully!')
                    loadDocumentData();
                }
                if (error.status == 412) {
                    toast.error('File with the same name already exists')
                }
            })
    }
    return (
        <Fragment>
            <LayoutLoader />
            <Breadcrumbs parent="EV Charges List" title="List" mainTitle={`${projectName} - Monitoring Document`} />
            <Row>
                <Col lg='10'>
                    <Card>
                        <CardBody>
                            <H3 attrH3={{ className: 'title-color' }}>Upload Documents  </H3>
                            <Row>
                                <Col lg="4">
                                    <FormGroup>
                                        <Label>Select Date</Label>
                                        <DatePicker
                                            className="datepicker-here form-control"
                                            selected={selectedDate}
                                            onChange={(e) => {
                                                setSelected(e)
                                            }}
                                            dropdownMode="select"
                                            dateFormat="dd MMM, yyyy"
                                            customInput={<CustomInput />}
                                            maxDate={new Date()}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg='10'>
                                    <div className="waste-collection">
                                        <Dropzone
                                            getUploadParams={getUploadParams}
                                            // onChangeStatus={async () => {
                                            //     const result = await trigger();
                                            // }}
                                            onSubmit={handleChange}
                                            maxSizeBytes={10485760}
                                            accept='.pdf, .jpeg, .jpg, .png'
                                            maxFiles={30}
                                            PreviewComponent={CustomPreview}
                                            canCancel={true}
                                            canRemove={true}
                                            canRestart={true}
                                            multiple={true}
                                            inputContent="Drag and Drop Files"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg='10'>
                                    <div className="waste-collection-upload">
                                        <div className="ag-theme-material ag-style mtop-2" style={{ width: '100%' }}>
                                            <AgGridTable
                                                columnData={columnsData}
                                                tableData={tableData}
                                                agRef={gridRef}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default MonitoringDocUpload;