
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Row, Col, Card, CardBody, Label, Button, InputGroup, InputGroupText } from 'reactstrap';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { H3 } from '../../../AbstractElements';
import ReactDatasheet from 'react-datasheet';
import Swal from 'sweetalert2';
import { defaultHeader, scope1, scope2, scope3, biomassEmissions, replaceYear, isNumber, totalScopes } from './TargetData';
import SummaryView from './SummaryView';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import { ValueViewer } from './TargetData';
import DataSheet from './ReactDataSheet';

function useForceUpdate() {
    const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
    return forceUpdate;
}

const TargetSetting = () => {
    const [baseYear, setbaseYear] = useState();
    const [targetYear, setTargetYear] = useState();
    const [emissionTarget, setTarget] = useState();
    const [data, setData] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [scope, setScope] = useState('1');
    const [nonUsScope, setNonUsScope] = useState('1');
    const [ghgUs, setGhgUs] = useState(true);
    const [ghgNonUs, setGhgNonUs] = useState(false)
    const [usBiomassData, setUsBiomass] = useState([]);
    const [nonUsBiomassData, setNonUsBiomass] = useState([]);
    const [open, setOpen] = useState(false);
    const [usScopeData, setUsScopeData] = useState([]);
    const [nonUsScopeData, setNonUsScopeData] = useState([]);
    const [biomassDesc, setBiomassDesc] = useState([]);
    const [targetDetails, setTargetDetails] = useState({});
    const [targetSettingData, setTargetData] = useState({});
    const [biomassArray, setBiomassArray] = useState([]);
    const [targetBiomassData, setTargetBiomassData] = useState([]);

    const gridRef = useRef(null);

    const usBiomassRef = useRef(null);

    const dataRef = useRef(null);

    const nonUsBiomassRef = useRef(null);

    const forceUpdate = useForceUpdate();

    const getTargetDetails = async () => {
        await fetchWrapper(`${API_URL}/emissionTarget/orgSettings`)
            .then((res) => {
                let targetData = res?.json;
                targetData?.baseYear ? setbaseYear(new Date(targetData?.baseYear)) : '';
                targetData?.targetYear ? setTargetYear(new Date(targetData?.targetYear)) : '';
                setTarget(targetData?.emissionTarget);
                let inventoryOptions = targetData?.globalApplicability?.split(',');
                console.log(inventoryOptions);
                inventoryOptions?.includes('1') && setGhgUs(true);
                inventoryOptions?.includes('2') && setGhgNonUs(true);
                setTargetDetails(targetData)
            }, (err) => console.log(err))
    }

    const getBiomassDescription = async () => {
        await fetchWrapper(`${API_URL}/emissionItemsMaster/BIOMASSC02/scope/0`)
            .then((res) => {
                let sData = res.json?.map((i) => i?.itemDescription);
                sData?.unshift('Description')
                setBiomassDesc(res?.json);
                const usBiomass = loadDefaultHeader(sData, 'US', 'biomass');
                setUsBiomass(usBiomass)
                const nonUsBiomass = loadDefaultHeader(sData, 'NONUS', 'biomass');
                setNonUsBiomass(nonUsBiomass);
                setBiomassArray(sData);
            }, (err) => console.log(err))
    }

    const populateYearRows = () => {
        let tempData = defaultHeader?.map((item) => {
            return {
                value: item,
                readOnly: true,
                width: 100,
                className: 'menu-style target-header'
            }
        })
        const baseYearValue = typeof baseYear === 'object' ? moment(baseYear).format('yyyy') : baseYear;
        tempData?.map((item, index) => {
            if (index > 0) {
                item.value = Number(baseYearValue) + (index)
            } else {
                item.value = Number(baseYearValue)
            }
        })
        return tempData;
    }

    const loadDefaultHeader = (columnData, ghgType, key) => {
        const sideColumn = columnData?.length > 0 ? columnData : ghgType === 'biomass' ? biomassEmissions : [];
        let getHeaderArray = [];
        const columnArray = sideColumn?.map((i) => {
            return [
                {
                    value: i,
                    readOnly: true,
                    width: 400,
                    className: 'side-menu'
                }
            ]
        })
        if (baseYear) {
            if (key === 'biomass') {
                if (usBiomassData[0]?.length > 0) {
                    getHeaderArray = usBiomassData[0]?.filter((i, index) => index > 0);
                } else {
                    getHeaderArray = populateYearRows();
                }
            } else {
                if (gridData?.length > 0) {
                    getHeaderArray = gridData[0]?.filter((i, index) => index > 0)
                } else {
                    getHeaderArray = populateYearRows();
                }
            }
        } else {
            getHeaderArray = defaultHeader?.map((item) => {
                return {
                    value: item,
                    readOnly: true,
                    width: 100,
                    className: 'menu-style target-header'
                }
            })
        }
        columnArray[0] = columnArray && getHeaderArray && [...columnArray[0], ...getHeaderArray];
        let tempArray = columnArray?.map((ele, index) => {
            if (index > 0) {
                let emptyArray;
                emptyArray = key ? new Array(11).fill({ value: '', width: 100 }) : (index === (columnArray?.length - 1)) ? new Array(11).fill({ value: '', width: 100, readOnly: true })
                    : new Array(11).fill({ value: '', width: 100 })
                return [...ele, ...emptyArray]
            } else {
                return ele
            }
        })
        return [...tempArray];
    }

    const populateBaseYear = (e, getYear) => {
        setbaseYear(e)
        let scopeData, bioData;
        if (gridData?.length === 0) handleScopes(scope, 'US');
        if (data?.length === 0) handleScopes(scope, 'NONUS');
        if (usBiomassData?.length === 0) getBiomassDescription();
        scopeData = replaceYear(gridData, getYear)
        setGridData(scopeData)
        bioData = replaceYear(usBiomassData, getYear)
        setUsBiomass(bioData)
        scopeData = replaceYear(data, getYear)
        setData(scopeData)
        bioData = replaceYear(nonUsBiomassData, getYear)
        setNonUsBiomass(bioData)
    }

    const handleBaseYear = (e, type) => {
        let getYear = moment(e).format('yyyy');
        let current = moment(new Date()).format('yyyy');

        if ((current - getYear) > 2) {
            if (type !== 'submitted') {
                Swal.fire({
                    icon: 'warning',
                    iconColor: '#006666',
                    title: `Are you sure you want to set ${getYear} as your base year?`,
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Yes",
                    denyButtonText: `No`
                }).then((result) => {
                    if (result.isConfirmed) {

                        populateBaseYear(e, getYear)
                    }
                })
            } else {
                populateBaseYear(e, getYear)
            }
        } else {
            populateBaseYear(e, getYear)
        }
    }

    const handleScopes = async (val, type) => {
        await fetchWrapper(`${API_URL}/emissionItemsMaster/GHG/scope/${val}`)
            .then((res) => {
                type === "US" ? setUsScopeData(res?.json) : setNonUsScopeData(res?.json);
                let sData = res.json?.map((i) => i?.itemDescription);
                sData?.unshift('Description')
                sData?.push(totalScopes[val])
                const preData = loadDefaultHeader(sData, type);
                type === 'US' ? setGridData(preData) : setData(preData)
            }, (err) => console.log(err))
    }

    useEffect(() => {
        getTargetDetails();
        handleScopes(scope, 'US');
        handleScopes(nonUsScope, 'NONUS');
        getBiomassDescription();
    }, [])

    const handleTotal = (newData, col, scope, tabData) => {
        let scope1Tot = 0, scope2Tot = 0, scope3Tot = 0;
        if (scope == 1) {
            for (let i = 1; i < (scope1.length - 1); i++) {
                scope1Tot = scope1Tot + Number(newData[i][col]?.value);
            }
            newData[scope1.length - 1][col] = { ...tabData[scope1.length - 1][col], value: scope1Tot, className: 'text-right-imp' }
        }
        if (scope == 2) {
            for (let i = 1; i < (scope2.length - 1); i++) {
                scope2Tot = scope2Tot + Number(newData[i][col]?.value);
            }
            scope2Tot = scope2Tot
            newData[scope2.length - 1][col] = { ...tabData[scope2.length - 1][col], value: scope2Tot, className: 'text-right-imp' }
        }
        if (scope == 3) {
            for (let i = 1; i < (scope3.length - 1); i++) {
                scope3Tot = scope3Tot + Number(newData[i][col]?.value);
            }
            scope3Tot = scope3Tot
            newData[scope3.length - 1][col] = { ...tabData[scope3.length - 1][col], value: scope3Tot, className: 'text-right-imp' }
        }
        return newData
    }

    const handleGhgType = (val, type) => {
        type === 'US' ? setGhgUs(val) : setGhgNonUs(val)
        if (val === true) {
            handleScopes(1, type);
            const preData = loadDefaultHeader(biomassArray, type, 'biomass');
            type === 'US' ? setUsBiomass(preData) : setNonUsBiomass(preData);
        }
    }

    const getTableData = (tableData, scopeData, type) => {
        const yearValue = tableData[0];
        const targetData = tableData?.map((item, index) => {
            let itemData = {};
            if (index > 0) {
                let getCode = scopeData?.find((ele) => ele?.itemDescription === item[0]?.value);
                itemData.code = getCode?.itemCode;
                itemData.targetValues = item?.map((i, key) => {
                    if (key > 0) {
                        return {
                            year: yearValue[key]?.value,
                            value: Number(i?.value)
                        }
                    }
                })
                itemData.targetValues?.shift();
                return itemData;
            }
        })
        targetData?.shift();
        type !== 'biomass' && targetData?.pop();
        return targetData;
    }

    const handleBiomassSubmit = async (bioData, type) => {
        const bodyData = {
            scope: 0,
            type: 'BIOMASSC02',
            baseYear: moment(baseYear).format('yyyy'),
            targetYear: moment(targetYear).format('yyyy'),
            emissionTarget: emissionTarget,
            globalApplicability: type,
            trackingData: getTableData(bioData, biomassDesc, 'biomass')
        }
        const options = {
            method: 'POST',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/emissionTargetDetails`, options)
            .then((res) => {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Your work has been saved",
                    showConfirmButton: false,
                    timer: 1500
                });
            }, (err) => {
                console.log(err)
                if (err.status === 200) {
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Your Target Data has been saved",
                        showConfirmButton: false,
                        timer: 1500
                    });
                } else {
                    toast.error('Something went wrong!')
                }

            })
    }

    const handleUsSubmit = async () => {
        const bodyData = {
            scope: scope,
            type: 'GHG',
            baseYear: moment(baseYear).format('yyyy'),
            targetYear: moment(targetYear).format('yyyy'),
            emissionTarget: emissionTarget,
            globalApplicability: 1,
            trackingData: getTableData(gridData, usScopeData, 'tracking'),
        }
        const options = {
            method: 'POST',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/emissionTargetDetails`, options)
            .then((res) => {
                console.log(res.json);

            }, (err) => console.log(err));

        handleBiomassSubmit(usBiomassData, 1);
    }

    const handleNonUsSubmit = async () => {
        const bodyData = {
            scope: nonUsScope,
            type: 'GHG',
            baseYear: moment(baseYear).format('yyyy'),
            targetYear: moment(targetYear).format('yyyy'),
            emissionTarget: emissionTarget,
            globalApplicability: 2,
            trackingData: getTableData(data, nonUsScopeData, 'tracking'),
        }
        const options = {
            method: 'POST',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/emissionTargetDetails`, options)
            .then((res) => {
                console.log(res.json);

            }, (err) => console.log(err));

        handleBiomassSubmit(nonUsBiomassData, 2);
    }

    const loadBiomassData = (tableData, ghgData) => {
        ghgData?.map((tracking) => {
            tracking?.trackingData?.map((ele) => {
                ele?.targetValues?.map((item) => {
                    for (let i = 0; i < tableData?.length; i++) {
                        for (let j = 0; j < tableData[0]?.length; j++) {
                            if (i > 0) {
                                if (tableData[i][0]?.value === ele?.description) {
                                    if (tableData[0][j]?.value === item?.year) {
                                        tableData[i][j] = {
                                            value: Number(item?.value),
                                            width: 100,
                                            className: 'text-right-imp'
                                        }
                                    }
                                }
                            }
                        }
                    }
                })

            })
        })
        return tableData;
    }

    const loadData = (tableData, ghgData) => {
        ghgData?.map((tracking) => {
            tracking?.trackingData?.map((ele) => {
                ele?.targetValues?.map((item) => {
                    for (let i = 0; i < tableData?.length; i++) {
                        for (let j = 0; j < tableData[0]?.length; j++) {
                            if (i > 0) {
                                if (tableData[i][0]?.value === ele?.description) {
                                    if (tableData[0][j]?.value === item?.year) {
                                        tableData[i][j] = {
                                            value: Number(item?.value),
                                            width: 100,
                                            className: 'text-right-imp'
                                        }
                                    }
                                }
                            }
                        }
                    }
                })

            })
        })
        let total = new Array(tableData[0]?.length).fill(0);
        for (let i = 1; i < tableData?.length; i++) {
            for (let j = 1; j < tableData[0]?.length; j++) {
                if (i < (tableData?.length - 1)) {
                    total[j] = total[j] + Number(tableData[i][j]?.value);
                    tableData[tableData?.length - 1][j] = {
                        value: Number(total[j]),
                        width: 100,
                        readOnly: true,
                        className: 'text-right-imp'
                    }
                }

            }
        }

        return tableData
    }

    const handleChange = async (e, type) => {
        type === 'US' ? setScope(e.target.value, type) : setNonUsScope(e.target.value, type)
        await fetchWrapper(`${API_URL}/emissionItemsMaster/GHG/scope/${e.target.value}`)
            .then(async (res) => {
                type === 'US' ? setUsScopeData(res?.json) : setNonUsScopeData(res?.json);
                let sData = res.json?.map((i) => i?.itemDescription);
                sData?.unshift('Description')
                sData?.push(totalScopes[e.target.value])
                const preData = loadDefaultHeader(sData, type);
                await fetchWrapper(`${API_URL}/emissionTargetDetails/targetDetails/type/GHG/scope/${e.target.value}`)
                    .then((res) => {
                        const ghgData = res?.json;
                        const tabData = type === 'US' ? ghgData?.filter((i) => i?.globalApplicability === 1) : ghgData?.filter((i) => i?.globalApplicability === 2);
                        const tempData = loadData(preData, tabData);
                        type === 'US' ? setGridData(tempData) : setData(tempData);
                    }, (err) => {
                        console.log(err);
                        if (err?.status === 404) {
                            type === 'US' ? setGridData(preData) : setData(preData);
                        }
                    })
            }, (err) => console.log(err))
    }

    const getTargetData = async (type, scope) => {
        await fetchWrapper(`${API_URL}/emissionTargetDetails/targetDetails/type/GHG/scope/${scope}`)
            .then((res) => {
                const ghgData = res?.json;
                setTargetData(ghgData);
                handleBaseYear(new Date(ghgData[0]?.baseYear), 'submitted');
                const usData = ghgData?.filter((i) => i?.globalApplicability === 1);
                const nonUsData = ghgData?.filter((i) => i?.globalApplicability === 2);
                if (usData?.length > 0) {
                    const tempData = loadData(gridData, usData);
                    setGridData(tempData)
                }
                if (nonUsData?.length > 0) {
                    const tempData = loadData(data, nonUsData);
                    setData(tempData)
                }
                getBiomassTargetData();
            }, (err) => console.log(err))
    }

    const getBiomassTargetData = async () => {
        await fetchWrapper(`${API_URL}/emissionTargetDetails/targetDetails/type/BIOMASSCO2/scope/0`)
            .then((res) => {
                const data = res?.json;
                setTargetBiomassData(data);
                handleBaseYear(new Date(data[0]?.baseYear), 'submitted')
                const usData = data?.filter((i) => i?.globalApplicability === 1);
                const nonUsData = data?.filter((i) => i?.globalApplicability === 2);
                if (usData?.length > 0) {
                    const bioData = loadBiomassData(usBiomassData, usData)
                    setUsBiomass(bioData)
                }
                if (nonUsData?.length > 0) {
                    const bioData = loadBiomassData(nonUsBiomassData, nonUsData)
                    setNonUsBiomass(bioData)
                }
            }, (err) => console.log(err))
    }

    useEffect(() => {

    }, [targetDetails])

    useEffect(() => {
        if (gridRef.current) {
            getTargetData('US', scope)
        }
    }, [gridRef.current])

    useEffect(() => {
        if (usBiomassRef.current) {
            getBiomassTargetData()
        }
    }, [usBiomassRef])

    useEffect(() => {
        if (dataRef.current) {
            getTargetData('NONUS', scope)
        }
    }, [dataRef.current])

    useEffect(() => {
        if (nonUsBiomassRef.current) {
            getBiomassTargetData()
        }
    }, [nonUsBiomassRef])


    useEffect(() => {
        forceUpdate();
    }, [targetSettingData])

    useEffect(() => {
        forceUpdate();
    }, [targetBiomassData])
    

    return (
        <Fragment>
            <LayoutLoader />
            {open && <SummaryView value={open} setopenModal={setOpen} baseYear={baseYear} />}
            <div className='ptop-2'>
                <H3 attrH3={{ className: 'credits-table' }}>Annual GHG Inventory Summary & Target Settings</H3>
                <div className='summary-button'>
                    <Button color='primary' className='justify-end' onClick={() => setOpen(true)}>Summary</Button>
                </div>
            </div>
            <Row>
                <Col lg='12'>
                    <Card className='mtop-1'>
                        <CardBody>
                            <div className="target-settings">
                                <div className='d-flex justify-between mtop-1'>
                                    <div className='d-flex '>
                                        <h5 className="mb-0 f-15 text-color"><i class="fa fa-calendar"></i> Base Year: </h5>
                                        <div className='ml-1'>
                                            <DatePicker
                                                className="datepicker-control"
                                                selected={baseYear}
                                                onChange={(e) => handleBaseYear(e)}
                                                showYearPicker
                                                dateFormat="yyyy"
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex '>
                                        <h5 className="mb-0 f-15 text-color"><i class="fa fa-calendar"></i> Target Year: </h5>
                                        <div className='ml-1'>
                                            <DatePicker
                                                className="datepicker-control"
                                                selected={targetYear}
                                                onChange={(e) => setTargetYear(e)}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                minDate={new Date(moment(new Date()).add(1, 'years'))}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <h5 className="mb-0 f-15 text-color">Target Emissions Tracking:  </h5> <h5 className='ml-1 mb-0'>Absolute</h5>
                                    </div>
                                </div>
                                <div className='d-flex mtop-1 justify-between'>
                                    <div className='d-flex'>
                                        <div className="d-flex">
                                            <h5 className="mb-0 f-15 text-color">Emission Reduction Target:  </h5>
                                            <div className="ml-1 input-percent">
                                                <InputGroup>
                                                    <input
                                                        className="datepicker-control w-100 percent-padding"
                                                        name="emissionTarget"
                                                        placeholder="%"
                                                        type="number"
                                                        min={0}
                                                        max={100}
                                                        value={emissionTarget}
                                                        onChange={(e) => setTarget(e.target.value)}
                                                    />
                                                    <InputGroupText>%</InputGroupText>
                                                </InputGroup>

                                                <div className='text-danger f-12'>{(emissionTarget > 100 || emissionTarget < 0) && 'Emission Target should be in between 0 to 100'}</div>
                                            </div>
                                        </div>
                                        <div className='d-flex ml-12'>
                                            <h5 className="mb-0 f-15 text-color">GHG Inventory: </h5>
                                            <Label className='ml-1 mb-0'>
                                                <input className='checkbox_animated' type='checkbox'
                                                    checked={ghgUs} defaultChecked onChange={() => handleGhgType(!ghgUs, 'US')} /> US</Label>
                                            <Label className='ml-1 mb-0'>
                                                <input className='checkbox_animated' type='checkbox' onChange={() => handleGhgType(!ghgNonUs, 'NONUS')}
                                                    checked={ghgNonUs} />Non - US</Label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {
                                ghgUs && <div>
                                    <div className='mtop-2 d-flex'>
                                        <H3 attrH3={{ className: 'f-15' }}>GHG Inventory - US (Metric tons/yr)</H3>
                                        <div className='ml-1'>
                                            <select className='form-select width-175px' name='scopeName'
                                                value={scope} onChange={(e) => handleChange(e, 'US')}>
                                                <option value={1}>Scope 1</option>
                                                <option value={2}>Scope 2</option>
                                                <option value={3}>Scope 3</option>
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        gridData?.length > 0 && <div style={{ width: '100%', height: '100%' }} className='mtop-1'>
                                            <ReactDatasheet
                                                ref={gridRef}
                                                data={gridData}
                                                valueRenderer={(cell) => cell?.value}
                                                valueViewer={ValueViewer}
                                                onCellsChanged={(changes) => {
                                                    let newData = gridData.map((row) => [...row]);
                                                    changes.forEach(({ cell, row, col, value }) => {
                                                        if (isNumber(value)) {
                                                            newData[row][col] = { ...gridData[row][col], value }
                                                            if (col === 1) {
                                                                newData[row].map((i, key) => {
                                                                    if (key > 0) {
                                                                        i.value = value
                                                                        handleTotal(newData, key, scope, gridData)
                                                                    }
                                                                })
                                                            }
                                                            const calcData = handleTotal(newData, col, scope, gridData)
                                                            setGridData(calcData)
                                                        }
                                                    })
                                                }}
                                            />
                                            {/* <DataSheet 
                                                dataRef={gridRef}  
                                                dataGrid={gridData}
                                                onCellChanged={(changes) => {
                                                    let newData = gridData.map((row) => [...row]);
                                                    changes.forEach(({ cell, row, col, value }) => {
                                                        if (isNumber(value)) {
                                                            newData[row][col] = { ...gridData[row][col], value }
                                                            if (col === 1) {
                                                                newData[row].map((i, key) => {
                                                                    if (key > 0) {
                                                                        i.value = value
                                                                        handleTotal(newData, key, scope, gridData)
                                                                    }
                                                                })
                                                            }
                                                            const calcData = handleTotal(newData, col, scope, gridData)
                                                            setGridData(calcData)
                                                        }
                                                    })
                                                }}
                                            /> */}
                                        </div>
                                    }

                                    <div className='mtop-2'><H3 attrH3={{ className: 'f-15' }}>US Biomass CO<sub>2</sub> Emissions (Metric tons/yr)</H3></div>
                                    <div style={{ width: '100%', height: '100%' }} className='mtop-1'>
                                        <ReactDatasheet
                                            ref={usBiomassRef}
                                            data={usBiomassData}
                                            valueRenderer={(cell) => cell?.value}
                                            valueViewer={ValueViewer}
                                            onCellsChanged={(changes) => {
                                                let newData = usBiomassData.map((row) => [...row]);
                                                changes.forEach(({ cell, row, col, value }) => {
                                                    if (isNumber(value)) {
                                                        newData[row][col] = { ...usBiomassData[row][col], value }
                                                        setUsBiomass(newData)
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='mtop-1 d-flex justify-end'>
                                        <Button color='primary' onClick={handleUsSubmit} >Submit</Button>
                                    </div>
                                </div>
                            }
                            {
                                ghgNonUs && <div>
                                    <div className="d-flex mtop-2">
                                        <div >
                                            <H3 attrH3={{ className: 'f-15' }}>GHG Inventory - Non-US (Metric tons/yr)</H3>
                                        </div>
                                        <div className='ml-1'>
                                            <select className='form-select width-175px' name='scopeName'
                                                value={nonUsScope} onChange={(e) => handleChange(e, 'NONUS')}>
                                                <option value={1}>Scope 1</option>
                                                <option value={2}>Scope 2</option>
                                                <option value={3}>Scope 3</option>
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        data?.length > 0 && <div style={{ width: '100%', height: '100%' }} className='mtop-1'>
                                            <ReactDatasheet
                                                ref={dataRef}
                                                data={data}
                                                valueRenderer={(cell) => cell?.value}
                                                valueViewer={ValueViewer}
                                                onCellsChanged={(changes) => {
                                                    let newData = data.map((row) => [...row]);
                                                    changes.forEach(({ cell, row, col, value }) => {
                                                        if (isNumber(value)) {
                                                            newData[row][col] = { ...data[row][col], value }
                                                            if (col === 1) {
                                                                newData[row].map((i, key) => {
                                                                    if (key > 0) {
                                                                        i.value = value
                                                                        handleTotal(newData, key, nonUsScope, data)
                                                                    }
                                                                })
                                                            }
                                                            const calcData = handleTotal(newData, col, nonUsScope, data)
                                                            setData(calcData)
                                                        }
                                                    })
                                                }}
                                            />
                                        </div>
                                    }
                                    <div className='mtop-2'><H3 attrH3={{ className: 'f-15' }}>Non-US Biomass CO<sub>2</sub> Emissions (Metric tons/yr)</H3></div>
                                    <div style={{ width: '100%', height: '100%' }} className='mtop-1'>
                                        <ReactDatasheet
                                            ref={nonUsBiomassRef}
                                            data={nonUsBiomassData}
                                            valueRenderer={(cell) => cell?.value}
                                            valueViewer={ValueViewer}
                                            onCellsChanged={(changes) => {
                                                let newData = nonUsBiomassData.map((row) => [...row]);
                                                changes.forEach(({ cell, row, col, value }) => {
                                                    if (isNumber(value)) {
                                                        newData[row][col] = { ...nonUsBiomassData[row][col], value }
                                                        setNonUsBiomass(newData)
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className='mtop-1 d-flex justify-end'>
                                        <Button color='primary' onClick={handleNonUsSubmit}>Submit</Button>
                                    </div>
                                </div>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default TargetSetting;