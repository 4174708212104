import React, { Fragment, useCallback, useState, useMemo, useEffect } from 'react';
import { Btn, H4, H3 } from '../../../AbstractElements';
import { Spinner } from 'reactstrap';
import { AgGridReact } from 'ag-grid-react';

const CustomPagination = (props) => {
    const onPageSizeChanged = (event) => {
      props.api.paginationSetPageSize(Number(event.target.value));
    };
  
    return (
      <div>
        <span>Page Size:</span>
        <select onChange={onPageSizeChanged} value={props.api.paginationGetPageSize()}>
          {props.paginationPageSizeOptions.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
    );
  };

const AgGridTable = ({ columnData, tableData, agRef, onGridReady }) => {
    
    return (
        <Fragment>
            <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                <AgGridReact
                    columnDefs={columnData}
                    rowData={tableData}
                    domLayout='autoHeight'
                    animateRows={true}
                    rowModelType='clientSide'
                    ref={agRef}
                    enableBrowserTooltips={true}
                    overlayLoadingTemplate={
                        // '<span class="ag-overlay-loading-center">Please wait while your data are loading...</span>'
                        `<div class="custom-loading-overlay">
                        <i class="fa fa-spinner fa-spin"></i> <span class='f-15'>Please wait while your data is loading...</span>
                      </div>`
                    }
                    overlayNoRowsTemplate={
                        '<span style="padding: 10px; border: 2px solid #444; border-radius: 5px; background: #0038680f">No Data Found</span>'
                    }
                    onGridReady={onGridReady}
                    pagination={true}
                    paginationPageSize={10}
                    enterNavigatesVerticallyAfterEdit={true}
                    stopEditingWhenCellsLoseFocus={true}
                    paginationPageSizeSelector={[5, 10, 15, 20]}
                    // paginationPageSizeOptions={paginationPageSizeOptions}
                    singleClickEdit={true}
                    // suppressMenuHide={true}
                />
            </div>

        </Fragment>
    )
}

export default AgGridTable;