import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody, Spinner, Button } from 'reactstrap';
import { Breadcrumbs, H5 } from '../../../AbstractElements';
import { Link } from 'react-router-dom';
import DataTableComponent from '../../Component/DataTable/DataTableComponent';
import fetchWrapper from '../../api/fetchWrapper';
import moment from 'moment';
import { Download, Trash2 } from 'react-feather';
import { API_URL } from '../../Config/Config';
import uploadSignimg from "../../../assets/images/upload-sign.svg";
import { toast } from 'react-toastify';
import DataUploadModal from './DataUploadModal';
import { capitalize, defaultQuarter, ghgUploadStatus } from '../../api/helper';
import DeleteDocument from './DeleteDocument';
import AgGridTable from "../AgGridTable";

const EmissionList = () => {
  const [tableData, setTableData] = useState([]);
  const [showFilter, setShowFilter] = useState(false)
  const [loading, setLoading] = useState(false);
  const [docLoading, setDocLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [uploadStatus, setUpload] = useState(false)
  const [deleteStatus, setDeleteStatus] = useState(false)
  const [dataId, setDataId] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const uploadRef = useRef(null);

  const loadDocuments = async () => {
    uploadRef?.current?.api?.showLoadingOverlay();
    await fetchWrapper(`${API_URL}/document/emissionList`)
      .then((res) => {
        console.log(res);
        uploadRef?.current?.api?.hideOverlay();
        if (res.status === 200) {
          setTableData(res.json)
        }
      }, (err) => {
        uploadRef?.current?.api?.hideOverlay();
        console.log(err)
      })
  }

  const handleDelete = (id) => {
    setDataId(id)
    setDeleteOpen(true)
  }

  const columnData = [
    {
      headerName: 'Location Name',
      field: 'locationName',
      filter: true,
      flex: 2,
      sortable: true,
      suppressMovable: true,
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 0,
        buttons: ['reset']
      }
    },
    {
      headerName: 'Period',
      filter: true,
      flex: 1,
      sortable: true,
      suppressMovable: true,
      valueGetter: (params) => {
        return `${params.data.uploadPeriod === 'MONTH' ? moment().month(params.data.month).format('MMMM') + ', ' + params.data.year :
          defaultQuarter[params.data.quarter] ? defaultQuarter[params.data.quarter] + ', ' + params.data.year : params.data.year}`
      },
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 0,
        buttons: ['reset']
      },
    },
    {
      headerName: 'Upload Period',
      filter: true,
      flex: 1,
      sortable: true,
      suppressMovable: true,
      valueGetter: (params) => {
        return `${params.data.uploadPeriod ? capitalize(params.data.uploadPeriod) : '---'}`
      },
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 0,
        buttons: ['reset']
      },
    },
    {
      headerName: 'Status',
      field: 'fileUploadStatus',
      filter: true,
      flex: 1.5,
      sortable: true,
      suppressMovable: true,
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 0,
        buttons: ['reset']
      },
    },
    {
      headerName: 'Download',
      width: 100,
      cellRenderer: params => {
        return (
          <div className='mr-36'>
            <a href={params.data.path} target='_blank'>
              <Download className='document-icon' /></a>
          </div>
        )
      },
      suppressMovable: true,
    },
    {
      headerName: 'Delete',
      width: 100,
      cellRenderer: params => {
        return (
          <div onClick={() => handleDelete(params.data.id)} >
            <Trash2 className='emission-trash' />
          </div>
        )
      },
      suppressMovable: true,
    },
  ]

  useEffect(() => {
    if (uploadStatus === true || deleteStatus === true)
      loadDocuments();
  }, [uploadStatus, deleteStatus])

  const onGridReady = useCallback((params) => {
    loadDocuments()
  })

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <div className='d-flex mtop-1'>
            <Breadcrumbs parent="Carbon Emission" title="List" mainTitle="GHG Emission" />
            <Button color='primary' onClick={() => setOpen(true)} className='text-right  action_button mr-13'> 
              <i style={{ fontSize: '25px' }} className="icofont  icofont-plus-circle mr-1" ></i> Data Upload
            </Button>
            {open === true && <DataUploadModal value={open} setOpenModal={setOpen} setUpload={setUpload} />}
          </div>

          <Card className="newtable carbon-emission">
            <CardBody className='data-tables emission-table ptop-0'>
              <div className="emission-upload">
                <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                  <AgGridTable
                    columnData={columnData}
                    tableData={tableData}
                    agRef={uploadRef}
                    onGridReady={onGridReady}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {deleteOpen && <DeleteDocument value={deleteOpen} setOpenModal={setDeleteOpen} dataId={dataId} setDelete={setDeleteStatus} />}
    </Fragment>
  )
}

export default EmissionList;