import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import DataTableComponent from '../../Common/Component/DataTable/DataTableComponent';
import { PlusCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import AddAsrUser from './AddAsrUser';
import fetchWrapper from '../../Common/api/fetchWrapper';
import { API_URL } from '../../Common/Config/Config';
import { toast } from 'react-toastify';
import AgGridTable from '../../Common/Component/AgGridTable';

const AsrUserlist = () => {
    const [open, setOpen] = useState(false);
    const [showFilter, setShowFilter] = useState(false)
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false)
    const [orgData, setOrgData] = useState();
    const userRef = useRef(null);

    const handleStatus = async (userName, status) => {
        let updateStatus = status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
        const options = {
            method: 'PUT'
        }
        await fetchWrapper(`${API_URL}/users/${userName}/${updateStatus}`, options)
            .then((res) => {
                if (res.status === 200) {
                    if (res?.json?.status === 'INACTIVE') {
                        toast.error('User has been disabled!')
                    } else {
                        toast.success('User has been enabled!')
                    }
                    loadData()
                }
            })
            .catch(err => console.log(err))
    }

    const loadOrgData = async () => {
        await fetchWrapper(`${API_URL}/users/orgDetails`)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setOrgData(res.json[0])
                }
            }, (err) => {
                console.log(err)
            })
    }


    const loadData = async () => {
        userRef?.current?.api?.showLoadingOverlay();
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/users`, options)
            .then((res) => {
                console.log(res)
                setData(res.json)
                userRef?.current?.api?.hideOverlay();
            })
            .catch((err) => {
                userRef?.current?.api?.hideOverlay();
                console.error(err)
            })
    }
    useEffect(() => {
        loadOrgData()
    }, [])


    useEffect(() => {
        loadData()
    }, [open])

    const columnData = [
        {
            headerName: 'First Name',
            field: 'first_name',
            filter: true,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Last Name',
            field: 'last_name',
            filter: true,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Email Id',
            field: 'email',
            filter: true,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Phone',
            field: 'phone',
            filter: true,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Status',
            filter: false,
            width: 100,
            sortable: true,
            suppressMovable: true,
            cellRenderer: (params) => {
                return (
                    <div className='mr-36'>
                        <label className="switch">
                            <input type="checkbox" id="togBtn" checked={params.data.status}
                                onChange={() => handleStatus(params.data.username, params.data.status)}
                            />
                            <div className="slider round">
                                <span className="on"></span>
                                <span className="off"></span>
                            </div>
                        </label>
                    </div>
                )
            }
        },
        {
            headerName: 'Edit',
            width: 90,
            cellRenderer: params => {
                return (
                    <Link to={`${process.env.PUBLIC_URL}/aso-admin/edit-aso-user/${params.data.id}`}>
                    <Edit className={`edit-icon`} />
                </Link>
                )
            },
            suppressMovable: true,
        },
    ]

    const handleClick = () => setOpen(true);

    const onGridReady = useCallback(async(params) => {
        loadData();
    })
    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <div className="d-flex mtop-1">
                            <Breadcrumbs mainTitle="Users" parent="Users" title="List" />
                            <div className="text-right  action_button">
                                <div className="link action_button" onClick={handleClick}>
                                    <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont  icofont-plus-circle" ></i>
                                    &nbsp;&nbsp;{"Add User"}</div>
                            </div>
                        </div>
                        <Card className=" newtable">
                            <CardBody className='data-tables ptop-0'>
                            <div className="groups-table">
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridTable
                                            columnData={columnData}
                                            tableData={data}
                                            agRef={userRef}
                                            onGridReady={onGridReady}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    {open && <AddAsrUser value={open} setOpenModal={setOpen} orgData={orgData} />}
                </Row>
            </Container>
        </Fragment>
    );

};

export default AsrUserlist;