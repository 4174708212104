import React, { Fragment, useState, useEffect } from "react";
import { CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { countries } from "../../Data/CountryList/Defaultdata";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import ButtonLoader from "../ButtonLoader";
import { defaultUserType } from "../../api/helper";
import { currencySymbol } from "../../api/currencyData";
import Tab from 'react-bootstrap/Tab';
import Nav from "react-bootstrap/Nav";

const AdminForm = ({ type, title, action, navigateUrl, cancel, preData, setAdd }) => {
    const [loading, setLoading] = useState(false)
    const [stateList, setStateList] = useState([]);
    const [country, setCountry] = useState();
    const [data, setData] = useState(preData)
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues, resetField } = useForm({ defaultValues: data });
    const [workspace, setWorkspace] = useState();
    const [ghgEmission, setGhgEmission] = useState(false);
    const [state, setState] = useState(preData?.state);
    const [tenantData, setTenantData] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [currency, setCurrency] = useState();
    const [countryList, setCountryList] = useState([]);
    const [relationshipOwner, setRelationship] = useState();
    const [allowSuborgs, setAllow] = useState(true);
    const [ghgReportingFrequancy, setFrequancy] = useState(preData?.hg_reporting_frequency || 'MONTHLY')
    const [activeKey, setActiveKey] = useState('1');

    const getCountry = async() => {
        await fetchWrapper(`${API_URL}/country`)
        .then((res) => {
            setCountryList(res.json)
        }, (err) => console.log(err))
    }

    useEffect(()=>{
        getCountry();
    }, [])

    const handleCountry = async (id) => {
        console.log(id)
        setCountry(id)
        await fetchWrapper(`${API_URL}/state/country/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    setStateList(res.json)
                }
            }, (err) => {
                console.log(err)
            })
    }

    const countryChangeEvent = (item, countryList) => {
        const selectedItem = countryList.find((countryItem) => countryItem.country === item);
        setCountry(item);
        setStateList(selectedItem.states);
        setValue('state', '')
        setState();
    }
    const [orgType, setOrgType] = useState('');
    const navigate = useNavigate();

    const userType = sessionStorage.getItem('userType');

    const loadTenantData = async () => {
        await fetchWrapper(`${API_URL}/tenants`)
            .then((res) => {
                setTenantData(res.json);
            }, (err) => console.log(err))
    }

    const getCurrency = async () => {
        await fetchWrapper(`${API_URL}/currency`)
            .then((res) => {
                setCurrencyList(res.json)
            }, (err) => console.log(err))
    }

    const onSubmit = async (data) => {
        setLoading(true)
        let bodyData = { ...data };
        data.ghgEmission = data?.ghgEmission === 'true' ? true : false;
        data.subOrgsEnabled = data?.subOrgsEnabled === 'true' ? true : false;
        let url, message;
        let method = action === "Add" ? 'POST' : 'PUT';
        if (action === 'Add') {
            url = `${API_URL}/organization`
            message = 'Organization Created Successfully!'
        }
        if (action === 'Edit') {
            url = `${API_URL}/organization/${data.id}`
            message = 'Organization Updated Successfully!'
        }
        const options = {
            method: method,
            body: data
        }
        await fetchWrapper(url, options)
            .then((res) => {
                if (res.ok) {
                    setAdd(true);
                    toast.success(message)
                    setLoading(false)
                    action === 'Add' && cancel()
                    action === 'Edit' && navigate(navigateUrl)
                }
            })
            .catch((err) => {
                setLoading(false)
                if (err.status == 409) {
                    toast.error('Organization Already Exist..')
                    console.log(err)
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }

    useEffect(() => {
        setData(prev => ({ ...prev, ...preData }))
        loadTenantData();
        reset(preData)
        setOrgType(preData?.orgType)
        getCurrency();
        // const getCurrency = Object.keys(currencySymbol);
        // setCurrencyList(getCurrency);
        if (preData) {
            console.log(preData?.country)
            let statesList = countries.find((item) => item.country === preData?.country)
            setStateList(statesList?.states)
            setGhgEmission(preData?.ghgEmission)
            setCurrency(preData?.currency)
            setValue('relationshipOwner', preData?.relationshipOwner)
            setRelationship(preData?.relationshipOwner)
            setAllow(preData?.subOrgsEnabled)
        }
    }, [preData])

    return (
        <Fragment>
            <Row>
                <Col lg={action === 'Add' ? 12 : 10}>
                    {title && <H3 attrH3={{ className: 'mtop-2 ml-15' }}>{title}</H3>}
                    <Card className={`${action === 'Add' && 'mb-0'} ${action === 'Edit' && ''}`}>
                        <CardBody className={action === 'Add' && 'p-0 mb-0 bs-none'}>
                            <Form
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <Tab.Container activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                                    <div>
                                        <Nav variant="tabs" defaultActiveKey={1}>
                                            <Nav.Item>
                                                <Nav.Link eventKey={1}>Company</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey={2}>Contact</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>

                                    <Tab.Content className="mtop-1">
                                        <Tab.Pane eventKey={1}>
                                            <Row>
                                                <Col lg="6">
                                                    <div>
                                                        <FormGroup >
                                                            <Label for="exampleEmail">  Company Name<span className='required'>*</span> </Label>
                                                            <input
                                                                className="form-control"
                                                                name="companyName"
                                                                placeholder=""
                                                                type="text"
                                                                required
                                                                defaultValue={data?.companyName || ''}
                                                                {...register('companyName', { required: true })}
                                                            />
                                                            <span className="text-danger">{errors.companyName && 'Company Name is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg='6'>
                                                    <div>
                                                        <FormGroup style={{ marginBottom: "8px " }}>
                                                            <Label for="exampleSelect"> Website <span className='required'>*</span> </Label>
                                                            <input
                                                                className="form-control"
                                                                id="exampleEmail"
                                                                name="website"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.website || ''}
                                                                {...register('website', { required: true, pattern: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ })}
                                                            />
                                                            <span className="text-danger">{errors?.website?.type === 'required' && 'Website is Required'}</span>
                                                            <span className="text-danger">{errors?.website?.type === 'pattern' && 'Invalid Website URL'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg='12'>
                                                    <div>
                                                        <FormGroup style={{ marginBottom: "8px " }}>
                                                            <Label for="exampleSelect"> Address <span className='required'>*</span></Label>
                                                            <input
                                                                className="form-control"
                                                                id="exampleEmail"
                                                                name="address"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.address || ''}
                                                                {...register('address', { required: true })}
                                                            />
                                                            <span className="text-danger">{errors.address && 'Address is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup >
                                                        <Label>City<span className='required'>*</span> </Label>
                                                        <input
                                                            className="form-control"
                                                            name="city"
                                                            placeholder=""
                                                            type="text"
                                                            required
                                                            defaultValue={data?.city || ''}
                                                            {...register('city', { required: true })}
                                                        />
                                                        <span className="text-danger">{errors.city && 'City is Required'}</span>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup >
                                                        <Label>Zipcode<span className='required'>*</span> </Label>
                                                        <input
                                                            className="form-control"
                                                            name="zipcode"
                                                            placeholder=""
                                                            type="text"
                                                            required
                                                            defaultValue={data?.zipcode}
                                                            {...register('zipCode', { required: true, maxLength: 8, minLength: 5 })}
                                                        />
                                                        <span className="text-danger">{errors.zipCode?.type === 'required' && 'Zipcode is Required'}</span>
                                                        <span className="text-danger">{errors.zipCode?.type === 'maxLength' && 'Zipcode should be less than 8 characters'}</span>
                                                        <span className="text-danger">{errors.zipCode?.type === 'minLength' && 'Zipcode should be more than 5 characters'}</span>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div>
                                                        <FormGroup >
                                                            <Label for="exampleSelect"> Country <span className='required'>*</span></Label>
                                                            <select className="form-select" name="country"
                                                                defaultValue={data?.country || ''}
                                                                {...register('country', { required: true })}
                                                                onChange={e => handleCountry(e.target.value)}
                                                            >
                                                                <option value=''>-Select Country-</option>
                                                                {countryList.map((item, i) => {
                                                                    return (
                                                                        <option value={item.id}>{item.name}</option>
                                                                    )
                                                                }
                                                                )}
                                                            </select>
                                                            <span className="text-danger">{errors.country && 'Country is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div>
                                                        <FormGroup >
                                                            <Label for="exampleSelect"> State <span className='required'>*</span></Label>
                                                            <select className="form-select" name="state"
                                                                value={state}
                                                                {...register('state', { required: true })}
                                                                onChange={(e) => setState(e.target.value)}
                                                            >
                                                                <option>-Select State-</option>
                                                                {
                                                                    stateList && stateList.length > 0 && stateList.map(i => {
                                                                        return (<option value={i.id}>{i.name}</option>)
                                                                    })
                                                                }
                                                            </select>
                                                            <span className="text-danger">{errors.state && 'state is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div>
                                                        <FormGroup >
                                                            <Label for="exampleSelect"> Organization Type <span className='required'>*</span></Label>
                                                            <select className="form-select" name="orgType" defaultValue={data?.orgType || ''}
                                                                {...register('orgType', { required: true })}
                                                                onChange={(e) => setOrgType(e.target.value)}>
                                                                <option value=''>-Select Organization Type-</option>
                                                                <option value={'ASSET_OWNER'}>Asset Owner</option>
                                                                <option value={'ASSESSOR'}>Assessor</option>
                                                                <option value={'ASSET_MANAGER'}>Asset Manager</option>
                                                            </select>
                                                            <span className="text-danger">{errors.orgType && 'orgType is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                {
                                                    orgType === 'ASSET_OWNER' && <Col lg="6">
                                                        <div>
                                                            <FormGroup >
                                                                <Label>Powered By <span className='required'>*</span></Label>
                                                                <select className="form-select" name='relationshipOwner' value={relationshipOwner}
                                                                    {...register('relationshipOwner', { required: true })} onChange={(e) => setRelationship(e.target.value)}
                                                                >
                                                                    <option value={''}>-Select Powered By-</option>
                                                                    {
                                                                        tenantData && tenantData?.length > 0 && tenantData?.map(i => <option value={i.tenantIdUUID}>{i.tenantName}</option>)
                                                                    }
                                                                </select>
                                                            </FormGroup>
                                                        </div>
                                                    </Col>
                                                }
                                            </Row>

                                            {
                                                orgType === 'ASSET_OWNER' && <Row>
                                                    <Col sm='6'>
                                                        <FormGroup className="mb-6">
                                                            <label>Currency</label>
                                                            <select className="form-select" name='currency' defaultValue={data?.currency}
                                                                {...register('currency')}>
                                                                <option value={''}>-Select Currency-</option>
                                                                {
                                                                    currencyList?.length > 0 && currencyList?.map((item) => {
                                                                        return (
                                                                            <option value={item.currencyCode}>{item.description}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                    {
                                                        preData && <Col>
                                                            <FormGroup className="mb-6">
                                                                <label>GHG Reporting Frequency</label>
                                                                <select className="form-select" name='ghg_reporting_frequency'
                                                                    value={ghgReportingFrequancy} {...register('ghg_reporting_frequency')}
                                                                    onChange={(e) => setFrequancy(e.target.value)}>
                                                                    <option value={''}>-Select Frequency-</option>
                                                                    <option value={'MONTHLY'}>Monthly</option>
                                                                    <option value={'BIMONTHLY'}>Bimonthly</option>
                                                                    <option value={'QUARTERLY'}>Quarterly</option>
                                                                    <option value={'QUADMONTHLY'}>Quadmonthly</option>
                                                                    <option value={'SEMIANNUAL'}>Semi Annual</option>
                                                                    <option value={'ANNUAL'}>Annual</option>
                                                                </select>
                                                            </FormGroup>
                                                        </Col>
                                                    }
                                                    <Col sm='6'>
                                                        <FormGroup className="mb-3">
                                                            <label>Enable Sub Organization</label>
                                                            <div className='d-flex mtop-1'>
                                                                <input className="form-check-input radio_animated" type='radio' name='subOrgsEnabled'
                                                                    defaultChecked={allowSuborgs === true ? true : false} value={true}
                                                                    {...register('subOrgsEnabled')} onChange={(e) => setAllow(true)} />
                                                                <lable>Yes</lable>
                                                                <input className="form-check-input radio_animated ml-2" type='radio' name='subOrgsEnabled'
                                                                    defaultChecked={allowSuborgs === false ? true : false} value={false}
                                                                    {...register('subOrgsEnabled')} onChange={(e) => setAllow(false)} />
                                                                <lable>No</lable>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            }
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={2}>
                                            <Row>
                                                <Col lg='6'>
                                                    <div>
                                                        <FormGroup style={{ marginBottom: "8px " }}>
                                                            <Label for="exampleSelect">First Name </Label>
                                                            <input
                                                                className="form-control"
                                                                id="exampleEmail"
                                                                name="firstName"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.firstName || ''}
                                                                {...register('firstName')}
                                                            />
                                                            <span className="text-danger">{errors.firstName && 'First Name is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div>
                                                        <FormGroup >
                                                            <Label for="exampleEmail">  Last Name </Label>
                                                            <input
                                                                className="form-control"
                                                                name="lastName"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.lastName || ''}
                                                                {...register('lastName')}
                                                            />
                                                            <span className="text-danger">{errors.firstName && 'First Name is Required'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div>
                                                        <FormGroup >
                                                            <Label for="exampleEmail"> Email ID (Login ID)</Label>
                                                            <input
                                                                className="form-control"
                                                                id="exampleEmail"
                                                                name="email"
                                                                placeholder=""
                                                                type="email"
                                                                disabled={action === 'Add' ? false : true}
                                                                defaultValue={data?.email || ''}
                                                                {...register('email', { pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                                                            />
                                                            <span className="text-danger">{errors?.email?.type === 'required' && 'email is Required'}</span>
                                                            <span className="text-danger">{errors?.email?.type === 'pattern' && 'Invalid Email'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div>
                                                        <FormGroup style={{ marginBottom: "8px " }}>
                                                            <Label for="exampleSelect"> Phone </Label>
                                                            <input
                                                                className="form-control"
                                                                id="exampleEmail"
                                                                name="phoneNumber"
                                                                placeholder=""
                                                                type="text"
                                                                defaultValue={data?.phone || ''}
                                                                {...register('phoneNumber', { pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/ })}
                                                            />
                                                            <span className="text-danger">{errors.phoneNumber?.type === 'required' && 'Phone Number is Required'}</span>
                                                            <span className="text-danger">{errors.phoneNumber?.type === 'pattern' && 'Invalid Phone Number'}</span>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                                <Row>
                                    <div className="d-flex " style={{ marginTop: "25px" }}>
                                        {
                                            loading ? <ButtonLoader loaderText={`${action === "Add" ? action : 'Updat'}ing`} color="primary" /> : <Button color="primary" >{action === "Add" ? action : 'Update'}</Button>

                                        }
                                        <Button color="secondary" className="ml-1" onClick={() => action === 'Add' ? cancel() : navigate(navigateUrl)}> Cancel</Button>
                                        {
                                            action === 'Edit' && data && data?.orgType === "ASSET_MANAGER" &&
                                            <div className=" ml-1 manage-asm-but">
                                                <Link to={`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/asm-management/${data?.id}`}>
                                                    <Button color="primary" >Manage Asset Manager</Button>
                                                </Link>
                                            </div>
                                        }
                                    </div>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default AdminForm;