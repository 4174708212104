import React, { Fragment, useState } from "react";
import { Container, Row, Col, CardBody, Card, FormGroup, Label, Button } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";


const LandingPage = () => {
    const [landingPage, setLandingPage] = useState();
    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col lg='8'>
                        <div className="d-flex mtop-1">
                            <Breadcrumbs mainTitle="Landing Page" />
                        </div>
                        <Card>
                            <CardBody className='data-tables responsive-view'>
                                <Row>
                                    <Col lg='6'>
                                        <div>
                                            <FormGroup>
                                                <Label for="exampleEmail">Select Landing Page <span className='required'>*</span> </Label>
                                                <select name="landingPage" className="form-select" value={landingPage} onChange={(e) => setLandingPage(e.target.value)}>
                                                    <option value=''>-Select Landing Page-</option>
                                                </select>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="mtop-1">
                                    <Button color="primary">Submit</Button>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default LandingPage;