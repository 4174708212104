import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Row, Col, Card, CardBody, Label, Button, InputGroup, InputGroupText } from 'reactstrap';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { H3, H4, H5 } from '../../../AbstractElements';
import ReactDatasheet from 'react-datasheet';
import Swal from 'sweetalert2';
import { defaultHeader, scope1, scope2, scope3, biomassEmissions, replaceYear, isNumber, totalScopes, totalScopesDescription } from './TargetData';
import SummaryView from './SummaryView';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import { ValueViewer } from './TargetData';
import DataSheet from './ReactDataSheet';
import { thousandSeparator } from '../../api/helper';

const TargetSettingView = () => {
    const [baseYear, setbaseYear] = useState();
    const [targetYear, setTargetYear] = useState();
    const [emissionTarget, setTarget] = useState();
    const [biomassData, setUsBiomass] = useState([]);
    const [open, setOpen] = useState(false);
    const [scopeCategory, setScopeCategory] = useState({});
    const [scope1Data, setScope1Data] = useState([]);
    const [scope2Data, setScope2Data] = useState([]);
    const [scope3Data, setScope3Data] = useState([]);
    const [biomass, setBiomassData] = useState([]);
    const [bioCategory, setBioCategory] = useState([]);
    const [baseYearData, setBaseYearData] = useState();
    const [offset, setOffset] = useState([]);
    const [summary, setSummary] = useState([]);
    const [calculatedTarget, setCalculatedTarget] = useState(0);

    const gridRef = useRef(null);

    const loadBaseYearData = (scopeData, responseData, baseYear, readStatus) => {
        for (let i = 0; i < scopeData?.length; i++) {
            for (let j = 0; j < scopeData[0]?.length - 1; j++) {
                responseData?.map((ele) => {
                    if (i > 0) {
                        if (ele?.itemKey === scopeData[i][0]?.value) {
                            if ((Number(ele?.year) === scopeData[0][j + 1]?.value) && (scopeData[0][j + 1]?.value === Number(baseYear))) {
                                scopeData[i][j] = {
                                    value: Number(ele?.value).toFixed(2),
                                    width: 100,
                                    className: `text-right-imp`,
                                    readOnly: true
                                }
                            }
                            if (j > 0) {
                                if ((Number(ele?.year) === Number(baseYear)) && (scopeData[0][j + 1]?.value !== Number(baseYear))) {
                                    scopeData[i][j] = {
                                        value: Number(ele?.value).toFixed(2),
                                        width: 100,
                                        className: `text-right-imp`,
                                        readOnly: readStatus
                                    }
                                }
                            }
                        }
                        if ((Number(ele?.year) === scopeData[0][j + 1]?.value) && (scopeData[0][j + 1]?.value === Number(baseYear))) {
                            if (j > 0) {
                                scopeData[i][j] = {
                                    ...scopeData[i][j],
                                    readOnly: true
                                }
                            }
                        }
                    }
                })
                if (!responseData) {
                    if (i > 0 && j === 1) {
                        scopeData[i][j] = { ...scopeData[i][j], readOnly: true }
                    }
                    if (i > 0 && j > 1) {
                        scopeData[i][j] = { ...scopeData[i][j], readOnly: readStatus }
                    }
                }
            }
        }
        let total = new Array(scopeData[0]?.length).fill(0);
        for (let i = 1; i < scopeData?.length; i++) {
            for (let j = 1; j < scopeData[0]?.length - 1; j++) {
                if (i < (scopeData?.length - 1)) {
                    total[j] = total[j] + Number(scopeData[i][j]?.value);
                    scopeData[scopeData?.length - 1][j] = {
                        value: Number(total[j]).toFixed(2),
                        width: 100,
                        readOnly: true,
                        className: 'text-right-imp f-w-600'
                    }
                }
            }
        }
        return scopeData;
    }

    const calculateTarget = (newData) => {
        console.log(newData);
        const base = moment(baseYear).format('yyyy');
        const targetYr = targetYear ? moment(targetYear).format('yyyy') : 0;
        const baseTotal = newData[newData?.length - 1][1]?.value;
        let targetTotal = 0;
        for (let j = 1; j < newData[1].length; j++) {
            if (Number(targetYr) === newData[0][j + 1]?.value) {
                targetTotal = newData[newData?.length - 1][j]?.value;
            }
        }
        let calcTarget = ((Number(baseTotal) - Number(targetTotal)) / Number(baseTotal)) * 100;
        console.log(baseTotal, targetTotal, calcTarget)
        setCalculatedTarget(Number(calcTarget).toFixed(2));
    }

    const loadSummaryData = (scope1Data, scope2Data, scope3Data) => {
        let newData = [...summary];
        newData[1] = [...scope1Data[scope1Data?.length - 1]];
        newData[2] = [...scope2Data[scope2Data?.length - 1]];
        newData[3] = [...scope3Data[scope3Data?.length - 1]];
        newData[5] = [...offset[offset?.length - 1]];
        for (let j = 0; j < newData[0]?.length - 1; j++) {
            newData[1][j] = { ...newData[1][j], className: 'text-right-imp f-w-500' };
            newData[2][j] = { ...newData[2][j], className: 'text-right-imp f-w-500' };
            newData[3][j] = { ...newData[3][j], className: 'text-right-imp f-w-500' };
            newData[5][j] = { ...newData[5][j], className: 'text-right-imp f-w-500' };
        }
        let total = new Array(newData[0]?.length).fill(0);
        for (let i = 1; i <= newData?.length; i++) {
            for (let j = 1; j < newData[0]?.length - 1; j++) {
                if (i < 4) {
                    total[j] = total[j] + Number(newData[i][j]?.value);
                    newData[4][j] = {
                        value: Number(total[j]).toFixed(2),
                        width: 100,
                        readOnly: true,
                        className: 'text-right-imp f-w-600'
                    }
                }
                if (i === 5) {
                    total[j] = total[j] - Number(newData[i][j]?.value);
                    newData[6][j] = {
                        value: Number(total[j]).toFixed(2),
                        width: 100,
                        readOnly: true,
                        className: 'text-right-imp f-w-600'
                    }
                }
            }
        }
        calculateTarget(newData);
        setSummary(newData);

    }

    const handleLoadGrid = async (json, year) => {
        if (json) {
            await setScope1Data(loadBaseYearData(scope1Data, json['Scope 1 Emissions'], year, targetYear ? false : true));
            await setScope2Data(loadBaseYearData(scope2Data, json['Location-Based Scope 2 Emissions'], year, targetYear ? false : true));
            await setScope3Data(loadBaseYearData(scope3Data, json['Scope 3 Emissions'], year, targetYear ? false : true));
            loadSummaryData(scope1Data, scope2Data, scope3Data)
        }
    }

    const getTargetDetails = async (year) => {
        await fetchWrapper(`${API_URL}/targetsettings/emissionDetails/${year}`)
            .then(res => {
                console.log(res.json)
                setBaseYearData(res.json);
                handleLoadGrid(res.json, year)
            }, (err) => {
                Swal.fire("Please Update GHG Emission data..")
                console.log(err)
            })
    }

    const populateBaseYear = async (e, getYear) => {
        setbaseYear(e);
        setScope1Data(replaceYear(scope1Data, getYear));
        setScope2Data(replaceYear(scope2Data, getYear));
        setScope3Data(replaceYear(scope3Data, getYear));
        setBiomassData(replaceYear(biomass, getYear));
        setOffset(replaceYear(offset, getYear));
        setSummary(replaceYear(summary, getYear))
        await getTargetDetails(getYear);
    }

    const handleBaseYear = (e, type) => {
        let getYear = moment(e).format('yyyy');
        populateBaseYear(e, getYear)
    }

    const populateYearRows = () => {
        let tempData = defaultHeader?.map((item) => {
            return {
                value: item,
                readOnly: true,
                width: 100,
                className: 'menu-style target-header'
            }
        })
        const baseYearValue = typeof baseYear === 'object' ? moment(baseYear).format('yyyy') : baseYear;
        tempData?.map((item, index) => {
            if (index > 1) {
                item.value = Number(baseYearValue) + (index)
            } else {
                item.value = Number(baseYearValue)
            }
        })
        return tempData;
    }

    const loadDefaultHeader = (columnData, scopeLabel, gridData, key) => {
        let getHeaderArray = [], columnArray = [];
        let tempData;
        //form scope and biomass grid
        if (key !== 'offset') {
            const sideColumn = columnData?.length > 0 ? columnData : scopeLabel === 'biomass' ? biomassEmissions : [];
            for (let i = 0; i < sideColumn?.length; i++) {
                if (i === 0) {
                    tempData = [
                        {
                            value: scopeLabel,
                            rowSpan: sideColumn?.length,
                            readOnly: true,
                            width: 100,
                            className: 'text-center verticle-middle f-w-600'
                        }
                    ]
                } else {
                    tempData = [
                        {
                            value: sideColumn[i],
                            readOnly: true,
                            width: 300,
                            className: `side-menu ${(i === ((sideColumn?.length - 1))) &&
                                (scopeLabel !== 'Biomass CO2 Emissions') && 'f-w-600'} ${scopeLabel === 'Summary' && i === 4 && 'f-w-600'}`
                        }
                    ]
                }
                columnArray.push(tempData)
            }
        }
        if (baseYear) {
            if (key === 'biomass') {
                if (biomassData[0]?.length > 0) {
                    getHeaderArray = biomassData[0]?.filter((i, index) => index > 0);
                } else {
                    getHeaderArray = populateYearRows();
                }
            } else {
                if (gridData?.length > 0) {
                    getHeaderArray = gridData[0]?.filter((i, index) => index > 0)
                } else {
                    getHeaderArray = populateYearRows();
                }
            }
        } else {
            getHeaderArray = defaultHeader?.map((item) => {
                return {
                    value: item,
                    readOnly: true,
                    width: 100,
                    className: 'menu-style target-header f-w-600'
                }
            })
        }
        //form offset grid
        if (key !== 'offset') {
            columnArray[0] = columnArray && getHeaderArray && [...columnArray[0], ...getHeaderArray];
            let tempArray = columnArray?.map((ele, index) => {
                if (index > 0) {
                    let emptyArray;
                    emptyArray = key ? new Array(11).fill({ value: '', width: 100, readOnly: scopeLabel === 'Summary' ? true : false, className: scopeLabel === 'Summary' && 'text-right-imp' }) :
                        (index === (columnArray?.length - 1))
                            ? new Array(11).fill({ value: '', width: 100, readOnly: true })
                            : new Array(11).fill({ value: '', width: 100 })
                    return [...ele, ...emptyArray]
                } else {
                    return ele
                }
            })
            return [...tempArray];
        } else {
            const offsetHeader = {
                value: scopeLabel,
                rowSpan: 6,
                readOnly: true,
                width: 100,
                className: 'text-center verticle-middle f-w-600'
            }
            getHeaderArray?.unshift(offsetHeader)
            const tempArray = Array(getHeaderArray?.length - 1).fill({ value: '' });
            const totalArray = tempArray.map((item, index) => {
                if (index > 0)
                    return {
                        value: '',
                        readOnly: true,
                        width: 100,
                        className: 'menu-style'
                    }
                else
                    return {
                        value: 'Total Offsets',
                        readOnly: true,
                        width: 300,
                        className: 'side-menu f-w-600'
                    }
            })
            return [[...getHeaderArray], [...tempArray], [...tempArray], [...tempArray], [...tempArray], [...totalArray]];
        }
    }


    const handleScopes = async (val) => {
        let data;
        await fetchWrapper(`${API_URL}/emissionItemsMaster/GHG/scope/${val}`)
            .then((res) => {
                let sData = res.json?.map((i) => i?.itemDescription);
                sData?.unshift('Description')
                sData?.push(totalScopes[val])
                data = sData;
            }, (err) => console.log(err))
        return data;
    }

    const getScopes = async () => {
        let scopeData, usScope = {}, scopes = ['scope1', 'scope2', 'scope3'], scopeLabels = ['Scope 1', 'Scope 2', 'Scope 3'];
        for (let i = 1; i <= 3; i++) {
            scopeData = await handleScopes(i);
            usScope[scopes[i - 1]] = scopeData
            const preData = loadDefaultHeader(scopeData, scopeLabels[i - 1], i === 1 ? scope1Data : i === 2 ? scope2Data : scope3Data);
            i === 1 ? setScope1Data(preData) : i === 2 ? setScope2Data(preData) : setScope3Data(preData);
        }
        setScopeCategory(usScope);
    }

    const getBiomassCategory = async () => {
        await fetchWrapper(`${API_URL}/emissionItemsMaster/BIOMASSC02/scope/0`)
            .then((res) => {
                let sData = res.json?.map((i) => i?.itemDescription);
                sData?.unshift('Description');
                setBioCategory(sData);
                const bioData = loadDefaultHeader(sData, 'Biomass CO2 Emissions', [], 'biomass');
                setBiomassData(bioData);
            }, (err) => console.log(err))
    }

    const handleTotal = (newData, col, tabData, scopeDesc) => {
        let scopeTot = 0;
        for (let i = 1; i < (scopeDesc.length - 1); i++) {
            scopeTot = scopeTot + Number(newData[i][col]?.value);
        }
        newData[scopeDesc.length - 1][col] = {
            ...tabData[scopeDesc.length - 1][col],
            value: Number(scopeTot)?.toFixed(2), className: 'text-right-imp f-w-600'
        }
        return newData;
    }

    const formOffsetGrid = () => {
        const offsetData = loadDefaultHeader([], 'Offset', [], 'offset');
        setOffset(offsetData)
    }

    const formSummaryGrid = () => {
        const summaryGrid = loadDefaultHeader(totalScopesDescription, 'Summary', [], 'summary')
        setSummary(summaryGrid);
    }

    useEffect(() => {
        getScopes()
        getBiomassCategory()
        formOffsetGrid()
        formSummaryGrid()
    }, [])

    useEffect(() => {
        if (baseYear) {
            getTargetDetails(moment(baseYear).format('yyyy'))
        }
    }, [targetYear])

    useEffect(() => {
        if (scope1Data?.length > 0 && scope2Data?.length > 0 && scope3Data?.length > 0 && offset?.length > 0) {
            loadSummaryData(scope1Data, scope2Data, scope3Data)
        }
    }, [scope1Data, scope2Data, scope3Data, offset])

    const onSubmit = async () => {

    }

    return (
        <Fragment>
            <LayoutLoader />
            {open && <SummaryView value={open} setopenModal={setOpen} baseYear={baseYear} />}
            <div className='ptop-2'>
                <H3 attrH3={{ className: 'credits-table' }}>GHG Emissions Reductions & Target Settings (Metric tons/yr)</H3>
            </div>

            <Card className=''>
                <CardBody className='ptop-0'>
                    <div className="target-settings mtop-1">
                        <Row>
                            <Col lg='3'>
                                <div className='d-flex '>
                                    <h5 className="mb-0 f-15 text-color w-7rem">
                                        Base Year: </h5>
                                    <div className='ml-0-25 w-7rem'>
                                        <DatePicker
                                            className="datepicker-control"
                                            selected={baseYear}
                                            onChange={(e) => handleBaseYear(e)}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            minDate={new Date('2015')}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg='3'>
                                <div className='d-flex '>
                                    <h5 className="mb-0 f-15 text-color w-7rem">
                                        Target Year: </h5>
                                    <div className='ml-0-25 w-7rem'>
                                        <DatePicker
                                            className="datepicker-control"
                                            selected={targetYear}
                                            onChange={(e) => setTargetYear(e)}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            minDate={new Date()}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg='3'>
                                <div className="d-flex w-21rem">
                                    <h5 className="mb-0 f-15 text-color">Emission Reduction Target:  </h5>
                                    <div className="ml-0-25 input-percent w-7rem">
                                        <InputGroup>
                                            <input
                                                className="datepicker-control w-100 percent-padding"
                                                name="emissionTarget"
                                                placeholder="0"
                                                type="number"
                                                min={0}
                                                max={100}
                                                value={emissionTarget}
                                                onChange={(e) => setTarget(e.target.value)}
                                            />
                                            <InputGroupText>%</InputGroupText>
                                        </InputGroup>
                                        <div className='text-danger f-12'>{(emissionTarget > 100 || emissionTarget < 0) && 'Emission Target should be in between 0 to 100'}</div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg='3'>
                                <div className="d-flex ml-0-25">
                                    <h5 className="mb-0 f-15 text-color">Target Emissions Tracking:  </h5> <h5 className='ml-0-25 mb-0'>Absolute</h5>
                                </div>
                            </Col>
                            <Col lg='4'>
                                <div className='d-flex  mtop-hf'>
                                    <h5 className="mb-0 f-15 text-color">Calculated Reduction Target:  </h5>
                                    <div className="ml-0-25 input-percent w-7rem">
                                        <InputGroup>
                                            <input
                                                className="datepicker-control w-100 percent-padding"
                                                name="emissionTarget"
                                                placeholder="0"
                                                type="number"
                                                disabled={true}
                                                min={0}
                                                max={100}
                                                value={calculatedTarget}
                                            />
                                            <InputGroupText>%</InputGroupText>
                                        </InputGroup>
                                    </div>
                                </div>
                            </Col>
                        </Row>



                    </div>


                    <div className='mtop-1'>
                        <div className='mtop-1'>
                            <ScopeDataSheet scopeData={scope1Data} scope={scopeCategory?.scope1}
                                setScope={setScope1Data} ref={gridRef} handleTotal={handleTotal} type={1} loadSummaryData={loadSummaryData} />
                        </div>
                        <div className='line-div-5'></div>
                        <div className='mtop-4px'>
                            <ScopeDataSheet scopeData={scope2Data} scope={scopeCategory?.scope2}
                                setScope={setScope2Data} ref={gridRef} handleTotal={handleTotal} type={1} loadSummaryData={loadSummaryData} />
                        </div>
                        <div className='line-div-5'></div>
                        <div className='mtop-4px'>
                            <ScopeDataSheet scopeData={scope3Data} scope={scopeCategory?.scope3}
                                setScope={setScope3Data} ref={gridRef} handleTotal={handleTotal} type={1} loadSummaryData={loadSummaryData} />
                        </div>
                        <div className='line-div-5'></div>
                        <div className='mtop-4px'>
                            <ScopeDataSheet scopeData={biomass}
                                setScope={setBiomassData} ref={gridRef} type={2} />
                        </div>
                        <div className='line-div-5'></div>
                        <div className='mtop-4px'>
                            <ScopeDataSheet scopeData={offset}
                                setScope={setOffset} ref={gridRef} type={3} />
                        </div>
                        <div className='line-div-5'></div>
                        <div className='mtop-4px'>
                            <ScopeDataSheet scopeData={summary}
                                setScope={setSummary} ref={gridRef} type={4} />
                        </div>
                    </div>
                    <div className='mtop-1'>
                        <Button color='primary' className={`${Number(emissionTarget) <= Number(calculatedTarget) ? '' : 'disabled-button'}`}>Submit</Button>
                    </div>

                </CardBody>
            </Card>

        </Fragment>
    )
}

export default TargetSettingView;

const ScopeDataSheet = ({ scopeData, scope, setScope, ref, handleTotal, type, loadSummaryData }) => {
    return (
        <DataSheet
            dataRef={ref}
            dataGrid={scopeData}
            onCellChanged={(changes) => {
                changes.forEach(({ cell, row, col, value }) => {
                    let newData = scopeData.map((row) => [...row]);
                    if (type === 1) { // 1 for scope(1,2,3), 2 for biomass
                        if (isNumber(value)) {
                            if (col === 1) {
                                newData[row].map((i, key) => {
                                    if (key > 0) {
                                        i.value = Number(value).toFixed(2)
                                        handleTotal(newData, key, scopeData, scope)
                                    }
                                })
                            } else {
                                newData[row].map((i, key) => {
                                    if (key > 0) {
                                        if (key >= col) {
                                            i.value = 0
                                            i.value = Number(value).toFixed(2);
                                            i.width = 100
                                        }
                                        if (key < col && key !== 0) {
                                            if (i.value) {
                                                i.value = Number(i.value).toFixed(2)
                                                i.width = 100
                                            } else {
                                                i.value = 0
                                                i.width = 100
                                            }

                                        }
                                        handleTotal(newData, key, scopeData, scope)
                                    }
                                })
                            }
                            const calcData = handleTotal(newData, col, scopeData, scope)
                            setScope(calcData)
                        }
                    } else if (type === 2) {
                        if (isNumber(value)) {
                            if (col === 1) {
                                newData[row].map((i, key) => {
                                    if (key > 0) {
                                        i.value = value
                                    }
                                })
                            }
                            setScope(newData)
                        }

                    } else if (type === 3) {
                        let total = 0;
                        if (col === 0) {
                            newData[row][col] = { ...scopeData[row][col], value }
                        }
                        if (col > 0 && isNumber(value)) {
                            newData[row][col] = { ...scopeData[row][col], value }
                            for (let i = 1; i < scopeData.length - 1; i++) {
                                total = total + Number(newData[i][col]?.value)
                            }
                            newData[scopeData?.length - 1][col] = {
                                ...scopeData[scopeData?.length - 1][col], value: thousandSeparator(total),
                                className: 'text-right-imp'
                            }
                        }
                        setScope(newData)
                    }
                })
            }}
        />
    )
}

