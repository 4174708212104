import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Badge, Label, Button } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DataTableComponent from '../DataTable/DataTableComponent';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { Eye, Edit, Trash2 } from 'react-feather';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import AddEvCharger from './AddEvCharger';
import { defaultUserType } from '../../api/helper';
import { useSelector } from 'react-redux';
import DeleteCharger from './DeleteEvCharger';
import { toast } from 'react-toastify';
import AgGridTable from '../AgGridTable';
import { useRef } from 'react';
import { useCallback } from 'react';

const EvChargerList = () => {
    const [tableData, setTableData] = useState([]);
    const [showFilter, setShowFilter] = useState(false)
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const projectId = useParams()?.id
    const projectName = useParams()?.name;
    const userType = useSelector(state => state.user?.userType);
    const [deleteModalOpen, setDeleteOpen] = useState(false);
    const [deleteRow, setDeleteRow] = useState();
    const [activeStatus, setActiveStatus] = useState(true)
    const [activeCharger, setActiveCharger] = useState([])
    const [inactiveCharger, setInactiveCharger] = useState([]);
    const [addStatus, setAddStatus] = useState(false);
    const [deleteStatus, setDelete] = useState(false);
    const navigate = useNavigate()

    const chargerRef = useRef();

    const tableColumns = [
        {
            headerName: 'Charger ID',
            field: 'chargerId',
            filter: true,
            width: 180,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Station Name',
            field: 'stationName',
            filter: true,
            width: 180,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Type',
            field: 'currentType',
            filter: true,
            width: 130,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Gun Capacity (Amp)',
            field: 'gunCapacity',
            filter: true,
            width: 200,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Status',
            filter: false,
            width: 130,
            sortable: true,
            suppressMovable: true,
            cellRenderer: (params) => {
                return (
                    <div className=''>
                        <label className="switch">
                            <input type="checkbox" id="togBtn"
                                checked={params.data.isactive}
                                onChange={() => handleStatus(params.data.id, params.data.isactive)}
                            />
                            <div className="slider round">
                                <span className="on"></span>
                                <span className="off"></span>
                            </div>
                        </label>
                    </div>
                )
            }
        },
        {
            headerName: 'Edit',
            width: 60,
            cellRenderer: params => {
                return (
                    <Link to={`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/edit-ev-charger/${params.data.id}`} >
                        <Edit className={`edit-icon `} />
                    </Link>
                )
            },
            suppressMovable: true,
        },
        {
            headerName: 'Delete',
            width: 70,
            cellRenderer: params => {
                return (
                    <div onClick={() => handleDeleteModal(params.data.id)} >
                        <Trash2 className={'trash-icon'} />
                    </div>
                )
            },
            suppressMovable: true,
        },
    ]

    const handleStatus = async (id, status) => {
        let updateStatus = status === true ? false : true;
        const options = {
            method: 'PUT'
        }
        await fetchWrapper(`${API_URL}/evchargers/status/${id}/${updateStatus}`, options)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    if (res?.json?.isactive === true)
                        toast.success('EV Charger Activated Successfully!')
                    else
                        toast.error('EV Charger Deactivated Successfully!')
                    loadEvCharges()
                }
            })
            .catch(err => {
                console.log(err)

            })
    }

    const loadEvCharges = async () => {
        await fetchWrapper(`${API_URL}/evchargers/project/${projectId}`)
            .then((res) => {
                console.log(res)
                let activeData = res.json?.filter(i => i.isactive === true)
                setActiveCharger(activeData)
                let deactiveData = res.json?.filter(i => i.isactive === false)
                setInactiveCharger(deactiveData);
                setActiveStatus(true)
                setTableData(activeData)
                setAddStatus(false)
            }, (err) => { console.log(err) })
    }

    useEffect(() => {
        if (addStatus) {
            loadEvCharges();
        }
        if (deleteStatus) {
            loadEvCharges();
        }
    }, [addStatus, deleteStatus])

    const handleDeleteModal = (id) => {
        setDeleteRow(id)
        setDeleteOpen(true)
    }

    const handleClick = () => {
        setOpen(true)
    }


    const handleActiveSwitch = (val) => {
        setLoading(true)
        let status = val === true ? false : true;
        setActiveStatus(pre => (pre, status));
        if (status === true) {
            setLoading(false)
            setTableData(activeCharger)
        } else {
            setLoading(false)
            setTableData(inactiveCharger);
        }
    }

    const handleBack = () => {
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/view-project/${projectId}`)
    }
    const handleMonitor = () => {
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/ev-charging-consumption/${projectId}/${projectName}`)
    }

    const handleStation = () => {
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/ev-station-lists/${projectId}/${projectName}`)
    }

    const onGridReady = useCallback(async (params) => {
        chargerRef.current.api.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/evchargers/project/${projectId}`)
            .then((res) => {
                console.log(res)
                let activeData = res.json?.filter(i => i.isactive === true)
                setActiveCharger(activeData)
                let deactiveData = res.json?.filter(i => i.isactive === false)
                setInactiveCharger(deactiveData);
                setActiveStatus(true)
                setTableData(activeData)
                chargerRef.current.api.hideOverlay();
            }, (err) => {
                console.log(err)
                chargerRef.current.api.hideOverlay();
                if (err.status === 404) {
                    chargerRef.current.api.showNoRowsOverlay();
                }
            })
    })
    return (
        <Fragment>
            <LayoutLoader />
            <Row>
                <Col sm="10">
                    <div className="d-flex">
                        <Breadcrumbs parent="EV Stations List" title="List" mainTitle={`${projectName} - EV Chargers`} />
                        <div className="text-right  action_button mr-13">
                            <div className="link action_button" onClick={handleClick}>
                                <i className="icon-style icofont  icofont-plus-circle" ></i>
                                &nbsp;&nbsp;{"Add New"}
                            </div>
                        </div>
                    </div>
                    <Container fluid={true}>
                        <Card className="newtable ev-charges">
                            <CardBody className='data-tables '>
                                <Row>
                                    <Col>
                                        <div className='d-flex active-switch'>
                                            <div>
                                                <Label className='active-switch-lable title-color'>Active Chargers</Label>
                                            </div>
                                            <label className="switch ml-1 ">
                                                <input type="checkbox" id="togBtn"
                                                    checked={activeStatus}
                                                    onChange={() => handleActiveSwitch(activeStatus)}
                                                />
                                                <div className="slider round">
                                                    <span className="on"></span>
                                                    <span className="off"></span>
                                                </div>
                                            </label>
                                        </div>
                                    </Col>
                                </Row>

                                {/* <DataTableComponent tableData={tableData} columnsData={tableColumns}
                                    setShowFilter={setShowFilter} loading={loading} filterStatus={false} /> */}
                                <Row>
                                    <Col sm='12'>
                                        <div className="charger-table">
                                            <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                                <AgGridTable
                                                    columnData={tableColumns}
                                                    tableData={tableData}
                                                    agRef={chargerRef}
                                                    onGridReady={onGridReady}
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                </Row>

                                <div>
                                    <Button color='secondary' onClick={handleBack}>Back</Button>
                                    <Button color="primary" className="ml-1  fl-right" onClick={handleStation}>Add Station</Button>
                                    {/* <Button color="primary" className="ml-1  fl-right" onClick={handleMonitor}>Monitoring</Button> */}
                                </div>
                            </CardBody>
                        </Card>

                        {
                            deleteModalOpen && <DeleteCharger dataId={deleteRow} value={deleteModalOpen} setOpenModal={setDeleteOpen} setDelete={setDelete} />
                        }
                        {
                            open && <AddEvCharger value={open} setOpenModal={setOpen} projectId={projectId} projectName={projectName} setAdd={setAddStatus} />
                        }
                    </Container>
                </Col>
            </Row>
        </Fragment>
    )
}

export default EvChargerList;