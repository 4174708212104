import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row, Table, Button, Input, CardHeader, FormGroup } from 'reactstrap';
import { Breadcrumbs, H6, P, Image, Btn, H3, H5, H4 } from "../../../AbstractElements";
import { Download, PlusCircle } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import DeclineModal from "./DeclineModal";
import AssignAssessor from "./AssignAssessor";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { defaultStatus, isValidNumber, defaultUserType, defaultMonitoringURL, capitalize, calculationList } from "../../api/helper";
import ProjectTransitionModal from "../ProjectTransitionModal";
import { toast } from "react-toastify";
import ButtonLoader from '../ButtonLoader';
import LayoutLoader from "../LayoutLoader/LayoutLoader";
import ProgressBarModel from "../ProgressBar";
import RegistrySubstates from "../RegistrySubstates";
import { useSelector, useDispatch } from "react-redux";
import { PROJECT_DETAILS } from "../../Constant";
import { Camera } from "react-feather";
import ReactCountryFlag from "react-country-flag";
import Tab from 'react-bootstrap/Tab';
import Nav from "react-bootstrap/Nav";
import defautImg from '../../../assets/images/document/DefaultImage.png';
import EvChargeCalculation from "../BaselineCalculation/EvChargeCalculation";
import SolarCalculation from "../BaselineCalculation/SolarCalculation";
import PlasticCalculation from "../BaselineCalculation/PlasticCalculation";

const ViewPage = ({ type, documents, projectId }) => {
    const [openDecline, setOpenDecline] = useState(false);
    const [openAssignModal, setOpenAssignModal] = useState(false);
    const [projectData, setProjectData] = useState();
    const [assessorOrgList, setOrgList] = useState([]);
    const [description, setDescription] = useState()
    const [notes, setNotes] = useState()
    const [openTransition, setOpenTransition] = useState(false);
    const [loading, setLoading] = useState(false)
    const [substateOpen, setSubstateOpen] = useState(false);
    const [notesError, setNotesError] = useState(false);
    const [splitValue, setSplitValue] = useState(projectData?.splitValue);
    const userType = useSelector(state => state?.user?.userType)
    const [uploadLoading, setUploadLoading] = useState(false)
    const [countryData, setCountryData] = useState();
    const allowedTypes = ['admin', 'sys-user']
    const [activeKey, setActiveKey] = useState('1');
    const defaultSize = {
        'LESSTHAN20K': '<20,000',
        'BETWEEN20K': '20,000-100,000',
        'BETWEEN1M': '100,001–1,000,000',
        'GREATERTHAN1M': '>1,000,000'
    }
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [CalcComponent, setCalcComponent] = useState();

    const loadData = async () => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/organization/type/ASSESSOR`, options)
            .then(res => {
                console.log(res)
                setOrgList(res.json)
            })
    }

    const loadNotesData = async () => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/note/project/${projectId}`, options)
            .then((res) => {
                console.log(res)
                setNotes(res.json)
            })
    }

    const getAllCountry = async () => {
        await fetchWrapper(`${API_URL}/country`)
            .then((res) => {
                setCountryData(res.json)
            }, (err) => console.log(err))
    }

    const handleCountryCode = (val) => {
        const countryCode = countryData?.find(ele => ele.name === val);
        return countryCode?.countryCode;
    }


    const handleNotes = (val) => {
        setDescription(val)
        if (notesError === true) {
            setNotesError(false)
        }
    }

    const loadProjectData = async () => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/projects/${projectId}`, options)
            .then((res) => {
                console.log(res)
                const data = res.json
                dispatch({
                    type: PROJECT_DETAILS,
                    payload: data
                })
                setSplitValue((Number(data?.splitValue) * 100))
                setProjectData(data)
            })
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        loadData()
        loadNotesData()
        loadProjectData()
        getAllCountry()
    }, [])

    useEffect(() => {

    }, [notes])

    const handleAccept = async () => {
        if (!splitValue) {
            toast.error('Please enter Split value.')
            return
        } else if (!isValidNumber(splitValue)) {
            toast.error('Invalid Value')
            return
        } else {
            setLoading(true)
            const options = {
                method: 'PATCH',
                body: {
                    description: description != '' ? description : null,
                    splitValue: splitValue
                }
            }
            await fetchWrapper(`${API_URL}/projects/${projectId}/validate`, options)
                .then(res => {
                    setLoading(false)
                    setOpenAssignModal(true)
                    setDescription('')
                    loadProjectData()
                })
                .catch(error => {
                    if (error.status == 200) {
                        setLoading(false)
                        if (projectData?.projectOrigin !== 'External') {
                            setOpenAssignModal(true)
                        }
                        setDescription('')
                        loadProjectData()
                        toast.success('Project Accepted Successfully!')
                    }
                    if (error.status == 406) {
                        toast.error('Unit Type is Required!')
                        setLoading(false)
                    }

                    if (error.status === 400) {
                        toast.error('Start Date and End date is Required!')
                        setLoading(false)
                        setTimeout(() => {
                            navigate(`${process.env.PUBLIC_URL}/admin/edit-project/${projectId}`)
                        }, 1000)
                    }
                })
        }

    }

    const defaultUserTypes = {
        ASO_USER: 'Asset Owner',
        ASO_ADMIN: 'Asset Owner',
        ASR_USER: 'Assessor',
        ASR_ADMIN: 'Assessor',
        SYS_ADMIN: 'Platform Admin',
        SYS_USER: 'Platform User'
    }

    const handleActiveProject = async () => {
        setLoading(true)
        const options = {
            method: 'PATCH'
        }
        await fetchWrapper(`${API_URL}/projects/${projectId}/active`, options)
            .then((res) => {
                setLoading(false)
                navigate(`${process.env.PUBLIC_URL}/${type}/projects`)
                toast.success('Project Activated Successfully!')
            })
            .catch(err => {
                if (err.status == 200) {
                    setLoading(false)
                    toast.success('Project Activated Successfully!')
                    loadProjectData()
                } else {
                    setLoading(false)
                    toast.error(`Please Check Project Details.. ${JSON.stringify(err.json)}`)
                }
            })
    }

    const handleSendNotes = async () => {
        if (!description) {
            setNotesError(true)
        } else {
            const options = {
                method: 'POST',
                body: {
                    projectId: projectId,
                    description: description
                }
            }

            await fetchWrapper(`${API_URL}/note`, options)
                .then(res => {
                    console.log(res)
                    if (res.ok) {
                        loadNotesData()
                        setDescription('')
                    }
                })
                .catch(error => console.log(error))
        }
    }

    const handleIssued = () => {
        sessionStorage.setItem('projectName', projectData?.name);
        sessionStorage.setItem('currency', projectData?.unitType);
        navigate(`${process.env.PUBLIC_URL}/admin/project/${projectId}/credits-issued`)
    }

    const handleCertify = () => {
        navigate(`${process.env.PUBLIC_URL}/admin/project/${projectId}/certify-credits`)
    }

    const handleMonitor = () => {
        if (projectData?.subCategoryItemName === 'EV Charging Stations' && projectData?.monitoringLevel === 'CHARGER') {
            navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/ev-charging-consumption/${projectId}/${projectData?.name}`)
        } else if (projectData?.subCategoryItemName === 'EV Charging Stations' && projectData?.monitoringLevel === 'STATION') {
            navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/ev-station-consumption/${projectId}/${projectData?.name}`)
        } else if (projectData?.projectSubCategoryName === 'Plastic Credit') {
            navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/monitoring/${projectId}/${projectData?.name}/plastic/${projectData?.dataCollectionFrequency}`)
        } else if (projectData?.subCategoryItemName === 'Building Management Systems') {
            navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/monitoring/${projectId}/${projectData?.name}/bms/${projectData?.dataCollectionFrequency}`)
        } else if (projectData?.subCategoryItemName === 'Solar Captive') {
            navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/monitoring/${projectId}/${projectData?.name}/solar/${projectData?.dataCollectionFrequency}`)
        } else if (projectData?.subCategoryItemName === 'BMS/Lighting') {
            navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/monitoring/${projectId}/${projectData?.name}/lighting/${projectData?.dataCollectionFrequency}`)
        }

    }

    const monitoringPermission = () => {
        if (['ASO_ADMIN', 'ASO_USER', 'ASM_USER', 'ASM_ADMIN'].includes(userType) && ((projectData?.subCategoryItemName === 'EV Charging Stations') || (projectData?.projectSubCategoryName === 'Plastic Credit') || (projectData?.subCategoryItemName === 'Building Management Systems') || (projectData?.subCategoryItemName === 'Solar Captive')) && (projectData?.assessmenttype !== 'ONETIME') && (projectData?.status === 'ACTIVE'))
            return true
    }

    const uploadProjectProfile = async (e) => {
        setUploadLoading(true)
        const authToken = sessionStorage.getItem('accessToken')
        let data = new FormData();
        data.append('image', e.target.files[0]);
        data.append('projectId', projectId);
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${authToken}`
            },
            body: data
        }
        await fetchWrapper(`${API_URL}/projects/uploadImage`, options)
            .then((res) => {
                console.log(res)
                setUploadLoading(false)
                toast.success('Profile Uploaded Successfully!')
                loadProjectData();
            }, (err) => {
                console.log(err)
                setUploadLoading(false)
                toast.error('Something went wrong')
            })
    }

    const onFileChange = async (e) => {
        console.log()
        const authToken = sessionStorage.getItem('accessToken')
        const file = e.target.files[0];
        let data = new FormData();
        data.append('file', file)
        // data.append('documentTypeName', documentType)
        // data.append('otherDocumentTypeName', otherValue)
        const options = {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${authToken}`
            },
            body: data
        }
        await fetchWrapper(`${API_URL}/document?projectId=${projectId}`, options)
            .then((res) => {
                if (res.ok) {
                    toast.success('Document Uploaded successfully!')
                }
            })
            .catch((error) => {
                console.error(error)
                if (error.status == 412) {
                    toast.error('File with the same name already exists')
                }
            })
    }

    return (
        <Fragment>
            <LayoutLoader />
            <Breadcrumbs mainTitle={projectData?.name} childParent="Project List" title={'View'} parent="Carbon Credits" subParent="Projects" />
            {
                projectData?.status === 'SUBMITTED_TO_REGISTRY' && allowedTypes.includes(type) && <div className="status-btn">
                    <Button color="primary" onClick={() => setSubstateOpen(true)} >Change Status</Button>
                </div>
            }
            {
                openAssignModal && assessorOrgList &&
                <AssignAssessor value={openAssignModal} setOpenModal={setOpenAssignModal}
                    orgList={assessorOrgList} projectId={projectData?.id} type={type} />
            }
            {
                substateOpen && <RegistrySubstates value={substateOpen} setOpenModal={setSubstateOpen}
                    projectId={projectId} registryType={projectData?.registry} />
            }
            <Tab.Container activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                <div className="p-relative">
                    <div>
                        <Nav variant="pills" defaultActiveKey="1">
                            <Nav.Item>
                                <Nav.Link eventKey="1">Profile</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="2">Details</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="3">Status</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="4">Notes</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="5">Documents</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="6">Baseline Calculation</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>

                    <div className="project-button-div">
                        {
                            projectData?.status === 'VALIDATED' && allowedTypes.includes(type) && projectData?.projectOrigin !== 'External' && <div className="paynow-btn w-200">
                                <Button color="primary" onClick={() => setOpenAssignModal(true)} >Assign Assessor</Button>
                            </div>
                        }

                        {
                            (['ASO_ADMIN', 'ASO_USER', 'ASM_ADMIN', 'ASM_USER'].includes(userType) && projectData?.subCategoryItemName === 'EV Charging Stations') && <div className="paynow-btn w-200">
                                <Button color="primary"
                                    onClick={() => navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/${defaultMonitoringURL[projectData?.monitoringLevel]}/${projectData?.id}/${projectData?.name}`)} >
                                    {projectData?.monitoringLevel === 'CHARGER' ? 'Manage EV Chargers' : 'Manage EV Stations'}</Button>
                            </div>
                        }
                    </div>
                    <div>
                        <Btn attrBtn={{ color: 'secondary', className: 'trans-button top-0', onClick: () => setOpenTransition(true) }} >
                            View Transitions
                        </Btn>
                    </div>

                </div>

                <Tab.Content className="mtop-1">
                    <Tab.Pane eventKey={'1'}>
                        <div id="profile">
                            <Row>
                                <Col lg="8" sm="12">
                                    <Card className="browser-table mb-18" >
                                        <CardHeader className="p-10">
                                            <div>
                                                <div className="d-flex-center mb-1 project-img">
                                                    <img className="br-10 "
                                                        src={projectData?.image ? `data:image/jpeg;base64,${projectData?.image}` : defautImg}
                                                        height={350} width={780} />
                                                    <div className="project-img-hover">
                                                        {
                                                            ['ASO_ADMIN', 'ASO_USER', 'ASM_ADMIN', 'ASM_USER'].includes(userType) &&
                                                            <div className="mtop-1 icon-div">
                                                                <label className="d-flex">
                                                                    {uploadLoading === true ? <i class="fa fa-spinner fa-spin"></i> : <Camera />}
                                                                    <input type='file'
                                                                        onChange={uploadProjectProfile}
                                                                        hidden
                                                                        accept='.jpeg, .jpg, .png'
                                                                    />
                                                                </label>

                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </CardHeader>
                                    </Card>
                                </Col>
                                <Col lg='4' >
                                    <Card>
                                        <CardBody >
                                            <div>
                                                <H3>Overview</H3>
                                            </div>
                                            <div className="mtop-2 line-div"></div>
                                            <div className="mtop-2">
                                                <div >
                                                    <h3 className="overview-title">Start Date</h3>
                                                    <div className="d-flex">
                                                        <i class="fa-regular fa-calendar-days i-color"></i>
                                                        <h3 className="ml-1 mb-0 f-15">{projectData?.start_date != null ? moment(projectData?.start_date).format('DD MMM, YYYY') : ''}</h3>
                                                    </div>
                                                </div>
                                                <div className="mtop-1" >
                                                    <h3 className="overview-title">End Date</h3>
                                                    <div className="d-flex">
                                                        <i class="fa-regular fa-calendar-days i-color"></i>
                                                        <h3 className="ml-1 mb-0 f-15">{projectData?.end_date != null ? moment(projectData?.end_date).format('DD MMM, YYYY') : ''}</h3>
                                                    </div>
                                                </div>
                                                <div className="mtop-1" >
                                                    <h3 className="overview-title">Country</h3>
                                                    <div className="d-flex">
                                                        <i class="fa-regular fa-globe i-color mr-1"></i>
                                                        <ReactCountryFlag countryCode={projectData?.countryCode} style={{
                                                            width: '2em',
                                                            height: '2em',
                                                        }} svg />
                                                    </div>
                                                </div>
                                                <div className="mtop-1" >
                                                    <h3 className="overview-title">Project Type</h3>
                                                    <div className="d-flex">
                                                        <i class="fa-regular fa-bolt i-color"></i>
                                                        <p className="ml-1 mb-0">{projectData?.subCategoryItemName}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={'3'}>
                        <div id="status">
                            <Row>
                                <Col lg='12'>
                                    <Card className="mb-18">
                                        <CardBody>
                                            <H3>Project Status</H3>
                                            <ProgressBarModel status={projectData?.status} registrySubstate={projectData?.registrySubState} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={'2'}>
                        <div id="details">
                            <Row>
                                <Col lg='6'>
                                    <Card className=" mb-18">
                                        <CardBody>
                                            <div className="table-responsive mb-0 mt-2">
                                                <Table className="table ">
                                                    <tbody>
                                                        <tr>
                                                            <td className=" fw-td-bold">Country</td> <td> {projectData?.country}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-td-bold">Project Subcategory</td> <td>{projectData?.projectSubCategoryName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-td-bold">Registry Type </td><td>{projectData?.registryName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-td-bold">Assessment Type</td> <td>{projectData?.assessmenttype === "ONETIME" ? 'One Time Assessment' : 'Periodic Assessment'}</td>
                                                        </tr>

                                                        <tr>
                                                            <td className="fw-td-bold">Registry URL</td>
                                                            <td>{projectData?.registry_url ? <a href={projectData?.registry_url} target="_blank">{projectData?.registry_url}</a> : 'NIL'}</td>
                                                        </tr>
                                                        {
                                                            projectData?.registryName === 'VERRA' && projectData?.subCategoryItemName !== 'EV Charging Stations' && <tr>
                                                                <td className="fw-td-bold">Average Annual Carbon Units </td> <td>{projectData?.average_annual_volume_of_vc_us} </td>
                                                            </tr>
                                                        }
                                                        {
                                                            projectData?.registryName === 'VERRA' && projectData?.subCategoryItemName !== 'EV Charging Stations' && <tr>
                                                                <td className="fw-td-bold">Project Associated with JNR Program </td> <td>{projectData?.isassociatedwithjnr ? 'Yes' : 'No'}</td>
                                                            </tr>
                                                        }
                                                        {
                                                            projectData?.registryName === 'ONCRA' && <tr>
                                                                <td className="fw-td-bold">Surface Area (ha)</td><td>{projectData?.surface_area}</td>
                                                            </tr>
                                                        }
                                                        {
                                                            projectData?.registryName === 'ONCRA' && <tr>
                                                                <td className="fw-td-bold">Estimation of Carbon Capacity</td><td>{projectData?.estimation_carbon_capacity}</td>
                                                            </tr>
                                                        }
                                                        {
                                                            projectData?.registryName === 'ONCRA' && <tr>
                                                                <td className="fw-td-bold">Specification of full life cycle analysed operational impact</td><td>{projectData?.operational_impacts}</td>
                                                            </tr>
                                                        }
                                                        {
                                                            projectData?.registryName === 'ONCRA' && <tr>
                                                                <td className="fw-td-bold">Potential per ha per year (tCO2/ha/y)</td><td>{projectData?.potential_per_ha_year}</td>
                                                            </tr>
                                                        }
                                                        {
                                                            projectData?.registryName === 'ONCRA' && <tr>
                                                                <td className="fw-td-bold">Total sequestration potential</td><td>{projectData?.total_sequestration_potential}</td>
                                                            </tr>
                                                        }
                                                        {
                                                            projectData?.registryName === 'ONCRA' && <tr>
                                                                <td className="fw-td-bold">Name of field or facility</td><td>{projectData?.facility_field_name}</td>
                                                            </tr>
                                                        }
                                                        {
                                                            projectData?.registryName === 'ONCRA' && <tr>
                                                                <td className="fw-td-bold">Legal ownership of field or facility</td><td>{projectData?.legal_ownership}</td>
                                                            </tr>
                                                        }

                                                        {
                                                            projectData?.subCategoryItemName === 'EV Charging Stations' && <tr>
                                                                <td className="fw-td-bold">Monitoring Level</td> <td>{projectData?.monitoringLevel}</td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td className="fw-td-bold">Carbon Credit Calculation Day</td>
                                                            <td>{projectData?.carbonCreditsCalculationDay}<sup>th</sup> of Every Month</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg='6'>
                                    <Card className=" mb-18">
                                        <CardBody>
                                            <div className="table-responsive mb-0 mt-2">
                                                <Table className="table ">
                                                    <tbody >
                                                        <tr>
                                                            <td className="fw-td-bold">Project Category</td> <td>{projectData?.projectCategoryName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-td-bold">Project Subcategory Item</td> <td>{projectData?.subCategoryItemName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-td-bold">Unit Type</td> <td>{projectData?.unitType}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="fw-td-bold">Assessor </td>
                                                            <td>{projectData?.assessor_org_name ?? 'NIL'}</td>
                                                        </tr>
                                                        {
                                                            projectData?.registryName === 'VERRA' && (projectData?.subCategoryItemName !== 'EV Charging Stations') && <tr>
                                                                <td className="fw-td-bold">Methodology</td>
                                                                <td>{projectData?.methodology}</td>
                                                            </tr>
                                                        }
                                                        {
                                                            projectData?.registryName === 'VERRA' && (projectData?.subCategoryItemName !== 'EV Charging Stations') && <tr>
                                                                <td className="fw-td-bold">Project Size </td> <td>{defaultSize[projectData?.size]} {projectData?.size && 'MtCO2e'}</td>
                                                            </tr>
                                                        }

                                                        {
                                                            projectData?.registryName === 'ONCRA' && <tr>
                                                                <td className="fw-td-bold">Carbon Baseline</td><td>{projectData?.carbon_baseline}</td>
                                                            </tr>
                                                        }
                                                        {
                                                            projectData?.registryName === 'ONCRA' && <tr>
                                                                <td className="fw-td-bold">Total gross sequestration potential</td><td>{projectData?.total_gross_sequestration_potential}</td>
                                                            </tr>
                                                        }
                                                        {
                                                            projectData?.registryName === 'ONCRA' && <tr>
                                                                <td className="fw-td-bold">Project duration (Years)</td><td>{projectData?.project_duration}</td>
                                                            </tr>
                                                        }
                                                        {
                                                            projectData?.registryName === 'ONCRA' && <tr>
                                                                <td className="fw-td-bold">Potential whole project per year (tCO2/y)</td><td>{projectData?.potential_whole_project_year}</td>
                                                            </tr>
                                                        }
                                                        {
                                                            projectData?.registryName === 'ONCRA' && <tr>
                                                                <td className="fw-td-bold">Estimated carbon income at ETS price</td><td>{projectData?.estimated_carbon_income}</td>
                                                            </tr>
                                                        }
                                                        {
                                                            projectData?.registryName === 'ONCRA' && <tr>
                                                                <td className="fw-td-bold">Geo-location</td><td>{projectData?.geo_location}</td>
                                                            </tr>
                                                        }
                                                        {
                                                            projectData?.registryName === 'ONCRA' && <tr>
                                                                <td className="fw-td-bold">Remove & store carbon</td><td>{projectData?.remove_store_carbon}</td>
                                                            </tr>
                                                        }
                                                        {
                                                            projectData?.assessmenttype === "PERIODIC" && <tr>
                                                                <td className="fw-td-bold">Periodic Assessment Type</td> <td>{capitalize(projectData?.periodicassessmenttype)}</td>
                                                            </tr>
                                                        }
                                                        {
                                                            projectData?.assessmenttype === 'PERIODIC' && projectData?.assessment_due_date && <tr>
                                                                <td className="fw-td-bold">Assessment Date</td>
                                                                <td>{projectData?.assessment_due_date}</td>
                                                            </tr>
                                                        }
                                                        {
                                                            projectData?.assessmenttype === "PERIODIC" && <tr>
                                                                {
                                                                    projectData?.frequency && <td className="fw-td-bold">Assessment Frequency</td>
                                                                }
                                                                {
                                                                    projectData?.frequency && <td>{capitalize(projectData?.frequency)}</td>
                                                                }
                                                                {
                                                                    projectData?.volume && <td className="fw-td-bold">Volume</td>
                                                                }
                                                                {
                                                                    projectData?.volume && <td>{projectData?.volume}</td>
                                                                }
                                                            </tr>
                                                        }
                                                        {
                                                            (projectData?.periodicassessmenttype === 'FREQUENCY') && <tr>
                                                                <td className="fw-td-bold">Data Collection Frequency</td>
                                                                <td>{capitalize(projectData?.dataCollectionFrequency)}</td>
                                                            </tr>
                                                        }
                                                        {/* {
                                                    projectData?.methodology === 'CCB' && <tr>
                                                        <td className="fw-td-bold">CCB Standard </td><td>{projectData?.ccbStandard}</td>
                                                    </tr>
                                                }
                                                {
                                                    projectData?.methodology === 'SDVI' && <tr>
                                                        <td className="fw-td-bold">Sustainable Development Goal Contributions </td><td>{projectData?.susdevgoalcontribution}</td>
                                                    </tr>
                                                } */}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={'4'}>
                        <div id="notes">
                            <Row>
                                <Col lg='6' sm='6' >
                                    <Card className="browser-table br-10 mb-18">
                                        <CardBody className="br-10">
                                            <H4>Notes</H4>
                                            <div>
                                                <textarea className="form-control" rows="3" cols="7" placeholder="Enter your Description"
                                                    onChange={(e) => handleNotes(e.target.value)} value={description}></textarea>
                                                {notesError === true && <span className="text-danger">Please enter Notes..</span>}
                                            </div>
                                            <div className="mtop-1">
                                                <Button color="primary" onClick={handleSendNotes}>Add Notes</Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg='6'>
                                    <Card className="browser-table br-10 mb-18 desc-card-height">
                                        <CardBody className="br-10">
                                            <H4>Description</H4>
                                            <textarea className="form-control" rows="5" cols="7" placeholder="Enter your Description" disabled={true}
                                                value={projectData?.description}></textarea>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" sm="12">
                                    <Card className="br-10 mb-18">
                                        <CardBody>
                                            <Table striped bordered>
                                                <thead className='f-16'>
                                                    <tr className="notes-table-header">
                                                        <th style={{ "width": "30%", paddingLeft: '20px' }} className="pb-14">Notes</th>
                                                        <th className="p-3">Sent By</th>
                                                        <th className="p-3" >Sent On</th>
                                                        <th className="p-3">Email ID</th>
                                                        <th className="p-3" >Persona Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    {
                                                        notes && notes.length > 0 && notes.map((item) => {
                                                            return (
                                                                <tr scope="row" className="notes-table-body">
                                                                    <td className="p-3">{item.description}</td>
                                                                    <td className="p-3">{item.name}</td>
                                                                    <td className="p-3">{moment(item.createdOn).format('DD MMM, YYYY hh:mm A')}</td>
                                                                    <td className="p-3">{item.email}</td>
                                                                    <td className="p-3">{defaultUserTypes[item.personaType]}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                            <div className="d-flex-center mt-2">
                                                {
                                                    notes && notes?.length == 0 && <span>No Notes Found..</span>
                                                }
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={'5'}>
                        <div id="docs">
                            <Row>
                                <Col lg="12" sm="12">
                                    <Card className="br-10 mb-18">
                                        <CardBody>
                                            <div className='d-flex mb-2 mt-2'>
                                                <h2>Documents</h2>
                                                <div className="file-upload">
                                                    <input
                                                        name="document"
                                                        onChange={onFileChange}
                                                        placeholder=""
                                                        accept=".png, .jpeg, .jpg"
                                                        type="file"
                                                    />
                                                </div>
                                            </div>
                                            <Table striped bordered>
                                                <thead className='f-16'>
                                                    <tr className="notes-table-header">
                                                        <th className="p-3">Document Name</th>
                                                        <th className="p-3" >Type</th>
                                                        <th className="p-3">Size</th>
                                                        <th className="p-3" >Download</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    {
                                                        documents && documents.length > 0 && documents.map((item) => {
                                                            return (
                                                                <tr scope="row" className="notes-table-body">
                                                                    <td className="p-3">{item.originalFileName}</td>
                                                                    <td className="p-3">{item.documentTypeName === 'Others' ? item.otherDocumentTypeName : item.documentTypeName}</td>
                                                                    <td className="p-3">{(item.size / 1024).toFixed()}KB</td>
                                                                    <td className="p-3"><a href={item.path} target='_blank'><Download className='document-icon' /></a></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                            <div className="d-flex-center mt-2">
                                                {
                                                    documents && documents?.length == 0 && <center>No Records Found..</center>
                                                }
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey={'6'}>
                        {
                            projectData?.subCategoryItemName === 'EV Charging Stations' ? <EvChargeCalculation /> :
                                projectData?.subCategoryItemName === 'Solar Captive' ? <SolarCalculation /> :
                                    projectData?.subCategoryItemName === 'Plastic collection and recycling' ? <PlasticCalculation /> :
                                        <div className="coming-soon-text">
                                            <div className="w-55 dashboard-content">
                                                <i class="fa-solid fa-bell f-s-60 fa-shake bell-icon" ></i>
                                                <H3 attrH3={{ className: 'credits-table text-center' }}>Stay tuned for an upcoming 
                                                announcement about our baseline configuration!!</H3>
                                            </div>
                                        </div>
                        }
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
            <Container fluid={true} className="ecommerce-page">
                <Row>
                    {
                        allowedTypes.includes(type) && <Row>
                            <Col lg='12' sm='12' >
                                <Card className="browser-table br-10 mb-18">
                                    <CardBody className="br-10">
                                        <FormGroup className="mb-3">
                                            <label>Split Value <span className='required'>*</span></label>
                                            <input disabled={projectData?.status !== 'SUBMITTED'} className={`form-control mb-1`}
                                                value={splitValue} placeholder='Split Value' type="text"
                                                name='splitValue' onChange={(e) => setSplitValue(e.target.value)} />
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                    <div className='d-flex mb-5'>
                        <Link to={`${process.env.PUBLIC_URL}/${type}/projects`}>
                            <Btn attrBtn={{ color: 'secondary' }} >
                                Back
                            </Btn>
                        </Link>
                        {
                            monitoringPermission() && <div>
                                <Button color="primary" className="ml-1" onClick={handleMonitor}>Monitoring</Button>
                            </div>
                        }
                        {
                            projectData?.status === 'SUBMITTED' && allowedTypes.includes(type) && <div>
                                {
                                    loading ? <ButtonLoader loaderText={"Accepting"} color='primary' className='ml-1' />
                                        : <Btn attrBtn={{ color: 'primary', className: 'ml-1', onClick: handleAccept }}>
                                            Accept Project
                                        </Btn>
                                }
                                <Btn attrBtn={{ color: 'light', className: 'ml-1', onClick: () => setOpenDecline(true) }}>
                                    Decline
                                </Btn>
                            </div>
                        }
                        {
                            (projectData?.projectOrigin === 'External' && projectData?.status === 'VALIDATED' || projectData?.status === 'APPROVED_BY_REGISTRY' && allowedTypes.includes(type)) && <div>
                                {
                                    loading ? <ButtonLoader loaderText={"Activating"} color='primary' className='ml-1' />
                                        : <Btn attrBtn={{ color: 'primary', className: 'ml-1', onClick: handleActiveProject }}>
                                            Activate
                                        </Btn>
                                }
                            </div>
                        }
                        {
                            openDecline && <DeclineModal value={openDecline} setOpenModal={setOpenDecline}
                                projectId={projectData?.id} type={type} />
                        }
                        {
                            projectData?.status === 'ACTIVE' && allowedTypes.includes(type) && <div>
                                <Btn attrBtn={{ color: 'primary', className: 'ml-1', onClick: handleIssued }}>
                                    Issue Credits
                                </Btn>
                                <Btn attrBtn={{ color: 'primary', className: 'ml-1', onClick: handleCertify }}>
                                    Certify Credits
                                </Btn>
                            </div>
                        }

                        {openTransition && <ProjectTransitionModal value={openTransition} setOpenModal={setOpenTransition} projectId={projectId} />}
                    </div>
                </Row>
            </Container>
            {/* <Taptop /> */}
        </Fragment>
    )
}
export default ViewPage;