import React, { Fragment, useState, useEffect } from "react";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import { CardBody, Row, Container, Col, Card, CardHeader, Table } from "reactstrap";
import LayoutLoader from "../../Component/LayoutLoader/LayoutLoader";
import { Link } from "react-router-dom";
import { Btn } from '../../../AbstractElements';
import { defaultUserType } from "../../api/helper";
import MapComponent from "../MarkerMap/MapComponent";

const ViewLocation = () => {
    const [data, setData] = useState();
    const [markPosition, setMarkPosition] = useState({
        lat: 37.7749, // Set your initial latitude
        lng: -122.4194, // Set your initial longitude
    })

    const mapStyles = {
        width: '100%',
        height: '400px'
    }
    useEffect(() => {
        const url = window.location.pathname.split('/');
        loadData(url[3])
    }, []);

    const loadData = async (id) => {
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/location/${id}`, options)
            .then((res) => {
                console.log(res)
                setData(res.json)
                let geolocationData = res.json?.geoCoordinates.split(',');
                const geoData = { lat: Number(geolocationData[0]), lng: Number(geolocationData[1]) }
                console.log(geoData)
                setMarkPosition(geoData)
            })
            .catch((error) => {
                console.debug(error)
            })
    }

    const userType = sessionStorage.getItem('userType')

    return (
        <Fragment>
            <LayoutLoader />
            <Row>
                <Col lg='12'>
                    <div className="d-flex mtop-1">
                        <Breadcrumbs parent="Location" title="View" mainTitle='Location Details' />
                        <Link to={`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/location-list`} className="mr-13">
                            <Btn attrBtn={{ color: 'secondary' }} >
                                Back
                            </Btn>
                        </Link>
                    </div>
                </Col>
            </Row>
            <Container fluid={true} className="ecommerce-page">
                <Row>
                    <Col lg={12}>
                        <Card className="browser-table">
                            <CardBody>
                                <div className="table-responsive mb-0 location">
                                    <Table className="table" bordered striped>
                                        <thead>
                                            <tr className="">
                                                <th scope="col">Group Name</th>
                                                <th scope="col">Location Type</th>
                                                <th scope="col">Address</th>
                                                <th scope="col">Area</th>
                                                <th scope="col">City</th>
                                                <th scope="col">State</th>
                                                <th scope="col">Country</th>
                                                <th scope="col">Map Link</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="location-body">
                                                <td>{data?.groupName}</td>
                                                <td>{data?.locationType}</td>
                                                <td>{`${data?.addressLine1}, ${data?.addressLine2}`}</td>
                                                <td>{data?.area}</td>
                                                <td>{`${data?.city} - ${data?.zipCode}`}</td>
                                                <td>{data?.stateName}</td>
                                                <td>{data?.countryName}</td>
                                                <td><a href={data?.geoLocation} className="location-link" target="_blank"><i class="fa-regular fa-globe"></i></a></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <MapComponent position={markPosition} setMarker={setMarkPosition} styles={mapStyles} />

            </Container>
        </Fragment>
    )
}

export default ViewLocation;