import React, { Fragment, useState, useEffect, forwardRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Form, FormGroup, Col, Row, Card, CardBody, Button, Table } from 'reactstrap'
import { StartDate, EndDate, SaveasDraft, CarbonstdMethodology, ClimateCommunity, SustainableDevelopment } from '../../Constant'
import { countries } from '../../Data/CountryList/Defaultdata';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { handleProjectData, handleProjectDates, handleDate, defaultUserType, defaultMonitoringURL, CustomOptionComponent } from '../../api/helper';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import Select from 'react-select'

const DetailsPage = ({ setSteps, preData }) => {
    const [formdata, setFormdata] = useState(preData)
    const [startDate, setStartDate] = useState(preData?.start_date ? new Date(preData?.start_date) : '');
    const [region, setRegion] = useState();
    const [endDate, setendDate] = useState(preData?.end_date ? new Date(preData?.end_date) : '');
    const [assessment, setAssessment] = useState();
    const [periodicType, setPeriodicType] = useState();
    const [frequency, setFrequency] = useState();
    const [dataFrequency, setDataFrequency] = useState();
    const [volume, setVolume] = useState();
    const [countryList, setCountryList] = useState();
    const { register, handleSubmit, clearErrors, formState: { errors }, control, setValue, getValues, reset } = useForm({ defaultValues: preData });
    const [registry, setRegistry] = useState(preData?.registryName);
    const [registryId, setRegistryId] = useState(preData?.registryId);
    const [categoryTypes, setCategoryType] = useState([]);
    const [unitTypeList, setUnitTypeList] = useState([]);
    const [registryTypes, setRegistryTypes] = useState();
    const [projectCategoryId, setProjectCategoryId] = useState(preData?.projectCategoryId);
    const [unitType, setUnitType] = useState(formdata?.unitType)
    const [subCategory, setSubcategory] = useState([]);
    const [subCategoryId, setSubcategoryId] = useState(preData?.projectSubCategoryId);
    const [subCategoryItemList, setSubcategoryItemList] = useState([]);
    const [subCategoryItemId, setSubcategoryItemId] = useState(preData?.subCategoryItemId);
    const [methodologyData, setMethodologyData] = useState([]);
    const [methodology, setMethodology] = useState(preData?.methodology);
    const [jnrStatus, setJnrStatus] = useState(preData?.isassociatedwithjnr);
    const [asoList, setAsoList] = useState([]);
    const [monitoringLevel, setMonitoringLevel] = useState(preData?.monitoringLevel);
    const [subCategoryItemName, setSubcategoryItemName] = useState(preData?.subCategoryItemName);
    const [carboncalcDay, setCarbonCalcDay] = useState(preData?.carbonCreditsCalculationDay);
    const [city, setCity] = useState(preData?.city);
    const [zipCode, setZipCode] = useState(preData?.zip_code);
    const days = Array.from({ length: 30 }, (_, i) => i + 1);
    const [sdgGoals, setGoals] = useState([]);
    const [sdgList, setSdgList] = useState([]);
    const [country, setCountry] = useState(preData?.country)

    const allowedTypes = ['admin', 'sys-user'];
    const type = window.location.pathname.split('/')[1]

    const getAllCountry = async () => {
        await fetchWrapper(`${API_URL}/country`)
            .then((res) => {
                setCountryList(res?.json);
            }, (err) => console.log(err))
    }

    const userType = sessionStorage.getItem('userType')
    const navigate = useNavigate();

    const getProjectCategory = async () => {
        await fetchWrapper(`${API_URL}/projectcategory`)
            .then((res) => {
                setCategoryType(res.json);
            })
            .catch(err => console.log(err))
    }

    const defaultSize = {
        '<20,000': 'LESSTHAN20K',
        '20000-100,000': 'BETWEEN20K',
        '100,001–1,000,000': 'BETWEEN1M',
        '>1,000,000': 'GREATERTHAN1M'
    }

    const defaultAssessmentType = {
        "One time": "ONETIME",
        "Periodic": "PERIODIC"
    }

    const fetchData = useCallback(async () => {
        if (registryId && subCategoryId) {
            await getData(registryId);
        }
        if (registry) {
            await getUnitTypes(registry);
            await getMethodologies(registry);
        }
    }, [registryId, registry]);

    const getMethodologies = async (registry) => {
        await fetchWrapper(`${API_URL}/methodology/${registry}`)
            .then((res) => {
                setMethodologyData(res.json);
            })
            .catch(error => console.error(error))
    }

    const handleJNR = (val) => {
        setJnrStatus(val);
        setValue('isassociatedwithjnr', val);
    }

    const defaultFrequency = {
        "1": "MONTHLY",
        "2": "QUARTERLY",
        "3": "HALFYEARLY",
        "4": "YEARLY"
    }

    useEffect(() => {
        getProjectCategory()
        getAllCountry()
        getSubcategory(preData?.projectCategoryId);
        loadSdgList();
        reset(preData)
    }, [preData, reset])


    useEffect(() => {
        fetchData();
    }, [fetchData])

    const getData = async (value) => {
        setRegistryId(value);
        const selRegistry = registryTypes?.find(i => i.registryId === value);
        setValue('registry', selRegistry?.registry);
        setRegistry(selRegistry?.registry)
        await getUnitTypes(selRegistry?.registry)
        await getSubCategoryItems(value);
    }

    const getUnitTypes = async (value) => {
        await fetchWrapper(`${API_URL}/unittypes/${value}`)
            .then((res) => {
                setUnitTypeList(res.json)
            }, (err) => console.log(err))
    }

    const getRegistryTypes = async (id) => {
        await fetchWrapper(`${API_URL}/registry/projectcategory/${id}`)
            .then((res) => {
                setRegistryTypes(res.json)
            }, (err) => console.log(err))
    }

    const getSubcategory = async (val) => {
        setProjectCategoryId(val)
        setValue('projectCategoryId', val);
        getRegistryTypes(val)
        await fetchWrapper(`${API_URL}/subcategory/category/${val}`)
            .then((res) => {
                setSubcategory(res.json);
            })
            .catch(error => console.error(error))
    }

    const getSubCategoryItems = async (val) => {
        await fetchWrapper(`${API_URL}/subcategoryitems/subcategory/${subCategoryId}/registry/${val}`)
            .then((res) => {
                setSubcategoryItemList(res.json);
            })
            .catch(error => { })
    }

    const handleSubcategoryItem = (val) => {
        setSubcategoryId(val);
        const getItem = subCategoryItemList.filter((item) => item.id === val);
        setSubcategoryItemName(getItem?.itemName);
    }

    const handleSubcategory = (val) => {
        setSubcategoryId(val)
        setRegistryId();
        setValue('registryId', '')
        setSubcategoryItemId();
        setValue('subCategoryItemId', '')
        setSubcategoryItemId();
        setValue('unitType', '');
        setUnitType();
    }

    const loadPreData = () => {
        preData.average_annual_volume_of_vc_us = !preData?.average_annual_volume_of_vc_us ? Number(0) : preData?.average_annual_volume_of_vc_us;
        setValue('name', preData?.name)
        setValue('country', preData?.country)
        setValue('region', preData?.region)
        setValue('average_annual_volume_of_vc_us', Number(preData?.average_annual_volume_of_vc_us))
        setValue('methodology', preData?.methodology)
        setValue('ccbstandardname', preData?.ccbstandardname)
        setValue('ccbstandard', preData?.ccbstandard)
        setValue('assessmenttype', preData?.assessmenttype)
        setValue('periodicassessmenttype', preData?.periodicassessmenttype)
        setValue('size', preData?.size)
        setValue('susdevgoalcontribution', preData?.susdevgoalcontribution)
        setValue('isassociatedwithjnr', preData?.isassociatedwithjnr)
        setValue('volume', preData?.volume)
        setValue('frequency', preData?.frequency)
        setValue('sdvistaprojecttype', preData?.sdvistaprojecttype)
        setValue('unitType', preData?.unitType);
        setValue('registry', preData?.registryName)
        setValue('creditsIssuance', preData?.creditsIssuance)
        setValue('type', 'Credits');
        setValue('projectCategoryId', preData?.projectCategoryId);
        setValue('projectSubCategoryId', preData?.projectSubCategoryId);
        setValue('subCategoryItemId', preData?.subCategoryItemId);
        setValue('registryId', preData?.registryId);
        setValue('unitType', preData?.unitType);
        setValue('surface_area', preData?.surface_area);
        setValue('carbon_baseline', preData?.carbon_baseline);
        setValue('estimation_carbon_capacity', preData?.estimation_carbon_capacity);
        setValue('total_gross_sequestration_potential', preData?.total_gross_sequestration_potential);
        setValue('operational_impacts', preData?.operational_impacts);
        setValue('project_duration', preData?.project_duration);
        setValue('potential_per_ha_year', preData?.potential_per_ha_year);
        setValue('potential_whole_project_year', preData?.potential_whole_project_year);
        setValue('total_sequestration_potential', preData?.total_sequestration_potential);
        setValue('estimated_carbon_income', preData?.estimated_carbon_income);
        setValue('facility_field_name', preData?.facility_field_name);
        setValue('geo_location', preData?.geo_location);
        setValue('legal_ownership', preData?.legal_ownership);
        setValue('remove_store_carbon', preData?.remove_store_carbon);
        setValue('emissionFactor', preData?.emissionFactor);
        setValue('monitoringLevel', preData?.monitoringLevel);
        setValue('city', preData?.city)
        setValue('zip_code', preData?.zip_code)
        setValue('carbonCreditsCalculationDay', preData?.carbonCreditsCalculationDay)
        setPeriodicType(preData?.periodicassessmenttype)
        preData && preData.start_date && setValue('start_date', preData?.start_date)
        preData && preData.end_date && setValue('end_date', preData?.end_date)
        setAssessment(preData?.assessmenttype)
        setSubcategoryId(preData?.projectSubCategoryId)
        setUnitType(preData?.unitType)
        setRegistryId(preData?.registryId)
        setSubcategoryItemId(preData?.subCategoryItemId)
        setRegistry(preData?.registryName);
        setMethodology(preData?.methodology);
        setStartDate(preData?.start_date);
        setendDate(preData?.end_date);
        setMonitoringLevel(preData?.monitoringLevel);
        setCarbonCalcDay(preData?.carbonCreditsCalculationDay);
    }

    const loadAsoOrgList = async () => {
        await fetchWrapper(`${API_URL}/organization/assetmanager/mappedOrgs`)
            .then((res) => {
                const data = res.json;
                setAsoList(data?.mappedAssetOwners)
            }, (err) => { console.log(err) })
    }

    useEffect(() => {
        if (['ASM_ADMIN', 'ASM_USER'].includes(userType)) {
            loadAsoOrgList();
        }
    }, [])

    const defaultType = {
        ASO_USER: 'user',
        ASO_ADMIN: 'aso-admin',
        ASM_USER: 'asm-user',
        ASM_ADMIN: 'asm-admin',
        SYS_ADMIN: 'admin',
        ASR_USER: 'assessor',
        ASR_ADMIN: 'asr-admin'
    }
    const updateValue = ({ target }) => {
        const selectedItem = countryList.find((item) => item.name == target.value);
        setRegion(selectedItem.region);
        setValue('projectCountry', selectedItem?.name);
        setCountry(target.value)
        setValue('countryCode', selectedItem.countryCode);
        setValue('region', selectedItem.region)
        setValue('asset_owner_id', formdata?.asset_org_id)
        clearErrors("projectCountry");
    };

    const handleStartDate = date => {
        setStartDate(moment(date).format('YYYY-MM-DD'));
    };

    const handleEndDate = date => {
        setendDate(moment(date).format('YYYY-MM-DD'));
    };

    const deepEqual = (x, y) => {
        const ok = Object.keys, tx = typeof x, ty = typeof y;
        return x && y && tx === 'object' && tx === ty ? (
            ok(x).every(key => deepEqual(x[key], y[key]))
        ) : (x === y);
    }

    const onSubmit = async (data) => {
        if (deepEqual(data, preData)) {
            setSteps(pre => pre + 1)
        } else {
            try {
                const userType = sessionStorage.getItem('userType')
                const bodyData = {
                    ...data,
                    area: formdata?.area,
                    id: formdata?.id,
                    crediting_period_in_years: formdata?.crediting_period_in_years,
                    latitude: formdata?.latitude,
                    longitude: formdata?.longitude,
                    website: formdata?.website,
                    assessorOrgId: formdata?.assessorOrgId,
                    assessorUserId: formdata?.assessorUserId,
                    assessor_name: formdata?.assessor_name,
                    assessor_org_name: formdata?.assessor_org_name,
                    status: "SUBMITTED",
                }
                const options = {
                    method: 'PUT',
                    body: handleProjectDates(bodyData)
                }
                await fetchWrapper(`${API_URL}/projects/${formdata?.id}`, options)
                    .then((res) => {
                        if (res.status === 200) {
                            const data = res.json;
                            toast.success('Project Updated Successfully!')
                        }
                        setSteps(pre => pre + 1)
                    })
            } catch (error) {
                if (error.status === 412) {
                    toast.error(`Please check the Details..${JSON.stringify(error.json)}`)
                }
            }
        }
    }

    const handleDraft = async (data) => {
        if (deepEqual(data, preData)) {
            setSteps(pre => pre + 1)
        } else {
            try {
                const userType = sessionStorage.getItem('userType')
                const bodyData = {
                    ...data,
                    area: formdata?.area,
                    id: formdata?.id,
                    crediting_period_in_years: formdata?.crediting_period_in_years,
                    latitude: formdata?.latitude,
                    longitude: formdata?.longitude,
                    website: formdata?.website,
                    assessmenttype: formdata?.assessmenttype,
                    assessorOrgId: formdata?.assessorOrgId,
                    assessorUserId: formdata?.assessorUserId,
                    assessor_name: formdata?.assessor_name,
                    assessor_org_name: formdata?.assessor_org_name,
                    status: "DRAFT",
                }
                const options = {
                    method: 'PUT',
                    body: bodyData
                }
                await fetchWrapper(`${API_URL}/projects/${formdata?.id}`, options)
                    .then((res) => {
                        if (res.status === 200) {
                            const data = res.json;
                            toast.success('Project Updated Successfully!')
                        }
                        setSteps(pre => pre + 1)
                    })
            } catch (error) {
                if (error.status === 412) {
                    toast.error(JSON.stringify(error.json))
                }
                // setSteps(pre => pre + 1)
            }
        }
    }

    useEffect(() => {
        setFormdata(pre => ({ ...pre, ...preData }))
        reset(formdata)
        if (preData) {
            getData(preData?.registryName);
            loadPreData()
        }
    }, [preData])

    useEffect(() => {
        reset(preData)
    }, [reset])

    const handleEvManage = () => {
        const url = monitoringLevel === 'CHARGER' ?
            `${process.env.PUBLIC_URL}/${defaultUserType[userType]}/ev-charger-lists/${preData?.id}/${preData?.name}` :
            `${process.env.PUBLIC_URL}/${defaultUserType[userType]}/ev-station-lists/${preData?.id}/${preData?.name}`;
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/${defaultMonitoringURL[monitoringLevel]}/${preData?.id}/
        ${preData?.name}`)
    }

    const CustomInput = forwardRef((props, ref) => {
        return (
            <div className={`${['ASR_ADMIN', 'ASR_USER'].includes(userType) ? 'disable-field form-control' : 'form-control'}`} onClick={props.onClick}>
                <label ref={ref} >
                    {props.value || props.placeholder}
                </label>
                <i className="fa fa-calendar input-calendar-ico" style={{ marginTop: '0.1rem' }}></i>
            </div>
        );
    });

    const handleCancel = () => {
        let userType = sessionStorage.getItem('userType')
        navigate(`${process.env.PUBLIC_URL}/${defaultType[userType]}/projects`)
    }

    const handleAssessment = (value) => {
        setAssessment(value);
        setValue('assessmenttype', value)
    }

    const loadSdgList = async () => {
        await fetchWrapper(`${API_URL}/projectsustainablegoals`)
            .then((res) => {
                const data = res.json?.map((ele) => {
                    return {
                        value: ele.id,
                        label: ele.name
                    }
                });
                setGoals(data)
            }, (err) => console.log(err))
    }

    const handleSdgList = (data) => {
        if (data?.length < 4) {
            setSdgList(data)
            let temList = data?.map(i => i.value);
            setValue('sustainableDevelopmentGoals', temList?.toString())
        }
    }

    return (
        <Fragment>
            <Row>
                <Col lg={12}>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>Project Name <span className='required'>*</span></label>
                                            <input className={`form-control mb-1`} placeholder='' type="text"
                                                disabled={formdata?.assessorOrgId != null ? true : false}
                                                name='projectName' defaultValue={formdata?.name || ""}
                                                {...register('name', { required: true })} />
                                            <span className='text-danger'>{errors.name && errors.name.type === 'required' && 'Project Name is required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>Asset Owner Organization</label>
                                            {
                                                (['ASO_ADMIN', 'ASO_USER', 'SYS_ADMIN', 'ASR_ADMIN', 'ASR_USER'].includes(userType)) && <input className={`form-control ${errors.assetCreator && 'is-invalid'} mb-1`}
                                                    value={formdata?.selectedAsoOrgId ? formdata?.selectedAsoOrgName : formdata?.asset_org_name} type="text" name='assetCreator'
                                                    disabled />
                                            }
                                            {
                                                (['ASM_ADMIN', 'ASM_USER'].includes(userType)) && <select className="form-select" {...register('selectedAsoOrgId', { required: true })}
                                                    disabled={true} value={formdata?.selectedAsoOrgId}
                                                // onChange={(e) => updateValue(e)} 
                                                >
                                                    <option value=''>Select Asset Owner Organization</option>
                                                    {
                                                        asoList?.length > 0 && asoList?.map((item) => {
                                                            return (
                                                                <option value={item?.assetOwnerId}>{item?.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>{StartDate} </label>
                                            <Controller
                                                name='start_date'
                                                control={control}
                                                onChange={handleStartDate}
                                                value={handleDate(formdata?.start_date)}
                                                render={({ field: { onChange, value } }) => {
                                                    return (
                                                        <DatePicker
                                                            className={`"form-control"`}
                                                            selected={value}
                                                            onChange={e => {
                                                                handleStartDate(e);
                                                                onChange(e);
                                                            }}
                                                            showMonthDropdown
                                                            dateFormatCalendar="MMMM"
                                                            showYearDropdown
                                                            yearDropdownItemNumber={15}
                                                            dropdownMode="select"
                                                            dateFormat="dd MMM, yyyy"
                                                            customInput={<CustomInput />}
                                                            disabled={['ASR_ADMIN', 'ASR_USER'].includes(userType)}
                                                        // minDate={new Date(moment(new Date()).add(1, 'day'))}
                                                        />
                                                    )
                                                }}
                                            />
                                            <span className='text-danger'>{errors.start_date && errors.start_date.type === 'required' && 'Start Date is required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>{EndDate} </label>
                                            <Controller
                                                name='end_date'
                                                control={control}
                                                onChange={handleEndDate}
                                                value={handleDate(formdata?.end_date)}
                                                render={({ field: { onChange, value } }) => {
                                                    return (
                                                        <DatePicker
                                                            className="datepicker-here form-control"
                                                            selected={value}
                                                            onChange={e => {
                                                                handleEndDate(e);
                                                                onChange(e)
                                                            }}
                                                            showMonthDropdown
                                                            dateFormatCalendar="MMMM"
                                                            showYearDropdown
                                                            yearDropdownItemNumber={15}
                                                            dropdownMode="select"
                                                            dateFormat="dd MMM, yyyy"
                                                            customInput={<CustomInput />}
                                                            minkDate={new Date(moment(startDate != "Invalid Date" ? startDate : new Date()).add(1, 'day'))}
                                                            disabled={['ASR_ADMIN', 'ASR_USER'].includes(userType)}
                                                        />
                                                    )
                                                }}
                                            />
                                            <span className='text-danger'>{errors.end_date && errors.end_date.type === 'required' && 'End Date is required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>City <span className="text-danger">*</span></label>
                                            <input className={`form-control mb-1`} placeholder='' type="text" name='city'
                                                defaultValue={preData?.city}
                                                {...register('city', { required: true })} />
                                            <span className='text-danger'>{errors.city && errors.city.type === 'required'
                                                && 'City is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>Zipcode <span className="text-danger">*</span></label>
                                            <input className={`form-control mb-1`} placeholder='' type="text" name='zipCode'
                                                defaultValue={preData?.zip_code}
                                                {...register('zip_code', { required: true, pattern: /[+-]?([0-9]*[.])?[0-9]+$/ })} />
                                            <span className='text-danger'>{errors.zip_code && errors.zip_code.type === 'required'
                                                && 'Zipcode is Required'}</span>
                                            <span className='text-danger'>{errors.zip_code && errors.zip_code.type === 'pattern'
                                                && 'Zipcode Value must be decimal'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>Country <span className='required'>*</span></label>
                                            <select className="form-select" defaultValue={formdata?.country || ""} {...register('country', { required: "Country is required" })}
                                                onChange={(e) => updateValue(e)} >
                                                <option value=''>-Select Country-</option>
                                                {countryList?.map((item, i) =>
                                                    <option value={item.name} data-index={i}>{item.name}</option>
                                                )}
                                            </select>
                                            <span className='text-danger'>{errors.country && errors.country.type === 'required' && 'Country is required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>Region</label>
                                            <input className={`form-control mb-1`} type="text"
                                                defaultValue={formdata?.region || ""} {...register('region')} disabled />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>Assesment Type</label>
                                            <select className='form-select' defaultValue={formdata?.assessmenttype}
                                                {...register('assessmenttype', { required: true })}
                                                onChange={(e) => handleAssessment(e.target.value)}
                                            >
                                                <option value=''>Select Assessment</option>
                                                <option value='ONETIME'>One Time Assessment</option>
                                                <option value='PERIODIC'>Periodic Assessment</option>
                                            </select>
                                            <span className="text-danger">{errors.assessmenttype && errors.assessmenttype.type === 'required'
                                                && 'Select Assesment Type'}</span>
                                        </FormGroup>
                                    </Col>
                                    {
                                        ((formdata?.registryName !== 'ONCRA') && (formdata?.subCategoryItemName !== 'EV Charging Stations')) && <Col sm='6'>
                                            <div className="row mb-3">
                                                <label for="inputEmail3" >Select Methodology </label>
                                                <FormGroup className="mb-3">
                                                    <select className="form-select" value={methodology}
                                                        {...register('methodology')}
                                                        onChange={(e) =>
                                                            setMethodology(e.target.value)
                                                        }>
                                                        <option value=''>Select Methodology</option>
                                                        {
                                                            methodologyData?.length > 0 && methodologyData?.map((item) => {
                                                                return <option value={item.name}>{item.name}</option>
                                                            })
                                                        }
                                                    </select>
                                                    <span className='text-danger'>{errors.methodology && errors.methodology.type === 'required' && 'Methodology is required'}</span>
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    }

                                </Row>
                                {
                                    assessment == 'PERIODIC' && <div>
                                        <Row>
                                            <Col lg='6'>
                                                <FormGroup>
                                                    <label>Periodic Assesment Type <span className="text-danger">*</span></label>
                                                    <select className="form-select"
                                                        {...register('periodicassessmenttype', { required: true })}
                                                        onChange={(e) => setPeriodicType(e.target.value)}
                                                    >
                                                        <option value={''}>-Select Type-</option>
                                                        <option value={'FREQUENCY'}>Frequency</option>
                                                        <option value={'VOLUME'}>Volume</option>
                                                    </select>
                                                    <span className="text-danger">{errors.periodicassessmenttype && errors.periodicassessmenttype.type === 'required'
                                                        && 'Select Periodic Assesment Type'}</span>
                                                </FormGroup>
                                            </Col>
                                            {
                                                periodicType == 'FREQUENCY' &&
                                                <Col md='3'>
                                                    <FormGroup>
                                                        <label>Frequency <span className="text-danger">*</span></label>
                                                        <select className="form-select" name="frequency"
                                                            defaultValue={formdata?.frequency}
                                                            {...register('frequency', { required: true })}
                                                            onChange={(e) => setFrequency(e.target.value)}>
                                                            <option value={''}>-Select Frequency-</option>
                                                            <option value={'MONTHLY'}>Monthly</option>
                                                            <option value={'QUARTERLY'}>Quarterly</option>
                                                            <option value={'HALFYEARLY'}>Half yearly</option>
                                                            <option value={'YEARLY'}>Yearly</option>
                                                        </select>
                                                        <span className="text-danger">{errors.frequency && errors.frequency.type === 'required'
                                                            && 'Select Frequency'}</span>
                                                    </FormGroup>
                                                </Col>
                                            }
                                            {
                                                periodicType == 'FREQUENCY' && <Col md='3'>
                                                    <FormGroup>
                                                        <label>Data Collection Frequency <span className="text-danger">*</span></label>
                                                        <select className="form-select" name="dataCollectionFrequency" defaultValue={formdata?.dataCollectionFrequency}
                                                            {...register('dataCollectionFrequency', { required: true })}
                                                            onChange={(e) => setDataFrequency(e.target.value)}>
                                                            <option value={''}>-Select Data Collection Frequency-</option>
                                                            <option value={'MONTHLY'}>Monthly</option>
                                                            <option value={'QUARTERLY'}>Quarterly</option>
                                                            <option value={'HALFYEARLY'}>Half yearly</option>
                                                            <option value={'YEARLY'}>Yearly</option>
                                                        </select>
                                                        <span className="text-danger">{errors.dataCollectionFrequency && errors.dataCollectionFrequency.type === 'required'
                                                            && 'Select Frequency'}</span>
                                                    </FormGroup>
                                                </Col>
                                            }
                                            {
                                                periodicType == 'VOLUME' && <Col md='6' >
                                                    <FormGroup>
                                                        <label>Volume(In Metric tons) <span className="text-danger">*</span></label>
                                                        <input className={`form-control mb-1`} placeholder='' type="text"
                                                            name='Volume' {...register('volume', { required: true, pattern: /^[0-9\b]+$/ })} onChange={(e) => setVolume(parseInt(e.target.value))} />
                                                        <span className="text-danger">{errors.volume && errors.volume.type === 'required'
                                                            && 'Volume Required'}</span>
                                                        <span className="text-danger">{errors.volume && errors.volume.type === 'pattern'
                                                            && 'Volume should be numeric'}</span>
                                                    </FormGroup>
                                                </Col>
                                            }
                                        </Row>
                                    </div>
                                }
                                {
                                    ((formdata?.registryName !== 'ONCRA') && (formdata?.subCategoryItemName !== 'EV Charging Stations')) && <Row>
                                        <Col sm='6'>
                                            <FormGroup className="mb-3">
                                                <label>Project Size (tCO<sub>2</sub>e/year) </label>
                                                <select className="form-select" defaultValue={formdata?.size || ""}
                                                    {...register('size')} >
                                                    <option value=''>-Select Project size-</option>
                                                    <option value="LESSTHAN20K"> &#60; 20,000 </option>
                                                    <option value="BETWEEN20K">20,000 – 100,000 </option>
                                                    <option value="BETWEEN1M">100,001 – 1,000,000 </option>
                                                    <option value="GREATERTHAN1M"> &#62; 1,000,000 </option>
                                                </select>
                                                <span className='text-danger'>{errors.size && errors.size.type === 'required' && 'Project Size is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col sm='6'>
                                            <FormGroup className="mb-3">
                                                <label>Average Annual Carbon Units </label>
                                                <input className={`form-control ${errors.avgannualvcu && 'is-invalid'} mb-1`} placeholder=''
                                                    type="text" name='avgannualvcu' defaultValue={formdata?.average_annual_volume_of_vc_us || ""}
                                                    {...register('average_annual_volume_of_vc_us', { pattern: /^[0-9\b]+$/ })} />
                                                <span className='text-danger'>{errors.average_annual_volume_of_vc_us && errors.average_annual_volume_of_vc_us.type === 'required' && 'Average Annual VCU is required'}</span>
                                                <span className='text-danger'>{errors.average_annual_volume_of_vc_us && errors.average_annual_volume_of_vc_us.type === 'pattern' && 'Average Annual VCU must be decimal'}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                }

                                <Row>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>Credits Issuance Method</label>
                                            <select className='form-select' {...register('creditsIssuance')} defaultValue={formdata?.creditsIssuance}>
                                                <option value=''>Select Credits Issuance Method</option>
                                                <option value={'Manual'}>Manual</option>
                                                <option value={'Automatic'}>Automatic</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>Project Category <span className="text-danger">*</span></label>
                                            <select className='form-select' value={projectCategoryId}
                                                {...register('projectCategoryId', { required: true })} onChange={(e) => getSubcategory(e.target.value)}
                                            >
                                                <option value=''>Select Project Category</option>
                                                {
                                                    categoryTypes?.length > 0 && categoryTypes?.map((i) => {
                                                        return (
                                                            <option value={i.id}>{i.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className='text-danger'>{errors.projectCategoryId && errors.projectCategoryId.type === 'required' && 'Project Category is required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>


                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>Project Subcategory <span className="text-danger">*</span></label>
                                            <select className='form-select' value={subCategoryId}
                                                {...register('projectSubCategoryId', { required: true })}
                                                onChange={(e) => setSubcategoryId(e.target.value)}
                                            >
                                                <option value=''>Select Project Subcategory</option>
                                                {
                                                    subCategory?.length > 0 && subCategory?.map((i) => {
                                                        return (
                                                            <option value={i.id}>{i.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className='text-danger'>{errors.projectSubCategoryId && errors.projectSubCategoryId.type === 'required' && 'Project Subcategory is required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>Registry Type <span className="text-danger">*</span></label>
                                            <select className='form-select' value={registryId}
                                                {...register('registryId', { required: true })}
                                                onChange={(e) => getData(e.target.value)}
                                            >
                                                <option value=''>Select Registry Type</option>
                                                {
                                                    registryTypes && registryTypes.length > 0 && registryTypes.map((item) => {
                                                        return (
                                                            <option value={item.registryId}>{item.registry}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>Project Subcategory Items <span className="text-danger">*</span></label>
                                            <select className='form-select' value={subCategoryItemId}
                                                {...register('subCategoryItemId', { required: true })}
                                                onChange={(e) => handleSubcategoryItem(e.target.value)}
                                            >
                                                <option value=''>Select Project Subcategory Items</option>
                                                {
                                                    subCategoryItemList?.length > 0 && subCategoryItemList?.map((i) => {
                                                        return (
                                                            <option value={i.id}>{i.itemName}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className='text-danger'>{errors.subCategoryItemId && errors.subCategoryItemId.type === 'required' && 'Project Subcategory Item is required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>Registry Unit Type <span className="text-danger">*</span></label>
                                            <select className='form-select' {...register('unitType', { required: true })} value={unitType}
                                                onChange={(e) => setUnitType(e.target.value)} >
                                                <option value=''>Select Registry Unit Type</option>
                                                {
                                                    unitTypeList && unitTypeList.length > 0 && unitTypeList.map((item) => {
                                                        return (
                                                            <option value={item.type}>{item.type}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="text-danger">{errors.unitType && 'Unit Type is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>Carbon Credits Calculation Day <span className="text-danger">*</span></label>
                                            <select className='form-select' value={carboncalcDay}
                                                {...register('carbonCreditsCalculationDay', { required: true })}
                                                onChange={(e) => setCarbonCalcDay(e.target.value)}
                                            >
                                                <option value="">Select Calculation Day</option>
                                                {
                                                    days?.map((i) => <option value={i}>{i}</option>)
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    {
                                        ((formdata?.projectSubCategoryName === "Plastic Credit") || (formdata?.subCategoryItemName === 'Building Management Systems')) &&
                                        <Col sm='6'>
                                            <FormGroup className="mb-3">
                                                <label>Baseline (MtCO2e) <span className="text-danger">*</span></label>
                                                <input className={`form-control mb-1`} placeholder='' type="text" name='baseline' defaultValue={formdata?.baseline}
                                                    {...register('baseline', { required: true, pattern: /[+-]?([0-9]*[.])?[0-9]+$/ })} />
                                                <span className='text-danger'>{errors.baseline && errors.baseline.type === 'required'
                                                    && 'Baseline is Required'}</span>
                                                <span className='text-danger'>{errors.baseline && errors.baseline.type === 'pattern'
                                                    && 'Baseline Value must be decimal'}</span>
                                            </FormGroup>
                                        </Col>

                                    }
                                    {
                                        formdata?.subCategoryItemName === 'EV Charging Stations' && <Col sm='6'>
                                            <FormGroup className='mb-3'>
                                                <label>Monitoring Level <span className="text-danger">*</span></label>
                                                <select className='form-select' name="monitoringLevel" value={monitoringLevel}
                                                    {...register('monitoringLevel', { required: true })}
                                                    onChange={(e) => setMonitoringLevel(e.target.value)}
                                                >
                                                    <option value=''>-Select Monitoring Level-</option>
                                                    <option value="STATION">Station</option>
                                                    <option value="CHARGER">Charger</option>
                                                </select>
                                                <span className="text-danger">{errors.monitoringLevel && 'Monitoring Level is Required'}</span>
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>


                                {
                                    formdata?.registryName === 'ONCRA' && <div>
                                        <Row>
                                            <Col sm='6'>
                                                <FormGroup className="mb-3">
                                                    <label>Surface Area(ha) <span className="text-danger">*</span></label>
                                                    <input className={`form-control mb-1`} placeholder='ex: 23' type="text"
                                                        name='surface_area' defaultValue={formdata?.surface_area}
                                                        {...register('surface_area', { required: true })} />
                                                    <span className='text-danger'>{errors.surface_area && errors.surface_area.type === 'required' && 'Surface Area is required'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col sm='6'>
                                                <FormGroup className="mb-3">
                                                    <label>Carbon Baseline <span className="text-danger">*</span></label>
                                                    <input className={`form-control mb-1`} placeholder='ex: 23' type="text"
                                                        name='carbonBaseline' defaultValue={formdata?.carbon_baseline}
                                                        {...register('carbon_baseline', { required: true, pattern: /^[0-9\b]+$/ })} />
                                                    <span className='placeholder-text-color'>In % Soil Organic Carbon (this is about 58% of Soil Organic Matter). If applicable.</span>
                                                    <span className='text-danger'>{errors.carbon_baseline && errors.carbon_baseline.type === 'required' && 'Carbon Baseline is required'}</span>
                                                    <span className='text-danger'>{errors.carbon_baseline && errors.carbon_baseline.type === 'pattern' && 'Carbon Baseline must be decimal'}</span>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col sm='6'>
                                                <FormGroup className="mb-3">
                                                    <label>Estimation of Carbon Capacity <span className="text-danger">*</span></label>
                                                    <input className={`form-control mb-1`} placeholder='ex: 23' type="text"
                                                        name='carbonCapacity' defaultValue={formdata?.estimation_carbon_capacity}
                                                        {...register('estimation_carbon_capacity', { required: true, pattern: /^[0-9\b]+$/ })} />
                                                    <span className="placeholder-text-color">In % Soil Organic Carbon</span>
                                                    <span className='text-danger'>{errors.estimation_carbon_capacity && errors.estimation_carbon_capacity.type === 'required' && 'Carbon Capacity is required'}</span>
                                                    <span className='text-danger'>{errors.estimation_carbon_capacity && errors.estimation_carbon_capacity.type === 'pattern' && 'Carbon Capacity must be decimal'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col sm='6'>
                                                <FormGroup className="mb-3">
                                                    <label>Total gross sequestration potential <span className="text-danger">*</span></label>
                                                    <input className={`form-control mb-1`} placeholder='ex: 23' type="text"
                                                        name='total_gross_sequestration_potential' defaultValue={formdata?.total_gross_sequestration_potential}
                                                        {...register('total_gross_sequestration_potential', { required: true, pattern: /^[0-9\b]+$/ })} />
                                                    <span className="placeholder-text-color">Capacity minus baseline x surface area, tCO2</span>
                                                    <span className='text-danger'>{errors.total_gross_sequestration_potential && errors.total_gross_sequestration_potential.type === 'required'
                                                        && 'Gross sequestration potential is required'}</span>
                                                    <span className='text-danger'>{errors.total_gross_sequestration_potential && errors.total_gross_sequestration_potential.type === 'pattern'
                                                        && 'Gross sequestration potential must be decimal'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm='6'>
                                                <FormGroup className="mb-3">
                                                    <label>Specification of full life cycle analysed operational impacts <span className="text-danger">*</span></label>
                                                    <input className={`form-control mb-1`} placeholder='' defaultValue={formdata?.operational_impacts} type="text" name='operational_impacts'
                                                        {...register('operational_impacts', { required: true, pattern: /[+-]?([0-9]*[.])?[0-9]+$/ })} />
                                                    <span className="placeholder-text-color">t CO2e in percentage of Potential whole project per year (above).
                                                        Default is 20% (type 0,2).</span>
                                                    <span className='text-danger'>{errors.operational_impacts && errors.operational_impacts.type === 'required'
                                                        && 'Operational Impacts field is required'}</span>
                                                    <span className='text-danger'>{errors.operational_impacts && errors.operational_impacts.type === 'pattern'
                                                        && 'Value must be decimal'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col sm='6'>
                                                <FormGroup className="mb-3">
                                                    <label>Project duration (Years) <span className="text-danger">*</span></label>
                                                    <input className={`form-control mb-1`} placeholder='' defaultValue={preData?.project_duration} type="text" name='project_duration'
                                                        {...register('project_duration', { required: true, pattern: /^[0-9\b]+$/ })} />
                                                    <span className="placeholder-text-color">20 is the standard Oncra duration</span>
                                                    <span className='text-danger'>{errors.project_duration && errors.project_duration.type === 'required'
                                                        && 'Project Duration is required'}</span>
                                                    <span className='text-danger'>{errors.project_duration && errors.project_duration.type === 'pattern'
                                                        && 'Project Duration must be decimal'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm='6'>
                                                <FormGroup className="mb-3">
                                                    <label>Potential per ha per year (tCO2/ha/y) <span className="text-danger">*</span></label>
                                                    <input className={`form-control mb-1`} placeholder='' defaultValue={preData?.potential_per_ha_year}
                                                        type="text" name='potential_per_ha_year' {...register('potential_per_ha_year', { required: true, pattern: /^[0-9\b]+$/ })} />
                                                    <span className='text-danger'>{errors.potential_per_ha_year && errors.potential_per_ha_year.type === 'required'
                                                        && 'Potential per ha per year is required'}</span>
                                                    <span className='text-danger'>{errors.potential_per_ha_year && errors.potential_per_ha_year.type === 'pattern'
                                                        && 'Potential per ha per year must be decimal'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col sm='6'>
                                                <FormGroup className="mb-3">
                                                    <label>Potential whole project per year (tCO2/y) <span className="text-danger">*</span></label>
                                                    <input className={`form-control mb-1`} placeholder='' defaultValue={preData?.potential_whole_project_year}
                                                        type="text" name='potential_whole_project_year'  {...register('potential_whole_project_year', { required: true, pattern: /^[0-9\b]+$/ })} />
                                                    <span className='text-danger'>{errors.potential_whole_project_year && errors.potential_whole_project_year.type === 'required'
                                                        && 'Potential whole project per year is required'}</span>
                                                    <span className='text-danger'>{errors.potential_whole_project_year && errors.potential_whole_project_year.type === 'pattern'
                                                        && 'Potential whole project per year must be decimal'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm='6'>
                                                <FormGroup className="mb-3">
                                                    <label>Total sequestration potential <span className="text-danger">*</span></label>
                                                    <input className={`form-control mb-1`} placeholder='' defaultValue={preData?.total_sequestration_potential}
                                                        type="text" name='total_sequestration_potential'
                                                        {...register('total_sequestration_potential', { required: true, pattern: /^[0-9\b]+$/ })} />
                                                    <span className="placeholder-text-color">Capacity minus baseline x surface area, tCO2</span>
                                                    <span className='text-danger'>{errors.total_sequestration_potential && errors.total_sequestration_potential.type === 'required'
                                                        && 'Total sequestration potential is required'}</span>
                                                    <span className='text-danger'>{errors.total_sequestration_potential && errors.total_sequestration_potential.type === 'pattern'
                                                        && 'Total sequestration potential must be decimal'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col sm='6'>
                                                <FormGroup className="mb-3">
                                                    <label>Estimated carbon income at ETS price <span className="text-danger">*</span></label>
                                                    <input className={`form-control mb-1`} placeholder='' defaultValue={preData?.estimated_carbon_income} type="text" name='estimated_carbon_income'
                                                        {...register('estimated_carbon_income', { required: true, pattern: /^[0-9\b]+$/ })} />
                                                    <span className="placeholder-text-color">Directly available, 12 year ahead, ex 20% buffer.</span>
                                                    <span className='text-danger'>{errors.estimated_carbon_income && errors.estimated_carbon_income.type === 'required'
                                                        && 'Total sequestration potential is required'}</span>
                                                    <span className='text-danger'>{errors.estimated_carbon_income && errors.estimated_carbon_income.type === 'pattern'
                                                        && 'Total sequestration potential must be decimal'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm='6'>
                                                <FormGroup className="mb-3">
                                                    <label>Name of field or facility <span className="text-danger">*</span></label>
                                                    <input className={`form-control mb-1`} placeholder='' type="text" name='facility_field_name'
                                                        defaultValue={preData?.facility_field_name} {...register('facility_field_name', { required: true })} />
                                                    <span className='text-danger'>{errors.facility_field_name && errors.facility_field_name.type === 'required' && 'Field name is required'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col sm='6'>
                                                <FormGroup className="mb-3">
                                                    <label>Geo-location (eg Google Maps link) <span className="text-danger">*</span></label>
                                                    <input className={`form-control mb-1`} placeholder='' type="text" name='geo_location'
                                                        defaultValue={preData?.geo_location} {...register('geo_location', { required: true })} />
                                                    <span className='text-danger'>{errors.geo_location && errors.geo_location.type === 'required' && 'Geo Location is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm='6'>
                                                <FormGroup className="mb-3">
                                                    <label>Legal ownership of field or facility</label>
                                                    <textarea className='form-control' defaultValue={preData?.legal_ownership}
                                                        {...register('legal_ownership', {
                                                            maxLength: { value: 4000, message: 'No of characters we can allow 4000 characters.' }
                                                        })} rows={4} cols={75} />
                                                    <span className="placeholder-text-color">Elaborate when it is not you</span>
                                                    <span className='text-danger'>{errors.legal_ownership && errors.legal_ownership.type === 'maxLength' &&
                                                        errors.legal_ownership.message}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col sm='6'>
                                                <FormGroup className="mb-3">
                                                    <label>How will you remove & store carbon?</label>
                                                    <textarea className='form-control' defaultValue={preData?.remove_store_carbon} {...register('remove_store_carbon',
                                                        { maxLength: { value: 4000, message: 'No of characters we can allow 4000 characters.' } })}
                                                        rows={4} cols={75} />
                                                    <span className="placeholder-text-color">Specify how you will e.g. increase soil carbon, increase the above ground biomass
                                                        stock, or other.</span>
                                                    <span className='text-danger'>{errors.remove_store_carbon && errors.remove_store_carbon.type === 'maxLength' &&
                                                        errors.remove_store_carbon.message}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {/* <Row>
                                    {
                                        allowedTypes.includes(type) && <Col sm='6'>
                                            <FormGroup >
                                                <label >Split Value</label>
                                                <input
                                                    className="form-control"
                                                    name="splitValue"
                                                    placeholder='%'
                                                    type="text"
                                                    defaultValue={Number(formdata?.splitValue) * 100}
                                                    {...register('splitValue', {
                                                        min: 0, max: 100, validate: {
                                                            checkNumber: (v) => !isNaN(v)
                                                        }
                                                    })}
                                                />
                                                <span className="text-danger">
                                                    {errors.splitValue?.type === 'max' && 'Split Value should be less than 100'}
                                                    {errors.splitValue?.type === 'min' && 'Split Value should be positive'}
                                                    {errors.splitValue?.type === 'checkNumber' && 'Value should be Number.'}
                                                </span>
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row> */}
                                {
                                    ((formdata?.registryName !== 'ONCRA') && (formdata?.subCategoryItemName !== 'EV Charging Stations')) && <Row>
                                        <Col sm='6'>
                                            <div>Project Associated with JNR program?</div>
                                            <Row>
                                                <Col sm='2'>
                                                    <div style={{ width: "26px", paddingTop: "20px" }}>
                                                        <label className="switch">
                                                            <input type="checkbox" id="togBtn" checked={jnrStatus}
                                                                {...register('isassociatedwithjnr')}
                                                                onChange={(e) => handleJNR(e.target.checked)}
                                                            />
                                                            <div className="slider round">
                                                                <span className="on">ON</span>
                                                                <span className="off">OFF</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col sm='10'><div style={{ paddingTop: "20px" }}>*JNR -Jurisdictional Nesting REDD (Reduing Emissions from Deforestation and Forest Degradation)</div></Col>
                                            </Row>
                                        </Col>
                                        <Col lg='6'>
                                    <FormGroup className='proj-details'>
                                        <label>UN Sustainable Development Goals</label>
                                        <Select
                                            isMulti={true}
                                            options={sdgGoals}
                                            placeholder="Select UN SDG Goals"
                                            value={sdgList}
                                            components={{
                                                Option: CustomOptionComponent
                                            }}
                                            onChange={(e) => handleSdgList(e)}
                                            styles={{
                                                option: (base, { isFocused, isSelected }) => {
                                                    return {
                                                        ...base,
                                                        background: isSelected ? '#003868' : isFocused ? '#0038681a' : undefined,
                                                        color: isSelected ? '#fff' : isFocused ? '#000' : undefined
                                                    }
                                                }
                                            }}
                                        />
                                        {/* <span className="text-danger">{sdgList?.length > 3 && 'Please select lessthan 3 Items'}</span> */}
                                    </FormGroup>
                                </Col>
                                    </Row>
                                }
                                {
                                    methodology == 'CCB' && <Row>
                                        <Col sm='6'>
                                            <FormGroup className="mb-3">
                                                <label>CCB Standard Name </label>
                                                <input className={`form-control ${errors.ccbstdname && 'is-invalid'} mb-1`} placeholder=''
                                                    type="text" name='ccbstdname' defaultValue={formdata?.ccbstandardname || ""} {...register('ccbstandardname')} />
                                                <span className='text-danger'>{errors.ccbstandardname && errors.ccbstandardname.type === 'required' && 'CCB Standard Name is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col sm='6'>
                                            <FormGroup className="mb-3">
                                                <label>CCB Standard </label>
                                                <input className={`form-control ${errors.ccbstd && 'is-invalid'} mb-1`} placeholder=''
                                                    type="text" name='ccbstd' defaultValue={formdata?.ccbstandard || ""} {...register('ccbstandard')} />
                                                <span className='text-danger'>{errors.ccbstandard && errors.ccbstandard.type === 'required' && 'CCB Standard is required'}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                }
                                {
                                    methodology == 'SDVI' && <Row>
                                        <Col sm='6'>
                                            <FormGroup className="mb-3">
                                                <label>SD Vista Project Type </label>
                                                <input className={`form-control ${errors.sdvistaprojtype && 'is-invalid'} mb-1`} placeholder='' type="text"
                                                    name='sdvistaprojtype' defaultValue={formdata?.sdvistaprojecttype || ""} {...register('sdvistaprojecttype')} />
                                                <span className='text-danger'>{errors.sdvistaprojecttype && errors.sdvistaprojecttype.type === 'required' && 'SD Visya Project Type is required'}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col sm='6'>
                                            <FormGroup className="mb-3">
                                                <label>Sustainable Development Goal Contributions </label>
                                                <input className={`form-control ${errors.susdevgoalcontri && 'is-invalid'} mb-1`} placeholder=''
                                                    type="text" name='susdevgoalcontri' defaultValue={formdata?.susdevgoalcontribution || ""} {...register('susdevgoalcontribution')} />
                                                <span className='text-danger'>{errors.susdevgoalcontribution && errors.susdevgoalcontribution.type === 'required' && 'Sustainable Development Goal Contribution is required'}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                }
                                
                                <Row>

                                    <Col sm='12'>
                                        <FormGroup className="mb-3">
                                            <label>Description</label>
                                            <textarea rows="3" cols="50" className="form-control" name="description" defaultValue={formdata?.description || ''}
                                                {...register('description', { maxLength: { value: 4000, message: 'No of characters we can allow 4000 characters.' } })}></textarea>
                                            <span className='text-danger'>{errors.description && errors.description.type === 'maxLength' && errors.description.message}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <div className="btn-mb mtop-2" >
                                    <Button color='primary' className='ml-1 update_btn' >Update</Button>
                                    {
                                        userType != 'SYS_ADMIN' && <Button color='light' disabled={preData?.status != 'DRAFT' ? true : false}
                                            className='btn-font ml-1' onClick={handleSubmit(handleDraft)} >{SaveasDraft}</Button>
                                    }
                                    <Button color='secondary' className='ml-1' onClick={handleCancel} >Cancel</Button>
                                    {
                                        formdata?.subCategoryItemName === 'EV Charging Stations' &&
                                        <Button color='primary' className='manage-asm-but' onClick={handleEvManage}>Manage EV Chargers</Button>
                                    }
                                </div>
                            </Form>
                        
                </Col>
            </Row>
        </Fragment>
    )
}

export default DetailsPage;