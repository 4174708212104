import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { LI, UL } from '../../../AbstractElements';
import { Back } from '../../../Common/Constant';
import { MenuItem } from './Menu';
import SidebarMenulist from './SidebarMenulist';
import { useStore, useSelector } from 'react-redux';
import { API_URL } from '../../../Common/Config/Config';
import fetchWrapper from '../../../Common/api/fetchWrapper';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logoutHelper } from '../../api/logout_helper';
import SimpleBar from 'simplebar-react';

const SidebarMenuItems = ({ wrapper, margin }) => {
  const Navigate = useNavigate();
  const { t } = useTranslation();
  const [menuList, setMenuList] = useState();

  let userType = useStore().getState().user?.userType;
  let suborgsOption = useStore().getState().user?.userDetails?.subOrgsEnabled;
  suborgsOption = suborgsOption ? suborgsOption : sessionStorage.getItem('suborgsEnabled');
  let targetEmissionsExists = useStore().getState().user?.userDetails ? useStore().getState().user?.userDetails?.targetEmissionsExists : sessionStorage.getItem('targetEmissionsExists');
  userType = userType ? userType : sessionStorage.getItem('userType');

  useEffect(() => {
    if (userType === 'ASO_USER' || userType === 'ASO_ADMIN') {
      getSubscribeMenu()
    } else {
      setMenuList(MenuItem[userType])
    }
  }, [])

  const getSubscribeMenu = async () => {
    await fetchWrapper(`${API_URL}/subscriptions/customer`)
      .then((res) => {

        if (res && res.json.length) {
          let subscribedMenu = MenuItem[userType][0].Items.filter(item =>
            res.json.find(sub => sub.id === item.id && sub.issubscribed)
          );
          if (userType === 'ASO_ADMIN') {
            suborgsOption === true && subscribedMenu.push(...MenuItem[userType][0].subOrgMenu);
            subscribedMenu.push(...MenuItem[userType][0].defaultMenu)
            subscribedMenu?.length > 0 && subscribedMenu?.filter(ele => ele?.id === 2 && ele?.title === 'GHG Emission')?.map((item) => {
              if (targetEmissionsExists === false) {
                if (item?.children[4]?.title === "Offsets") item?.children?.splice(4, 1);
              }
            })
          }

          if(userType === 'ASO_USER') {
            subscribedMenu.push(...MenuItem[userType][0].defaultMenu)
          }

          if (subscribedMenu.length) {
            MenuItem[userType][0].Items = subscribedMenu
            setMenuList(MenuItem[userType])
          } else {
            unSubscribeUser();
          }
        } else {
          unSubscribeUser()
        }
      }).catch((err) => {
        console.log(err)
      });
  }

  const unSubscribeUser = () => {
    toast.error('Not subscribe any module for this organization. Please contact to green carbon admin.');
    logoutHelper()
    Navigate(`${process.env.PUBLIC_URL}/login`);
  }

  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector('.page-header').className = 'page-header close_icon';
      document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon ';
      if (item.type === 'sub') {
        document.querySelector('.page-header').className = 'page-header ';
        document.querySelector('.sidebar-main').className = 'sidebar-main ';
      }
    }
  };

  return (
    <Fragment>
      <UL attrUL={{ className: 'sidebar-links simple-list', id: 'simple-bar' }}>
        <SimpleBar style={{ height: '350px' }}>
          <LI attrLI={{ className: 'back-btn' }}>
            <div className="mobile-back text-end"><span>{Back}</span><i class="fa-solid fa-angle-right ps-2"></i></div>
          </LI>
          {menuList && <SidebarMenulist MENUITEMS={menuList} t={t} toggletNavActive={toggletNavActive} />}
        </SimpleBar>
        <div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}>
          <div className="simplebar-scrollbar" style={{ width: 0, display: 'none' }}></div>
        </div>
        <div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}>
          <div className="simplebar-scrollbar" style={{ height: 77, transform: 'translate3d(0, 304, 0)', display: 'block' }}></div>
        </div>
      </UL >
    </Fragment >
  );
};
export default SidebarMenuItems;
