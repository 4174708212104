import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Badge, Label, Button } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DataTableComponent from '../DataTable/DataTableComponent';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { Eye, Edit, Trash2 } from 'react-feather';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import AddEvStation from './AddEvStation';
import { defaultUserType } from '../../api/helper';
import { useSelector } from 'react-redux';
import DeleteCharger from './DeleteStation';
import { toast } from 'react-toastify';
import AgGridTable from '../AgGridTable';
import { useCallback } from 'react';
import MapLocationModal from '../Location/MapLocationModal';

const EvStationList = () => {
    const [tableData, setTableData] = useState([]);
    const [showFilter, setShowFilter] = useState(false)
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const projectId = useParams()?.id
    const projectName = useParams()?.name;
    const userType = useSelector(state => state.user?.userType);
    const [deleteModalOpen, setDeleteOpen] = useState(false);
    const [deleteRow, setDeleteRow] = useState();
    const [activeStatus, setActiveStatus] = useState(true)
    const [activeCharger, setActiveCharger] = useState([])
    const [inactiveCharger, setInactiveCharger] = useState([]);
    const [addStatus, setAddStatus] = useState(false)
    const [deleteStatus, setDelete] = useState(false);
    const navigate = useNavigate()
    const stationRef = useRef();
    const [mapOpen, setMapOpen] = useState(false);
    const [mapData, setMapData] = useState();
    const [locationData, setLocationData] = useState();

    const handleMap = (data) => {
        let geoCoordinates = data?.geocoordinates?.split(',')
        const geoData = { lat: Number(geoCoordinates[0]), lng: Number(geoCoordinates[1]) }
        console.log(geoData)
        setMapData(geoData);
        setLocationData(data);
        setMapOpen(true)
    }

    const tableColumns = [
        {
            headerName: 'Station Name',
            field: 'name',
            filter: true,
            flex: 2,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Station Location',
            filter: true,
            flex: 3,
            sortable: true,
            cellRenderer: (params) => {
                const length = params.data?.locationName + params.data?.addressLine1
                return <div className='d-flex'>
                    <a  className="location-link" onClick={()=>handleMap(params.data)} ><i class="fa-solid fa-location-dot map-pin"></i></a>
                    <div className={`ml-1 ${length > 75 && 'cell-wrap'}`} >{`${params.data.addressLine1}, ${params.data.city}`}</div>
                </div>
            },
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            // cellClass: 'cell-wrap'
        },
        {
            headerName: 'Country',
            field: 'country',
            filter: true,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        // {
        //     headerName: 'City',
        //     field: 'city',
        //     filter: true,
        //     flex: 1.5,
        //     sortable: true,
        //     suppressMovable: true,
        //     // valueGetter: (params) => {
        //     //     return params.data.city + ' - ' + params.data.zipCode
        //     // },
        //     filterParams: {
        //         filterOptions: ['contains'],
        //         maxNumConditions: 0,
        //         buttons: ['reset']
        //     }
        // },
        {
            headerName: 'Charging Unit Capacity (KW)',
            field: 'chargingUnitCapacity',
            filter: true,
            width: 150,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Status',
            filter: false,
            width: 70,
            sortable: true,
            suppressMovable: true,
            cellRenderer: (params) => {
                return (
                    <div className=''>
                        <label className="switch">
                            <input type="checkbox" id="togBtn"
                                checked={params.data.isactive}
                                onChange={() => handleStatus(params.data.id, params.data.isactive)}
                            />
                            <div className="slider round">
                                <span className="on"></span>
                                <span className="off"></span>
                            </div>
                        </label>
                    </div>
                )
            }
        },
        {
            headerName: 'View',
            width: 60,
            cellRenderer: params => {
                return (
                    <Link to={`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/view-ev-station/${params.data.id}`} >
                        <Eye className={`action-icon`} />
                    </Link>
                )
            },
            suppressMovable: true,
        },
        {
            headerName: 'Edit',
            width: 50,
            cellRenderer: params => {
                return (
                    <Link to={`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/edit-ev-station/${params.data.id}`} >
                        <Edit className={`edit-icon `} />
                    </Link>
                )
            },
            suppressMovable: true,
        },
        {
            headerName: 'Delete',
            width: 70,
            cellRenderer: params => {
                return (
                    <div onClick={() => handleDeleteModal(params.data.id)} >
                        <Trash2 className={'trash-icon'} />
                    </div>
                )
            },
            suppressMovable: true,
        },
    ]



    const handleStatus = async (id, status) => {
        let updateStatus = status === true ? false : true;
        const options = {
            method: 'PUT'
        }
        await fetchWrapper(`${API_URL}/evstation/status/${id}/${updateStatus}`, options)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    if (res?.json?.isactive === true)
                        toast.success('EV Station Activated Successfully!')
                    else
                        toast.error('EV Station Deactivated Successfully!')
                    loadEvStations()
                }
            })
            .catch(err => {
                console.log(err)

            })
    }

    const loadEvStations = async () => {
        await fetchWrapper(`${API_URL}/evstation/project/${projectId}`)
            .then((res) => {
                console.log(res)
                let activeData = res.json?.filter(i => i.isactive === true)
                setActiveCharger(activeData)
                let deactiveData = res.json?.filter(i => i.isactive === false)
                setInactiveCharger(deactiveData);
                setActiveStatus(true)
                setTableData(activeData)
            }, (err) => { console.log(err) })
    }

    useEffect(() => {
        if (addStatus || deleteStatus) {
            loadEvStations();
        }

    }, [addStatus, deleteStatus])

    const handleDeleteModal = (id) => {
        setDeleteRow(id)
        setDeleteOpen(true)
    }

    const handleClick = () => {
        setOpen(true)
    }


    const handleActiveSwitch = (val) => {
        setLoading(true)
        let status = val === true ? false : true;
        setActiveStatus(pre => (pre, status));
        if (status === true) {
            setLoading(false)
            setTableData(activeCharger)
        } else {
            setLoading(false)
            setTableData(inactiveCharger);
        }
    }

    const handleBack = () => {
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/view-project/${projectId}`)
    }

    const onGridReady = useCallback(async (params) => {
        stationRef.current.api.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/evstation/project/${projectId}`)
            .then((res) => {
                console.log(res)
                stationRef.current.api.hideOverlay();
                let activeData = res.json?.filter(i => i.isactive === true)
                setActiveCharger(activeData)
                let deactiveData = res.json?.filter(i => i.isactive === false)
                setInactiveCharger(deactiveData);
                setActiveStatus(true)
                setTableData(activeData)
            }, (err) => {
                console.log(err)
                stationRef.current.api.hideOverlay();
                if (err.status === 404) {
                    stationRef.current.api.showNoRowsOverlay();
                }
            })
    })

    return (
        <Fragment>
            <LayoutLoader />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <div className="d-flex mtop-1">
                            <Breadcrumbs parent="EV Stations List" title="List" mainTitle={`${projectName} - EV Stations`} />
                            <div className="text-right  action_button mr-13">
                                <div className="link action_button" onClick={handleClick}>
                                    <i className="icon-style icofont  icofont-plus-circle" ></i>
                                    &nbsp;&nbsp;{"Add New"}
                                </div>
                            </div>
                        </div>

                        <Card className="newtable ev-charges">
                            <CardBody className='data-tables'>
                                <Row>
                                    <Col>
                                        <div className='d-flex active-switch'>
                                            <div>
                                                <Label className='active-switch-lable title-color'>Active Stations</Label>
                                            </div>
                                            <label className="switch ml-1 ">
                                                <input type="checkbox" id="togBtn"
                                                    checked={activeStatus}
                                                    onChange={() => handleActiveSwitch(activeStatus)}
                                                />
                                                <div className="slider round">
                                                    <span className="on"></span>
                                                    <span className="off"></span>
                                                </div>
                                            </label>
                                        </div>
                                    </Col>
                                </Row>

                                {/* <DataTableComponent tableData={tableData} columnsData={tableColumns}
                                    setShowFilter={setShowFilter} loading={loading} filterStatus={false} /> */}
                                <Row>
                                    <Col lg='12'>
                                        <div className='station-table'>
                                            <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                                <AgGridTable
                                                    columnData={tableColumns}
                                                    tableData={tableData}
                                                    agRef={stationRef}
                                                    onGridReady={onGridReady}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>


                                <div>
                                    <Button color='secondary' onClick={handleBack}>Back</Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {
                    deleteModalOpen && <DeleteCharger dataId={deleteRow} value={deleteModalOpen} setOpenModal={setDeleteOpen} setDelete={setDelete} />
                }
                {
                    open && <AddEvStation value={open} setOpenModal={setOpen} projectId={projectId} projectName={projectName} setAdd={setAddStatus} />
                }
                {
                    mapOpen && <MapLocationModal value={mapOpen} setOpenModal={setMapOpen} data={mapData} locationData={locationData}/>
                }
            </Container>
        </Fragment>
    )
}

export default EvStationList;