import React, { Fragment, useState, useEffect } from "react";
import { CardBody, Spinner, Table } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap";
import { H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm } from "react-hook-form";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import uploadSignimg from "../../../assets/images/upload-sign.svg"
import { useNavigate } from "react-router-dom";
import ButtonLoader from '../ButtonLoader'
import { defaultUserType } from "../../api/helper";
import { Download } from "react-feather";
import Dropzone from 'react-dropzone-uploader';

const IssuedCredits = () => {
    const [notes, setNotes] = useState()
    const { register, handleSubmit, formState: { errors }, resetField, setValue } = useForm();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [docLoading, setDocLoading] = useState(false)
    const [fileArray, setFileArray] = useState([]);
    const [sizeExceed, setSizeExceed] = useState(false);
    const projectId = window.location.pathname.split('/')[3]
    const type = sessionStorage.getItem('userType')

    useEffect(() => {
        setValue('name', sessionStorage.getItem('projectName'))
        setValue('currency', sessionStorage.getItem('currency'))

    }, [])

    const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

    const onSubmit = async (data) => {
        setLoading(true)

        const options = {
            method: 'POST',
            body: {
                projectId: projectId,
                amount: data?.amount,
                notes: notes
            }
        }

        await fetchWrapper(`${API_URL}/projectcredits/credits/issue`, options)
            .then(res => {
                console.log(res)
                if (res.ok) {
                    setLoading(false)
                    setNotes('')
                    resetField('amount')
                    toast.success('Credits Issued Successfully!')
                }
            }, (err) => {
                setLoading(false)
                console.log(err)
            })
    }

    const handleCancel = () => {
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[type]}/projects`)
    }

    const handleBack = () => {
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[type]}/view-edit/view/${projectId}`)
    }


    const handleUpload = async (e) => {
        setDocLoading(true)
        if (fileArray && fileArray.length < 5) {
            let file = e.target.files[0]
            if (file.size < 4194304) {
                const authToken = sessionStorage.getItem('accessToken')
                let data = new FormData();
                data.append('file', e.target.files[0])
                data.append('documentTypeName', 'Others')
                data.append('otherDocumentTypeName', 'Issue')
                const options = {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${authToken}`
                    },
                    body: data
                }
                await fetchWrapper(`${API_URL}/document/credits?projectId=${projectId}`, options)
                    .then((res) => {
                        console.log(res)
                        if (res.ok) {
                            let arr = [...fileArray];
                            arr.push(res.json);
                            setFileArray(arr);
                            setSizeExceed(false)
                            setDocLoading(false)
                            toast.success('Document Uploaded successfully!')
                        }
                    })
                    .catch((error) => {
                        setDocLoading(false)
                        console.error(error)
                    })
            } else {
                setSizeExceed(true)
                setDocLoading(false)
            }
        }
    }

    const loadData = async () => {
        await fetchWrapper(`${API_URL}/document/${projectId}/Issue`)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setFileArray(res.json)
                }
            }, (error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        loadData();
    }, [])

    return (
        <Fragment>
            <Breadcrumbs parent="Projects" title="Issue Credits" mainTitle='Issue Credits' />
            <Row>
                <Col lg='12'>
                    <Card>
                        <CardBody>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup className="mb-3">
                                            <label>Project Name <span className='required'>*</span></label>
                                            <input className={`form-control mb-1`} placeholder='' type="text"
                                                name='projectName' disabled={true}
                                                {...register('name', { required: true })} />
                                            <span className='text-danger'>{errors.name && errors.name.type === 'required' && 'Project Name is required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <FormGroup >
                                            <Label >  Currency<span className='required'>*</span> </Label>
                                            <input
                                                className="form-control"
                                                name="currency"
                                                placeholder=""
                                                type="text"
                                                required
                                                disabled={true}
                                                {...register('currency')}
                                            />
                                            <span className="text-danger">{errors.currency && 'Currency is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col sm='6'>
                                        <FormGroup >
                                            <Label >  Amount<span className='required'>*</span> </Label>
                                            <input
                                                className="form-control"
                                                name="amount"
                                                placeholder=""
                                                type="text"
                                                required
                                                {...register('amount')}
                                            />
                                            <span className="text-danger">{errors.amount && 'Amount is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col sm='6'>
                                        <div className="dz-message needsclick">
                                            <Dropzone
                                                maxFiles={1}
                                                multiple={false}
                                                accept='.xlsx,.xls, .pdf, .doc, .txt, .jpeg, .kml'
                                                getUploadParams={getUploadParams}
                                                onChangeStatus={handleUpload}
                                                maxSizeBytes={4194304}
                                                inputContent="Drag and Drop Files"
                                            />
                                        </div>
                                        {/* <div className="mtop-2">
                                            <label id="uploadBtn"  >
                                                <input type='file'
                                                    onChange={handleUpload} hidden accept='.xlsx,.xls, .pdf, .doc, .txt, .jpeg, .jpg'
                                                />
                                                Upload
                                                {
                                                    docLoading ? <Spinner style={{ height: "15px", width: "15px" }} className="ml-1" color="#2d2d2d" /> : <img style={{ height: "10px", width: "10px" }} className="ml-1" src={uploadSignimg} />
                                                }
                                            </label>
                                        </div> */}
                                        <div>
                                            {
                                                fileArray && fileArray.length === 5 && <span className='text-danger'>Only 5 docs are allowed</span>
                                            }
                                            {
                                                sizeExceed && <span className='text-danger'>File size should be below 4 MB</span>
                                            }
                                        </div>

                                    </Col>
                                </Row>
                                <p className='no-wrap'>[Note:Supported formats xlsx,xls,PDF,Doc,jpeg and file size should be 4MB per file]</p>
                                <Row>
                                    <Col sm='6'>
                                        <label>Notes </label>
                                        <textarea rows={4} cols={25} className="form-control" placeholder="Notes..." value={notes} onChange={(e) => setNotes(e.target.value)} />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <div className="mt-40" >

                                            {
                                                loading ? <ButtonLoader type="grow" color='primary' className='btn-font' loaderText={"Submitting"} /> :
                                                    <Button color='primary' className='btn-font ml-1' type="submit" >Submit</Button>
                                            }
                                            <Button color='secondary' className='btn-font ml-1' onClick={handleCancel} >Cancel</Button>
                                            <Button color='warning' className='btn-font ml-1' onClick={handleBack} >Back</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                    <Card >
                        <CardBody>
                            <Row>
                                <Col lg='12'>
                                    <Table striped bordered>
                                        <thead className='f-16'>
                                            <tr className="notes-table-header">
                                                <th>Document Name</th>
                                                <th>Size</th>
                                                <th>Download</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                fileArray && fileArray.length > 0 && fileArray.map((item) => {
                                                    return (
                                                        <tr className="notes-table-body">
                                                            <td>{item.originalFileName}</td>
                                                            <td>{(item.size / 1024).toFixed()}KB</td>
                                                            <td><a href={item.path} target='_blank'><Download className='document-icon' /></a></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    {
                                        fileArray && fileArray?.length == 0 && <div className='d-flex-center mtop-2'>No Records Found..</div>
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default IssuedCredits; 
