import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import DataTableComponent from '../DataTable/DataTableComponent';
import { Eye, PlusCircle, Trash2 } from 'react-feather';
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { toast } from 'react-toastify';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import AddSubOrganization from './AddSubOrganization';
import { useSelector } from 'react-redux';
import { defaultUserType } from '../../api/helper';
import { BsFileEarmarkBarGraph } from 'react-icons/bs';

const SubOrganizationList = () => {
    const [open, setOpen] = useState(false);
    const [showFilter, setShowFilter] = useState(false)
    const [orgList, setOrgList] = useState()
    const [loading, setLoading] = useState(false);
    const [addStatus, setAddStatus] = useState(false);
    const userType = useSelector(state => state?.user?.userType);

    const defaultOrgType = {
        "ASSET_OWNER": 'Asset Owner',
        "ASSESSOR": 'Assessor',
        "ASSET_MANAGER": 'Asset Manager'
    }

    const loadData = async () => {
        setLoading(true)
        const options = {
            method: 'GET'
        }
        await fetchWrapper(`${API_URL}/organization/subOrgs`, options)
            .then(res => {
                console.log(res)
                setLoading(false)
                setOrgList(res.json)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            })
    }

    const handleStatus = async (id, status) => {
        let updateStatus = status === true ? false : true;
        const options = {
            method: 'PUT'
        }
        await fetchWrapper(`${API_URL}/organization/status/${id}/${updateStatus}`, options)
            .then((res) => {
                if (res.status === 200) {
                    if (res?.json?.isactive === false) {
                        toast.error('User has been disabled!')
                    } else {
                        toast.success('User has been enabled!')
                    }
                    loadData()
                }
            })
            .catch(err => console.log(err))
    }

    const tableColumns = [
        {
            name: 'Company Name',
            selector: row => `${row.companyName}`,
            sortable: true,
            center: false,
            filterable: showFilter,
        },
        {
            name: 'Website',
            selector: row => `${row.website}`,
            sortable: true,
            center: false,
            filterable: showFilter,
        },
        {
            name: 'address',
            selector: row => `${row.address}`,
            sortable: true,
            center: false,
            filterable: showFilter,
        },
        {
            name: 'City',
            selector: row => `${row.state}, ${row.country}`,
            sortable: true,
            center: false,
            filterable: showFilter,
        },
        {
            name: "Ownership History",
            cell: (row) => (
                <Link to={`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/ownership-history/${row.id}/${row.companyName}`}>
                    <BsFileEarmarkBarGraph className={`ownership-icon`} />
                </Link>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
        {
            name: 'Status',
            cell: (row) => (
                <div className='mr-36'>
                    <label className="switch">
                        <input type="checkbox" id="togBtn" checked={row.isactive} 
                        onChange={() => handleStatus(row.id, row.isactive)} />
                        <div className="slider round">
                            <span className="on"></span>
                            <span className="off"></span>
                        </div>
                    </label>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: false,
            button: true,
            center: true
        },
        {
            name: "View",
            cell: (row) => (
                <Link to={`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/view-sub-organization/${row.id}`}>
                    <Eye className={`action-icon`} />
                </Link>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: "Edit",
            cell: (row) => (
                <Link to={`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/edit-sub-organization/${row.id}`}>
                    <Edit className={`edit-icon`} />
                </Link>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    useEffect(() => {
        loadData()
    }, [])

    useEffect(()=>{
        if(addStatus === true) {
            loadData()
        }
    }, [addStatus])

    const handleClick = () => setOpen(true);
    
    return (
        <Fragment>
            <LayoutLoader />
            <Container fluid={true}>
                <Row>
                    <Col sm="11">
                        <div className="d-flex">
                            <Breadcrumbs mainTitle="Sub Organizations" parent="Organizations" title="List" />
                            <div className="text-right action_button">
                                <div className="link action_button" onClick={handleClick}>
                                    <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont  icofont-plus-circle" ></i>
                                    &nbsp;&nbsp;{"Add Sub Organization"}
                                </div>
                            </div>
                        </div>
                        <Card className=" newtable">
                            <CardBody className='data-tables responsive-view subOrgList'>
                                <DataTableComponent tableData={orgList} columnsData={tableColumns} title={'Organizations List'}
                                    setShowFilter={setShowFilter} loading={loading} />
                            </CardBody>
                        </Card>
                    </Col>
                    {open && <AddSubOrganization value={open} setOpenModal={setOpen} setAdd={setAddStatus}/>}
                </Row>
            </Container>
        </Fragment>
    );
};

export default SubOrganizationList;