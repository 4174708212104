import React, { Fragment, useEffect, useState } from "react";
import { CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    Button
} from "reactstrap";
import { H3, H4 } from "../../../AbstractElements";
import { toast } from "react-toastify";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { X } from "react-feather";

const DeleteGroup = ({ dataId, value, setOpenModal, setDelete }) => {
    const [open, setOpen] = useState(value)

    const onClose = () => {
        setOpen(false)
        setOpenModal(false)
    }

    const onDelete = async () => {
        try {
            const options = {
                method: 'DELETE'
            }
            await fetchWrapper(`${API_URL}/group/${dataId}`, options)
                .then((res) => {
                    console.log(res)
                    if (res.status === 200) {
                        setDelete(true)
                        setOpen(false)
                        setOpenModal(false)
                        toast.success('Group Deleted Successfully!', { autoClose: 2000 })
                    }
                })
                .catch((error) => {
                    console.log(error)
                    if (error.status === 200) {
                        setDelete(true)
                        setOpen(false)
                        setOpenModal(false)
                        toast.success('Group Deleted Successfully!', { autoClose: 2000 })
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Fragment>
            <Modal isOpen={open} size='md' centered={true}>
                <ModalHeader>
                <H3 attrH3={{ className: 'modal-title-color' }}>Delete Group</H3>
                <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <p >Are you sure you want to delete this group?</p>
                    <div className="d-flex">
                    <Button color="primary" onClick={onDelete}>Delete</Button>
                    <Button color='secondary'  className="ml-1" onClick={onClose}>Cancel</Button>
                    </div>
                </ModalBody>
                
            </Modal>
        </Fragment>
    )
}

export default DeleteGroup