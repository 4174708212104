import React, { Fragment, useState, useEffect } from "react";
import { CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { countries } from "../../Data/CountryList/Defaultdata";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import ButtonLoader from "../ButtonLoader";
import { defaultUserType, defaultOrgControlType, CustomInput } from "../../api/helper";
import { currencySymbol } from "../../api/currencyData";
import DatePicker from 'react-datepicker';
import moment from "moment";

const SubOrganizationForm = ({ type, title, action, navigateUrl, cancel, preData, setAdd }) => {
    const orgControl = {
        'Equity': 'EQUITY',
        'Operational': 'OPERATIONAL',
        'Financial': 'FINANCIAL'
    }
    const [loading, setLoading] = useState(false)
    const [stateList, setStateList] = useState([]);
    const [country, setCountry] = useState();
    const [data, setData] = useState(preData)
    const { register, handleSubmit, formState: { errors }, reset, setValue, control } = useForm({ defaultValues: data });
    const [workspace, setWorkspace] = useState();
    const [ghgEmission, setGhgEmission] = useState(false);
    const [state, setState] = useState(preData?.state);
    const [tenantData, setTenantData] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [controlType, setControl] = useState(orgControl[preData?.controlType]);
    const [currency, setCurrency] = useState();
    const [relationshipOwner, setRelationship] = useState();
    const [showDate, setShowDate] = useState(false);
    const [effectiveDate, setEffectiveDate] = useState();

    const countryChangeEvent = (item, countryList) => {
        const selectedItem = countryList.find((countryItem) => countryItem.country === item);
        setCountry(item);
        setStateList(selectedItem.states);
        setValue('state', '')
        setState();
    }
    const [orgType, setOrgType] = useState('');
    const navigate = useNavigate();



    const userType = sessionStorage.getItem('userType');

    const loadTenantData = async () => {
        await fetchWrapper(`${API_URL}/tenants`)
            .then((res) => {
                setTenantData(res.json);
            }, (err) => console.log(err))
    }

    const getCurrency = async () => {
        await fetchWrapper(`${API_URL}/currency`)
            .then((res) => {
                setCurrencyList(res.json)
            }, (err) => console.log(err))
    }

    const onSubmit = async (data) => {
        setLoading(true)
        data.ghgEmission = data?.ghgEmission === 'true' ? true : false;
        data.orgType = 'ASSET_OWNER';
        data.subOrgsEnabled = true;
        data.controlType = controlType;
        console.log(data)
        let url, message;
        let method = action === "Add" ? 'POST' : 'PUT';
        if (action === 'Add') {
            url = `${API_URL}/organization`
            message = 'Organization Created Successfully!'
        }
        if (action === 'Edit') {
            url = `${API_URL}/organization/${data.id}`
            message = 'Organization Updated Successfully!'
        }
        const options = {
            method: method,
            body: data
        }
        await fetchWrapper(url, options)
            .then((res) => {
                if (res.ok) {
                    action === 'Add' && setAdd(true)
                    toast.success(message)
                    setLoading(false)
                    action === 'Add' && cancel()
                    if (action === 'Add') {
                        setTimeout(() => {
                            navigate(`${process.env.PUBLIC_URL}/aso-admin/module-origanization`)
                        }, 1000)
                    }
                    action === 'Edit' && navigate(navigateUrl)
                }
            })
            .catch((err) => {
                setLoading(false)
                if (err.status == 409) {
                    toast.error('Organization Already Exist..')
                    console.log(err)
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }

    useEffect(() => {
        setData(prev => ({ ...prev, ...preData }))
        loadTenantData();
        reset(preData)
        getCurrency();
        if (preData) {
            let statesList = countries.find((item) => item.country === preData?.country)
            setStateList(statesList?.states)
            setGhgEmission(preData?.ghgEmission)
            setControl(orgControl[preData?.controlType])
            setCurrency(preData?.currency)
            setValue('relationshipOwner', preData?.relationshipOwner)
            setRelationship(preData?.relationshipOwner)
        }
    }, [preData])

    const handleControl = (value) => {
        setControl(value)
        setShowDate(true)
    }

    return (
        <Fragment>
            <Row>
                <Col lg={action === 'Add' ? 12 : 10}>
                    {title && <H3 attrH3={{ className: 'mtop-2 ml-15' }}>{title}</H3>}
                    <Card className={`${action === 'Add' && 'mb-0'} ${action === 'Edit' && ''}`}>
                        <CardBody className={action === 'Add' && 'p-0 mb-0 bs-none'}>
                            <Form
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <Row>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label for="exampleEmail">Company Name<span className='required'>*</span> </Label>
                                            <input
                                                className="form-control"
                                                name="companyName"
                                                placeholder=""
                                                type="text"
                                                defaultValue={data?.companyName || ''}
                                                {...register('companyName', { required: true })}
                                            />
                                            <span className="text-danger">{errors.companyName && 'Company Name is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col lg='6'>
                                        <div>
                                            <FormGroup >
                                                <Label>Website <span className='required'>*</span> </Label>
                                                <input
                                                    className="form-control"
                                                    id="exampleEmail"
                                                    name="website"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.website || ''}
                                                    {...register('website', { required: true, pattern: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ })}
                                                />
                                                <span className="text-danger">{errors?.website?.type === 'required' && 'Website is Required'}</span>
                                                <span className="text-danger">{errors?.website?.type === 'pattern' && 'Invalid Website URL'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='12'>
                                        <div>
                                            <FormGroup >
                                                <Label>Address <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    id="exampleEmail"
                                                    name="address"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.address || ''}
                                                    {...register('address', { required: true })}
                                                />
                                                <span className="text-danger">{errors.address && 'Address is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label>City<span className='required'>*</span> </Label>
                                            <input
                                                className="form-control"
                                                name="city"
                                                placeholder=""
                                                type="text"
                                                defaultValue={data?.city || ''}
                                                {...register('city', { required: true })}
                                            />
                                            <span className="text-danger">{errors.city && 'City is Required'}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup >
                                            <Label>Zipcode<span className='required'>*</span> </Label>
                                            <input
                                                className="form-control"
                                                name="zipcode"
                                                placeholder=""
                                                type="text"
                                                defaultValue={data?.zipcode}
                                                {...register('zipCode', { required: true, maxLength: 8, minLength: 5 })}
                                            />
                                            <span className="text-danger">{errors.zipCode?.type === 'required' && 'Zipcode is Required'}</span>
                                            <span className="text-danger">{errors.zipCode?.type === 'maxLength' && 'Zipcode should be less than 8 characters'}</span>
                                            <span className="text-danger">{errors.zipCode?.type === 'minLength' && 'Zipcode should be more than 5 characters'}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup >
                                                <Label>Country <span className='required'>*</span></Label>
                                                <select className="form-select" name="country"
                                                    defaultValue={data?.country || ''}
                                                    {...register('country', { required: true })}
                                                    onChange={e => countryChangeEvent(e.target.value, countries)}
                                                >
                                                    <option value=''>-Select Country-</option>
                                                    {countries.map((item, i) => {
                                                        return (
                                                            <option value={item.country}>{item.country}</option>
                                                        )
                                                    }
                                                    )}
                                                </select>
                                                <span className="text-danger">{errors.country && 'Country is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup >
                                                <Label>State <span className='required'>*</span></Label>
                                                <select className="form-select" name="state"
                                                    value={state}
                                                    {...register('state', { required: true })}
                                                    onChange={(e) => setState(e.target.value)}
                                                >
                                                    <option>-Select State-</option>
                                                    {
                                                        stateList && stateList.length > 0 && stateList.map(i => {
                                                            return (<option value={i}>{i}</option>)
                                                        })
                                                    }
                                                </select>
                                                <span className="text-danger">{errors.state && 'state is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='6'>
                                        <FormGroup className="mb-6">
                                            <label>Currency</label>
                                            <select className="form-select" name='currency' value={currency}
                                                {...register('currency')}
                                                onChange={(e) => setCurrency(e.target.value)}
                                            >
                                                <option value={''}>-Select Currency-</option>
                                                {
                                                    currencyList?.length > 0 && currencyList?.map((item) => {
                                                        return (
                                                            <option value={item.currencyCode}>{item.description}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label>Control Type<span className='required'>*</span></Label>
                                            <select className="form-select" name="controlType" value={controlType}
                                                {...register('controlType', { required: true })}
                                                onChange={e => handleControl(e.target.value)}
                                            >
                                                <option value=''>-Select Control Type-</option>
                                                <option value="EQUITY">Equity</option>
                                                <option value="OPERATIONAL">Operational</option>
                                                <option value="FINANCIAL">Financial</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='6'>
                                        <FormGroup >
                                            <Label>Control Percentage<span className='required'>*</span></Label>
                                            <input
                                                className="form-control"
                                                name="controlTypePercentage"
                                                placeholder="%"
                                                type="text"
                                                {...register('controlTypePercentage', { required: true, min: 0, max: 100 })}
                                                onChange={() => setShowDate(true)}
                                            />
                                            <span className="text-danger">{errors.controlTypePercentage?.type === 'required' && 'Control Percentage is Required'}</span>
                                            <span className="text-danger">{errors.controlTypePercentage?.type === 'min' && 'The value should be greater than 0'}</span>
                                            <span className="text-danger">{errors.controlTypePercentage?.type === 'max' && 'The value should be less than 100'}</span>
                                        </FormGroup>
                                    </Col>
                                    {
                                        (action === 'Edit' && showDate) && <Col lg='6'>
                                            <FormGroup className="mb-3">
                                                <label>Effective Date <span className="text-danger">*</span> </label>
                                                <Controller
                                                    name='effectiveDate'
                                                    control={control}
                                                    rules={{ required: true }}
                                                    onChange={(e) => setEffectiveDate(moment(e).format('YYYY-MM-DD'))}
                                                    // defaultValue={new Date()}
                                                    render={({ field: { onChange, value } }) => {
                                                        return (
                                                            <DatePicker
                                                                className="datepicker-here form-control"
                                                                selected={value}
                                                                onChange={e => {
                                                                    onChange(e)
                                                                }}
                                                                dropdownMode="select"
                                                                dateFormat="dd MMM, yyyy"
                                                                customInput={<CustomInput />}
                                                                minDate={new Date()}
                                                            />
                                                        )
                                                    }}
                                                />
                                                <span className='text-danger'>{errors.effectiveDate && errors.effectiveDate.type === 'required' && 'Effective Date is required'}</span>
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                                <div className="mb-20">
                                    <H3>Contact Person Details</H3>
                                </div>
                                <Row>
                                    <Col lg='6'>
                                        <div>
                                            <FormGroup >
                                                <Label>First Name <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    id="exampleEmail"
                                                    name="firstName"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.firstName || ''}
                                                    {...register('firstName', { required: true })}
                                                />
                                                <span className="text-danger">{errors.firstName && 'First Name is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup >
                                                <Label for="exampleEmail">  Last Name <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    name="lastName"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.lastName || ''}
                                                    {...register('lastName', { required: true })}
                                                />
                                                <span className="text-danger">{errors.firstName && 'First Name is Required'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup >
                                                <Label for="exampleEmail"> Email ID  {action === 'Add' && <span className='required'>*</span>}  (Login ID)</Label>
                                                <input
                                                    className="form-control"
                                                    id="exampleEmail"
                                                    name="email"
                                                    placeholder=""
                                                    type="email"
                                                    disabled={action === 'Add' ? false : true}
                                                    defaultValue={data?.email || ''}
                                                    {...register('email', { required: true, pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
                                                />
                                                <span className="text-danger">{errors?.email?.type === 'required' && 'email is Required'}</span>
                                                <span className="text-danger">{errors?.email?.type === 'pattern' && 'Invalid Email'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div>
                                            <FormGroup >
                                                <Label> Phone <span className='required'>*</span></Label>
                                                <input
                                                    className="form-control"
                                                    id="exampleEmail"
                                                    name="phoneNumber"
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={data?.phone || ''}
                                                    {...register('phoneNumber', { required: true, pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/ })}
                                                />
                                                <span className="text-danger">{errors.phoneNumber?.type === 'required' && 'Phone Number is Required'}</span>
                                                <span className="text-danger">{errors.phoneNumber?.type === 'pattern' && 'Invalid Phone Number'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col lg='6'>
                                        <div >
                                            <FormGroup >
                                                <Label> User Name {action === 'Add' && <span className='required'>*</span>}</Label>
                                                <input
                                                    className="form-control"
                                                    id="exampleEmail"
                                                    name="userName"
                                                    placeholder=""
                                                    disabled={action === 'Add' ? false : true}
                                                    type="text"
                                                    defaultValue={data?.userName || ''}
                                                    {...register('userName', { required: true, pattern: /^(?=.{4,20}$)(?:[a-z\d]+(?:(?:\.|-|_)[a-zA-Z\d])*)+$/ })}
                                                />
                                                <span className="text-danger">{errors.userName?.type === 'required' && 'User Name is Required'}</span>
                                                <span className="text-danger">{errors.userName?.type === 'pattern' && 'User Name should be contain 4 - 20 characters and should be Alphanumeric(Lowercase only) and Underscore(_) only'}</span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                </Row> */}
                                <Row>
                                    <div className="d-flex " style={{ marginTop: "25px" }}>
                                        {
                                            loading ? <ButtonLoader loaderText={`${action === "Add" ? action : 'Updat'}ing`} color="primary" /> : <Button color="primary" >{action === "Add" ? action : 'Update'}</Button>
                                        }
                                        <Button color="secondary" className="ml-1" onClick={() => action === 'Add' ? cancel() : navigate(navigateUrl)}> Cancel</Button>
                                    </div>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default SubOrganizationForm;