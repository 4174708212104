// dashbaord
import UserDashboard from '../User/Dashboard/UserDashboard';
import PowerBiReport from '../User/Dashboard/PowerBiReport';

//Green-Carbon Pages
import Projects from '../User/ProjectHistory';
import CustomersList from '../Admin/Customer';
import EditProfile from '../Common/Component/Profile/EditProfile';
import UserManagement from '../Admin/UserManagement/UserManagement';
import EditUser from '../Admin/UserManagement/EditUser';
import ProponentProjects from '../Proponent/AsmUserProjectHistory';
import PaymentMethod from '../User/PaymentMethod';
import Subscription from '../User/Subscription';
import ViewAsmUserProject from '../Proponent/AsmUserProjectHistory/ViewProject';
import AdminProjects from '../Admin/AdminProjects';
import EditCustomer from '../Admin/Customer/Edit Customer';
import AssessorProjects from '../Assessor/AssessorProjects';
import UserProjectInit from '../User/UserProjectInit';
import AsmUserProjectInit from '../Proponent/AsmUserProjectInitiation';
import ViewAssessorProject from '../Assessor/AssessorProjects/ViewAssessorProject';
import ViewUserProject from '../User/ProjectHistory/ViewProject';
import ViewAdminProject from '../Admin/AdminProjects/AdminViewProject';
import AsoAdminProjectInit from '../ASOAdmin/AsoAdminProjectInit';
import AsoAdminProjectList from '../ASOAdmin/AsoAdminProjectList';
import ViewAsoAdminProject from '../ASOAdmin/AsoAdminProjectList/AsoAdminViewProject';
import AsmAdminProjectInit from '../ASMAdmin/AsmAdminProjectInit';
import AsmAdminProjectList from '../ASMAdmin/AsmAdminProjectList';
import ViewAsmAdminProject from '../ASMAdmin/AsmAdminProjectList/AsmAdminViewProject';
import AsrAdminProjectList from '../ASRAdmin/AsrAdminProjectList';
import ViewAsrAdminProject from '../ASRAdmin/AsrAdminProjectList/ViewAsrAdminProject';
import AsoUserlist from '../ASOAdmin/AsoUserOnboard/AsoUserlist';
import EditAsoUser from '../ASOAdmin/AsoUserOnboard/EditAsoUser';
import AsrUserlist from '../ASRAdmin/AssessorOnBoarding/AsrUserlist';
import EditAsrUser from '../ASRAdmin/AssessorOnBoarding/EditAsrUser';
import AsmUserlist from '../ASMAdmin/AsmUserOnboard/AsmUserlist';
import EditAsmUser from '../ASMAdmin/AsmUserOnboard/EditAsmUser';
import EditUserProject from '../User/ProjectHistory/EditProject';
import AsoAdminEditProject from '../ASOAdmin/AsoAdminProjectList/AsoAdminEditProject';
import ASMMapping from '../Common/Component/ASMMapping';
import AdminEditProject from '../Admin/AdminProjects/AdminEditProject';
import IssuedCredits from '../Common/Component/IssuedCredits';
import CertifyCredits from '../Common/Component/CertifyCredits';
import WalletSummary from '../Common/Component/Wallet/Summary';
import WalletDetailsPage from '../Common/Component/Wallet/Details';
import EditAssessorProject from '../Assessor/AssessorProjects/EditAssessorProject';
import EditAsrAdminProject from '../ASRAdmin/AsrAdminProjectList/EditAsrAdminProject';
import OrderDetails from '../Common/Component/CarbonTrading/Orders/OrderDetails';
import ViewCustomer from '../Admin/Customer/ViewCustomer';
import AdminOrders from '../Admin/AdminOrders';
import BOUserProjects from '../BackOffice/Project';
import ViewBOUserProject from '../BackOffice/Project/BOUserViewProject';
import BOUserEditProject from '../BackOffice/Project/BOUserEditProject';
import BOCustomersList from '../BackOffice/Customer';
import BOEditCustomer from '../BackOffice/Customer/BOEdit Customer';
import BOViewCustomer from '../BackOffice/Customer/BOViewCustomer';
import ModuleSubscribe from '../Common/Component/ModuleSubscription/moduleSubscribe';
import BOOrders from '../BackOffice/BOOrders';
import EditGroup from '../Common/Component/Groups/EditGroup';
import EditLocation from '../Common/Component/Location/EditLocation';
import ViewLocation from '../Common/Component/Location/ViewLocation';
import EmissionList from '../Common/Component/CarbonEmissionData/EmissionList';
import OrgSummary from '../Common/Component/OrgSummary';
import BuyOffsetsList from '../Common/Component/BuyOffsets/BuyOffsetsList';
import CategoryList from '../Common/Component/CreditRates/CategoryList';
import EditCategory from '../Common/Component/CreditRates/EditCategory';
import ExchangeRateList from '../Common/Component/ExchangeRate/ExchangeRateList';
import EditExchangeRate from '../Common/Component/ExchangeRate/EditExchangeRate';
import AsmAdminEditProject from '../ASMAdmin/AsmAdminProjectList/AsmAdminEditProject';
import CarbonTrading from '../Common/Component/CarbonTrading';
import CommonDashboard from '../Common/Component/Dashboard/CommonDashboard';
import ViewOrders from '../Common/Component/CarbonTrading/Orders/ViewOrders';
import GroupList from '../Common/Component/Groups/GroupList';
import LocationList from '../Common/Component/Location/LocationList';
import EvStationList from '../Common/Component/EvStations/EvStationList';
import ViewEvStation from '../Common/Component/EvStations/ViewEvStation';
import EditEvStation from '../Common/Component/EvStations/EditEvStation';
import EvChargerConsumptionList from '../Common/Component/EvChargerConsumption/EvChargerConsumptionList';
import EvChargerList from '../Common/Component/EvChargers/EvChargerList';
import EditEvCharger from '../Common/Component/EvChargers/EditEvCharger';
import EvStationConsumptionList from '../Common/Component/EvStationConsumption/EvStationConsumption';
import ConsumptionHistory from '../Common/Component/EvChargerConsumption/ConsumptionHistory';
import SubOrganizationList from '../Common/Component/SubOrganization/SubOrganizationList';
import EditSubOrganization from '../Common/Component/SubOrganization/EditSuborganization';
import ViewSubOrganization from '../Common/Component/SubOrganization/ViewSubOrganization';
import OwnershipHistory from '../Common/Component/OwnershipHistory';
import Monitoring from '../Common/Component/Monitoring';
import MonitoringHistory from '../Common/Component/MonitoringHistory/MonitoringHistory';
import MonitoringDocUpload from '../Common/Component/MonitoringDocUpload/MonitoringDocUpload';
import TargetSetting from '../Common/Component/TargetSetting';
import Offset from '../Common/Component/Offset';
import OffsetProjectList from '../Common/Component/BuyOffsets/OffsetProjectList';
import OffsetProjectDetails from '../Common/Component/BuyOffsets/OffsetProjectDetails';
import OrderReview from '../Common/Component/BuyOffsets/OrderReview';
import ProjectInitiation from '../Common/Component/ProjectInitiation';
import LandingPage from '../Common/Component/Settings/LandingPage';
import TargetSettingView from '../Common/Component/TargetSetting/TargetSettingView';
import ReportDownload from '../User/Dashboard/ReportDownload';

const ghg_emission_request = {
        "moduleId": "GHGEmissions",
        "reportId": "GHGEmissionDashboard"
};

const gri_request = {
        "moduleId": "GHGEmissions",
        "reportId": "GRIDashboard"
};

const lighting_request = {
        "moduleId": "LIGHTING",
        "reportId": "LIGHTINGDashboard"
};

const hvac_request = {
        "moduleId": "HVAC",
        "reportId": "HVACDashboard"
};

const aqi_request = {
        "moduleId": "AQI",
        "reportId": "AQIDashboard"
};

const dimming_request = {
        "moduleId": "SmartEnergy-Lighting",
        "reportId": "Dimming"
};

const lifespan_request = {
        "moduleId": "SmartEnergy-Lighting",
        "reportId": "Lifespan"
};

const dimming_weather_request = {
        "moduleId": "SmartEnergy-Lighting",
        "reportId": "Dimming_Weather"
};

const energy_cost_request = {
        "moduleId": "SmartEnergy-Lighting",
        "reportId": "Energy_Cost"
};

const target_net_zero_request = {
        "moduleId": "GHGEmissions",
        "reportId": "Target-Net-Zero-Emission"
};

const esg_scope1 = {
        "moduleId": "GHGEmissions",
        "reportId": "Scope1"
};

const esg_scope2 = {
        "moduleId": "GHGEmissions",
        "reportId": "Scope2"
};

const esg_scope3 = {
        "moduleId": "GHGEmissions",
        "reportId": "Scope3"
}

const customer_dashboard = {
        "moduleId": "Customer",
        "reportId": "CustomerProjectList"
}

export const routes = [
        //Green-Carbon Pages
        { path: `${process.env.PUBLIC_URL}/user/project-initiation/:origin`, Component: <UserProjectInit /> },
        { path: `${process.env.PUBLIC_URL}/user/projects`, Component: <Projects /> },
        { path: `${process.env.PUBLIC_URL}/user/dashboard`, Component: <CommonDashboard /> },
        { path: `${process.env.PUBLIC_URL}/user/ghg-accounting`, Component: <PowerBiReport request_param={ghg_emission_request} /> },
        { path: `${process.env.PUBLIC_URL}/user/lighting-dashboard`, Component: <PowerBiReport request_param={lighting_request} /> },
        { path: `${process.env.PUBLIC_URL}/user/hvac-dashboard`, Component: <PowerBiReport request_param={hvac_request} /> },
        { path: `${process.env.PUBLIC_URL}/user/aqi-dashboard`, Component: <PowerBiReport request_param={aqi_request} /> },
        { path: `${process.env.PUBLIC_URL}/user/gri-dashboard`, Component: <PowerBiReport request_param={gri_request} /> },
        { path: `${process.env.PUBLIC_URL}/user/target-net-zero-emission`, Component: <PowerBiReport request_param={target_net_zero_request} /> },
        { path: `${process.env.PUBLIC_URL}/user/ghg-accounting`, Component: <PowerBiReport request_param={ghg_emission_request} /> },

        { path: `${process.env.PUBLIC_URL}/user/forecasted-dimming-dashboard`, Component: <PowerBiReport request_param={dimming_request} /> },
        { path: `${process.env.PUBLIC_URL}/user/saved-energy-dashboard`, Component: <PowerBiReport request_param={energy_cost_request} /> },
        { path: `${process.env.PUBLIC_URL}/user/dimming-manager-dashboard`, Component: <PowerBiReport request_param={dimming_weather_request} /> },
        { path: `${process.env.PUBLIC_URL}/user/forecasted-lifespan-dashboard`, Component: <PowerBiReport request_param={lifespan_request} /> },

        { path: `${process.env.PUBLIC_URL}/edit-profile`, Component: <EditProfile /> },
        { path: `${process.env.PUBLIC_URL}/user/payment`, Component: <PaymentMethod /> },
        { path: `${process.env.PUBLIC_URL}/user/subscription`, Component: <Subscription /> },
        { path: `${process.env.PUBLIC_URL}/user/view-project/:id`, Component: <ViewUserProject /> },
        { path: `${process.env.PUBLIC_URL}/user/edit-project/:id`, Component: <EditUserProject /> },
        { path: `${process.env.PUBLIC_URL}/user/wallet-summary`, Component: <WalletSummary /> },
        { path: `${process.env.PUBLIC_URL}/user/wallet-details`, Component: <WalletDetailsPage /> },
        { path: `${process.env.PUBLIC_URL}/user/carbon-trading/trading`, Component: <CarbonTrading /> },
        { path: `${process.env.PUBLIC_URL}/user/carbon-trading/orders`, Component: <ViewOrders /> },
        { path: `${process.env.PUBLIC_URL}/user/carbon-trading/orders-details/:id`, Component: <OrderDetails /> },
        { path: `${process.env.PUBLIC_URL}/user/group-list`, Component: <GroupList /> },
        { path: `${process.env.PUBLIC_URL}/user/groups/:id`, Component: <EditGroup /> },
        { path: `${process.env.PUBLIC_URL}/user/location-list`, Component: <LocationList /> },
        { path: `${process.env.PUBLIC_URL}/user/edit-location/:id`, Component: <EditLocation /> },
        { path: `${process.env.PUBLIC_URL}/user/view-location/:id`, Component: <ViewLocation /> },
        { path: `${process.env.PUBLIC_URL}/user/carbon-emission-list`, Component: <EmissionList /> },
        { path: `${process.env.PUBLIC_URL}/user/organization-summary`, Component: <OrgSummary /> },
        { path: `${process.env.PUBLIC_URL}/user/buy-offsets`, Component: <OffsetProjectList /> },
        { path: `${process.env.PUBLIC_URL}/user/offset-project-details/:id`, Component: <OffsetProjectDetails /> },
        { path: `${process.env.PUBLIC_URL}/user/offset-order-review/:id`, Component: <OrderReview /> },
        { path: `${process.env.PUBLIC_URL}/user/target-settings`, Component: <TargetSetting /> },
        { path: `${process.env.PUBLIC_URL}/user/ev-station-lists/:id/:name`, Component: <EvStationList /> },
        { path: `${process.env.PUBLIC_URL}/user/view-ev-station/:id`, Component: <ViewEvStation /> },
        { path: `${process.env.PUBLIC_URL}/user/edit-ev-station/:id`, Component: <EditEvStation /> },
        { path: `${process.env.PUBLIC_URL}/user/ev-charging-consumption/:id/:name`, Component: <EvChargerConsumptionList /> },
        { path: `${process.env.PUBLIC_URL}/user/monitoring/:id/:name/:type(/:frequency)`, Component: <Monitoring /> },
        { path: `${process.env.PUBLIC_URL}/user/ev-charger-lists/:id/:name`, Component: <EvChargerList /> },
        { path: `${process.env.PUBLIC_URL}/user/edit-ev-charger/:id`, Component: <EditEvCharger /> },
        { path: `${process.env.PUBLIC_URL}/user/ev-station-consumption/:id/:name`, Component: <EvStationConsumptionList /> },
        { path: `${process.env.PUBLIC_URL}/user/ev-consumption-history/:id/:name`, Component: <ConsumptionHistory /> },
        { path: `${process.env.PUBLIC_URL}/user/monitoring-document-upload/:id/:name`, Component: <MonitoringDocUpload /> },
        { path: `${process.env.PUBLIC_URL}/user/monitoring-history/:id/:name/:type`, Component: <MonitoringHistory /> },
        { path: `${process.env.PUBLIC_URL}/user/ownership-history/:id/:name`, Component: <OwnershipHistory /> },
        { path: `${process.env.PUBLIC_URL}/user/settings/landing-page`, Component: <LandingPage /> },
        { path: `${process.env.PUBLIC_URL}/user/downloads`, Component: <ReportDownload /> },
        //Backoffice pages
        { path: `${process.env.PUBLIC_URL}/back-office/dashboard`, Component: <UserDashboard /> },
        { path: `${process.env.PUBLIC_URL}/back-office/view-edit/:action/:id`, Component: <ViewBOUserProject /> },
        { path: `${process.env.PUBLIC_URL}/back-office/edit-project/:id`, Component: <BOUserEditProject /> },
        { path: `${process.env.PUBLIC_URL}/back-office/projects`, Component: <BOUserProjects /> },
        { path: `${process.env.PUBLIC_URL}/back-office/customer-list`, Component: <BOCustomersList /> },
        { path: `${process.env.PUBLIC_URL}/back-office/edit-customer/:id`, Component: <BOEditCustomer /> },
        { path: `${process.env.PUBLIC_URL}/back-office/view-customer/:id`, Component: <BOViewCustomer /> },
        { path: `${process.env.PUBLIC_URL}/back-office/asm-management/:id`, Component: <ASMMapping /> },
        { path: `${process.env.PUBLIC_URL}/back-office/project/:id/credits-issued`, Component: <IssuedCredits /> },
        { path: `${process.env.PUBLIC_URL}/back-office/orders`, Component: <BOOrders /> },
        { path: `${process.env.PUBLIC_URL}/back-office/module-origanization`, Component: <ModuleSubscribe /> },

        //Admin pages
        { path: `${process.env.PUBLIC_URL}/admin/customer-list`, Component: <CustomersList /> },
        { path: `${process.env.PUBLIC_URL}/admin/user-management`, Component: <UserManagement /> },
        { path: `${process.env.PUBLIC_URL}/admin/edit-user`, Component: <EditUser /> },
        { path: `${process.env.PUBLIC_URL}/admin/projects`, Component: <AdminProjects /> },
        { path: `${process.env.PUBLIC_URL}/admin/view-project/:id`, Component: <ViewAdminProject /> },
        { path: `${process.env.PUBLIC_URL}/admin/edit-project/:id`, Component: <AdminEditProject /> },
        { path: `${process.env.PUBLIC_URL}/admin/edit-customer/:id`, Component: <EditCustomer /> },
        { path: `${process.env.PUBLIC_URL}/admin/asm-management/:id`, Component: <ASMMapping /> },
        { path: `${process.env.PUBLIC_URL}/admin/project/:id/credits-issued`, Component: <IssuedCredits /> },
        { path: `${process.env.PUBLIC_URL}/admin/view-customer/:id`, Component: <ViewCustomer /> },
        { path: `${process.env.PUBLIC_URL}/admin/dashboard`, Component: <PowerBiReport request_param={customer_dashboard} /> },
        { path: `${process.env.PUBLIC_URL}/admin/orders`, Component: <AdminOrders /> },
        { path: `${process.env.PUBLIC_URL}/admin/module-origanization`, Component: <ModuleSubscribe /> },
        { path: `${process.env.PUBLIC_URL}/admin/project/:id/certify-credits`, Component: <CertifyCredits /> },
        { path: `${process.env.PUBLIC_URL}/admin/credit-rates`, Component: <CategoryList /> },
        { path: `${process.env.PUBLIC_URL}/admin/credit-rates/edit/:id`, Component: <EditCategory /> },
        { path: `${process.env.PUBLIC_URL}/admin/exchange-rate-list`, Component: <ExchangeRateList /> },
        { path: `${process.env.PUBLIC_URL}/admin/exchange-rates/edit/:id`, Component: <EditExchangeRate /> },
        { path: `${process.env.PUBLIC_URL}/admin/sub-organization-list`, Component: <SubOrganizationList /> },
        { path: `${process.env.PUBLIC_URL}/admin/edit-sub-organization`, Component: <EditSubOrganization /> },
        { path: `${process.env.PUBLIC_URL}/admin/view-sub-organization/:id`, Component: <ViewSubOrganization /> },
        { path: `${process.env.PUBLIC_URL}/admin/project-initiation/:origin`, Component: <ProjectInitiation /> },

        //Asset Manager Pages
        { path: `${process.env.PUBLIC_URL}/asm-user/projects`, Component: <ProponentProjects /> },
        { path: `${process.env.PUBLIC_URL}/asm-user/project-initiation`, Component: <AsmUserProjectInit /> },
        { path: `${process.env.PUBLIC_URL}/asm-user/view-project`, Component: <ViewAsmUserProject /> },
        { path: `${process.env.PUBLIC_URL}/asm-user/ev-station-lists/:id/:name`, Component: <EvStationList /> },
        { path: `${process.env.PUBLIC_URL}/asm-user/view-ev-station/:id`, Component: <ViewEvStation /> },
        { path: `${process.env.PUBLIC_URL}/asm-user/edit-ev-station/:id`, Component: <EditEvStation /> },
        //Assessor pages
        { path: `${process.env.PUBLIC_URL}/assessor/dashboard`, Component: <UserDashboard /> },
        { path: `${process.env.PUBLIC_URL}/assessor/projects`, Component: <AssessorProjects /> },
        { path: `${process.env.PUBLIC_URL}/assessor/view-edit/:action/:id`, Component: <ViewAssessorProject /> },
        { path: `${process.env.PUBLIC_URL}/assessor/project/:id/credits-issued`, Component: <IssuedCredits /> },
        { path: `${process.env.PUBLIC_URL}/assessor/project/:id/certify-credits`, Component: <CertifyCredits /> },
        { path: `${process.env.PUBLIC_URL}/assessor/edit-project/:id`, Component: <EditAssessorProject /> },

        //Aso Admin
        { path: `${process.env.PUBLIC_URL}/aso-admin/project-initiation/:origin`, Component: <AsoAdminProjectInit /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/projects`, Component: <AsoAdminProjectList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/view-project/:id`, Component: <ViewAsoAdminProject /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/edit-project/:id`, Component: <AsoAdminEditProject /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/user-list`, Component: <AsoUserlist /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/edit-aso-user/:id`, Component: <EditAsoUser /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/dashboard`, Component: <CommonDashboard /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/wallet-summary`, Component: <WalletSummary /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/wallet-details`, Component: <WalletDetailsPage /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/carbon-trading/trading`, Component: <CarbonTrading /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/carbon-trading/orders`, Component: <ViewOrders /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/carbon-trading/orders-details/:id`, Component: <OrderDetails /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/group-list`, Component: <GroupList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/groups/:id`, Component: <EditGroup /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/location-list`, Component: <LocationList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/edit-location/:id`, Component: <EditLocation /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/view-location/:id`, Component: <ViewLocation /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/carbon-emission-list`, Component: <EmissionList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/organization-summary`, Component: <OrgSummary /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ev-station-lists/:id/:name`, Component: <EvStationList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/view-ev-station/:id`, Component: <ViewEvStation /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/edit-ev-station/:id`, Component: <EditEvStation /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ev-charging-consumption/:id/:name`, Component: <EvChargerConsumptionList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/monitoring/:id/:name/:type?/:frequency`, Component: <Monitoring /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ev-charger-lists/:id/:name`, Component: <EvChargerList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/edit-ev-charger/:id`, Component: <EditEvCharger /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ev-station-consumption/:id/:name`, Component: <EvStationConsumptionList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ev-consumption-history/:id/:name`, Component: <ConsumptionHistory /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/monitoring-history/:id/:name/:type`, Component: <MonitoringHistory /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/monitoring-document-upload/:id/:name`, Component: <MonitoringDocUpload /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/sub-organization-list`, Component: <SubOrganizationList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/edit-sub-organization/:id`, Component: <EditSubOrganization /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/view-sub-organization/:id`, Component: <ViewSubOrganization /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/module-origanization`, Component: <ModuleSubscribe /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ownership-history/:id/:name`, Component: <OwnershipHistory /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/downloads`, Component: <ReportDownload /> },

        { path: `${process.env.PUBLIC_URL}/aso-admin/ghg-accounting`, Component: <PowerBiReport request_param={ghg_emission_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/lighting-dashboard`, Component: <PowerBiReport request_param={lighting_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/hvac-dashboard`, Component: <PowerBiReport request_param={hvac_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/aqi-dashboard`, Component: <PowerBiReport request_param={aqi_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/gri-dashboard`, Component: <PowerBiReport request_param={gri_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/target-net-zero-emission`, Component: <PowerBiReport request_param={target_net_zero_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ghg-emission-dashboard-scope1`, Component: <PowerBiReport request_param={esg_scope1} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ghg-emission-dashboard-scope2`, Component: <PowerBiReport request_param={esg_scope2} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/ghg-emission-dashboard-scope3`, Component: <PowerBiReport request_param={esg_scope3} /> },

        { path: `${process.env.PUBLIC_URL}/aso-admin/forecasted-dimming-dashboard`, Component: <PowerBiReport request_param={dimming_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/saved-energy-dashboard`, Component: <PowerBiReport request_param={energy_cost_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/dimming-manager-dashboard`, Component: <PowerBiReport request_param={dimming_weather_request} /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/forecasted-lifespan-dashboard`, Component: <PowerBiReport request_param={lifespan_request} /> },

        { path: `${process.env.PUBLIC_URL}/aso-admin/buy-offsets`, Component: <OffsetProjectList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/offset-project-details/:id`, Component: <OffsetProjectDetails /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/offset-order-review/:id`, Component: <OrderReview /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/target-settings`, Component: <TargetSettingView /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/offsets`, Component: <Offset /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/settings/landing-page`, Component: <LandingPage /> },

        //Asset Manager Admin
        { path: `${process.env.PUBLIC_URL}/asm-admin/project-initiation/:origin`, Component: <AsmAdminProjectInit /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/projects`, Component: <AsmAdminProjectList /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/view-project/:id`, Component: <ViewAsmAdminProject /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/edit-project/:id`, Component: <AsmAdminEditProject /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/dashboard`, Component: <CommonDashboard /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/wallet-summary`, Component: <WalletSummary /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/wallet-details`, Component: <WalletDetailsPage /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/carbon-trading/trading`, Component: <CarbonTrading /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/carbon-trading/orders`, Component: <ViewOrders /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/carbon-trading/orders-details/:id/:orgId`, Component: <OrderDetails /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/group-list`, Component: <GroupList /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/groups/:id`, Component: <EditGroup /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/location-list`, Component: <LocationList /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/edit-location/:id`, Component: <EditLocation /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/view-location/:id`, Component: <ViewLocation /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/carbon-emission-list`, Component: <EmissionList /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/organization-summary`, Component: <OrgSummary /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/ghg-emission-dashboard`, Component: <PowerBiReport request_param={ghg_emission_request} /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/lighting-dashboard`, Component: <PowerBiReport request_param={lighting_request} /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/hvac-dashboard`, Component: <PowerBiReport request_param={hvac_request} /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/aqi-dashboard`, Component: <PowerBiReport request_param={aqi_request} /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/gri-dashboard`, Component: <PowerBiReport request_param={gri_request} /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/forecasted-dimming-dashboard`, Component: <PowerBiReport request_param={dimming_request} /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/saved-energy-dashboard`, Component: <PowerBiReport request_param={energy_cost_request} /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/dimming-manager-dashboard`, Component: <PowerBiReport request_param={dimming_weather_request} /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/forecasted-lifespan-dashboard`, Component: <PowerBiReport request_param={lifespan_request} /> },

        { path: `${process.env.PUBLIC_URL}/aso-admin/buy-offsets`, Component: <OffsetProjectList /> },
        { path: `${process.env.PUBLIC_URL}/aso-admin/offset-project-details/:id`, Component: <OffsetProjectDetails /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/user-list`, Component: <AsmUserlist /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/edit-asm-user/:id`, Component: <EditAsmUser /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/ev-station-lists/:id/:name`, Component: <EvStationList /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/view-ev-station/:id`, Component: <ViewEvStation /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/edit-ev-station/:id`, Component: <EditEvStation /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/ev-charging-consumption/:id/:name`, Component: <EvChargerConsumptionList /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/monitoring/:id/:name/:type?/:frequency`, Component: <Monitoring /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/ev-charger-lists/:id/:name`, Component: <EvChargerList /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/edit-ev-charger/:id`, Component: <EditEvCharger /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/ev-station-consumption/:id/:name`, Component: <EvStationConsumptionList /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/ev-consumption-history/:id/:name`, Component: <ConsumptionHistory /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/monitoring-document-upload/:id/:name`, Component: <MonitoringDocUpload /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/monitoring-history/:id/:name/:type`, Component: <MonitoringHistory /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/monitoring/:id/:name/:type?/:frequency`, Component: <Monitoring /> },
        { path: `${process.env.PUBLIC_URL}/asm-admin/ownership-history/:id/:name`, Component: <OwnershipHistory /> },

        //Assessor Admin
        { path: `${process.env.PUBLIC_URL}/asr-admin/projects`, Component: <AsrAdminProjectList /> },
        { path: `${process.env.PUBLIC_URL}/asr-admin/view-project/:id`, Component: <ViewAsrAdminProject /> },
        { path: `${process.env.PUBLIC_URL}/asr-admin/edit-project/:id`, Component: <EditAsrAdminProject /> },
        { path: `${process.env.PUBLIC_URL}/asr-admin/user-list`, Component: <AsrUserlist /> },
        { path: `${process.env.PUBLIC_URL}/asr-admin/edit-asr-user/:id`, Component: <EditAsrUser /> },
        { path: `${process.env.PUBLIC_URL}/asr-admin/project/:id/credits-issued`, Component: <IssuedCredits /> },
        { path: `${process.env.PUBLIC_URL}/asr-admin/project/:id/certify-credits`, Component: <CertifyCredits /> },
        { path: `${process.env.PUBLIC_URL}/asr-admin/edit-project/:id`, Component: <EditAsrAdminProject /> },
        { path: `${process.env.PUBLIC_URL}/asr-admin/dashboard`, Component: <UserDashboard /> }
];


