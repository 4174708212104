import { useStore } from 'react-redux';

const fetchWrapper = (url, options) => {
  const authToken = sessionStorage.getItem('accessToken');
  return new Promise((resolve, reject) => {
    const requestOptions = {
      body:
        options?.body instanceof FormData
          ? options?.body
          : JSON.stringify(options?.body),
      method: options?.method || "GET",
      headers: options?.headers || {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${authToken}`
      },
      // credentials: "include",
    };
    const parseJSON = (response) => {
      return new Promise((resolv, rej) =>
        response
          .json()
          .then((json) => {
            resolv({
              status: response.status,
              ok: response.ok,
              json,
            });
          })
          .catch((err) => {
            rej(response);
          })
      );
    };
    fetch(url, requestOptions)
      .then(parseJSON)
      .then((response) => {
        if (response.ok) {
          return resolve(response);
        }
        return reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default fetchWrapper;
