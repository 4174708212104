import React, { Fragment, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Badge, Label, Button, FormGroup, Spinner } from 'reactstrap';
import { Breadcrumbs, H3 } from '../../../AbstractElements';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import DataTableComponent from '../DataTable/DataTableComponent';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { Eye, Edit, Trash2 } from 'react-feather';
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import { defaultUserType } from '../../api/helper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import ButtonLoader from '../ButtonLoader';


const EvStationConsumptionList = () => {
    const projectId = useParams()?.id;
    const projectName = useParams()?.name;
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false)
    const userType = useSelector(state => state.user?.userType);
    const [year, setYear] = useState(new Date());
    const [month, setMonth] = useState(new Date());
    const [quarter, setQuarter] = useState('');
    const [semiAnnual, setSemiannual] = useState('');
    const [reportPeriodFreq, setReportPeriodFreq] = useState('YEARLY');
    const [editableValues, setEditableValues] = useState();
    const gridRef = useRef(null)
    const { register, handleSubmit, formState: { errors }, trigger } = useForm();
    const navigate = useNavigate();
    const [projectData, setProjectData] = useState();

    const loadProjectData = async() => {
        await fetchWrapper(`${API_URL}/projects/${projectId}`)
        .then((res) => {
            const data = res.json;
            setReportPeriodFreq(data?.dataCollectionFrequency)
            setProjectData(res.json)
        }, (err) => console.log(err))
    }

    const getGridData = () => {
        if (gridRef.current) {
            const gridApi = gridRef.current.api;
            const data = gridApi.getModel().rowsToDisplay.map((rowNode) => {
                return {
                    'stationId': rowNode.data.id,
                    'actualConsumption': rowNode.data.actualConsumption
                }
            });
            setEditableValues(data)
            return data;
        }
        return [];
    }



    const columnData = [
        {
            headerName: 'Station Name',
            field: 'name',
            filter: true,
            flex: 2,
            cellClass: 'cell-overflow', 
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true
        },
        {
            headerName: 'Country',
            field: 'country',
            filter: true,
            flex: 1,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true
        },
        {
            headerName: 'State',
            field: 'state',
            filter: true,
            flex: 1,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true
        },
        {
            headerName: 'City',
            field: 'city',
            filter: true,
            flex: 1.5,
            valueGetter: (params) => {
                return params.data.city + ' - ' + params.data.zipCode
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true
        },
        {
            headerName: 'Address',
            field: 'addressLine1',
            filter: true,
            cellRenderer: (params) => {
                return (<div>{`${params.data.addressLine1}, ${params.data.addressLine2}`}</div>)
            },
            flex: 2,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['reset']
            },
            suppressMovable: true,
            cellClassRules: {
                'cell-wrap': params => params.value?.length > 21
            }
        },
        {
            headerName: 'Consumption (KWH)',
            field: 'actualConsumption',
            editable: true,
            cellEditor: 'agNumberCellEditor',
            singleClickEdit: true,
            cellEditorPopup: false,
            cellEditorParams: {
                min: 0,
                precision: 2,
            },
            cellClass: 'text-input-field',
            suppressMovable: true
        },
    ]

    useEffect(() => {
        loadProjectData();
    }, [])

    const loadStationData = async() =>{
        await fetchWrapper(`${API_URL}/evstation/project/${projectId}`)
            .then((res) => {
                setLoading(false)
                setTableData(res.json)
            }, (err) => { console.log(err) })
    }

    const onSubmit = async () => {
        let evData = getGridData();
        if (reportPeriodFreq === 'MONTHLY' && !month) {
            toast.error('Please select Month..')
            return
        } else if (reportPeriodFreq === 'QUARTERLY' && !quarter) {
            toast.error('Please select Quarter..')
            return
        } else if (reportPeriodFreq === 'HALFYEARLY' && !semiAnnual) {
            toast.error('Please select Semi-Annual..')
            return
        } else if (!reportPeriodFreq) {
            toast.error('Please select Period..')
            return
        } else {
            let periodData;
            if (reportPeriodFreq === 'MONTHLY' && month) {
                periodData = moment(month).format('M');
            }
            if (reportPeriodFreq === 'QUARTERLY' && quarter) {
                periodData = moment(quarter).quarter();
            }
            if (reportPeriodFreq === 'HALFYEARLY' && semiAnnual) {
                periodData = semiAnnual;
            }
            let bodyData = {
                reportingPeriodFrequency: reportPeriodFreq,
                reportingPeriod: new Date(year).getFullYear(),
                reportingPeriodFrequencyValue: Number(periodData),
                monitoringLevel:"STATION",
                projectId,
                evConsumption: [...evData]
            }
            const options = {
                method: 'POST',
                body: bodyData
            }
            await fetchWrapper(`${API_URL}/evconsumption`, options)
                .then((res) => {
                    if (res.status === 200) {
                        toast.success('EV Station Consumption Added Successfully!')
                        loadStationData()
                    } else if(res.status === 406) {
                        toast.error(JSON.stringify(res.json))
                    }
                }, (err) => {
                    console.log(err)
                    if (err.status === 200) {
                        toast.success('EV Station Consumption Added Successfully!')
                        loadStationData()
                    } else if(err.status === 406) {
                        toast.error(JSON.stringify(err.json))
                    }
                })
        }
    }

    const onGridReady = useCallback(async (params) => {
        gridRef.current.api.showLoadingOverlay();
        await fetchWrapper(`${API_URL}/evstation/project/${projectId}`)
            .then((res) => {
                setLoading(false)
                gridRef.current.api.hideOverlay();
                setTableData(res.json)
            }, (err) => { console.log(err) })
    }, []);

    const handleBack = () => {
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/projects`)
    }

    const handleHistory = () => {
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[userType]}/ev-consumption-history/${projectId}/${projectName}`)
    }

    return (
        <Fragment>
            <Breadcrumbs parent="EV Charges List" title="List" mainTitle={`${projectName} - Monitoring`} />
            <Card>
                <CardBody>
                    <H3 attrH3={{ className: 'title-color' }}>EV Station Consumption</H3>
                    <div className='mtop-2'>
                        <Row>
                            <Col lg='3'>
                                <FormGroup>
                                    <Label>Data Collection Frequency</Label>
                                    <select className='form-select' value={reportPeriodFreq} 
                                    disabled={projectData?.dataCollectionFrequency}
                                        {...register('reportingPeriodFrequency', { required: true })}
                                        onChange={(e) => setReportPeriodFreq(e.target.value)}
                                    >
                                        <option value=''>Select Period</option>
                                        <option value={'MONTHLY'}>Month</option>
                                        <option value={'QUARTERLY'}>Quarter</option>
                                        <option value={'HALFYEARLY'}>Semi Annual</option>
                                        <option value={'YEARLY'}>Annual</option>
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col lg='3'>
                                <FormGroup>
                                    <Label>Select Year</Label>
                                    <DatePicker
                                        className="datepicker-here form-control"
                                        selected={year}
                                        onChange={(e) => setYear(e)}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        maxDate={new Date()}
                                    />
                                </FormGroup>
                            </Col>

                            {
                                reportPeriodFreq === 'MONTHLY' && <Col lg='3'>
                                    <FormGroup>
                                        <Label>Select Month</Label>
                                        <DatePicker
                                            className="datepicker-here form-control"
                                            selected={month ? month : new Date()}
                                            onChange={(e) => setMonth(e)}
                                            showMonthYearPicker
                                            dateFormat="MMM"
                                            placeholderText="-Month-"
                                            maxDate={new Date()}
                                        />
                                    </FormGroup>
                                </Col>
                            }
                            {
                                reportPeriodFreq === 'QUARTERLY' && <Col lg='3'>
                                    <FormGroup>
                                        <Label>Select Quarter</Label>
                                        <DatePicker
                                            className="datepicker-here form-control"
                                            selected={quarter ? quarter : new Date()}
                                            onChange={(e) => setQuarter(e)}
                                            showQuarterYearPicker
                                            dateFormat="QQQ"
                                            placeholderText="-Quarter-"
                                            maxDate={new Date()}
                                        />
                                    </FormGroup>
                                </Col>
                            }
                            {
                                reportPeriodFreq === 'HALFYEARLY' && <Col lg='3'>
                                    <FormGroup>
                                        <Label>Select Semi Annual</Label>
                                        <select className='form-select' 
                                        onChange={(e) => setSemiannual(e.target.value)}
                                    >
                                        <option value=''>-Semi Annual-</option>
                                        <option value={1}>Jan - June</option>
                                        <option value={2}>Jul - Dec</option>
                                        
                                    </select>
                                    </FormGroup>
                                </Col>
                            }
                        </Row>
                    </div>
                    <Row>
                        <Col lg={12}>
                            <div className='ev-station-consumption'>
                                <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                    <AgGridReact
                                        columnDefs={columnData}
                                        rowData={tableData}
                                        ref={gridRef}
                                        rowModelType='clientSide'
                                        domLayout='autoHeight'
                                        animateRows={true}
                                        getRowStyle={() => {
                                            return {
                                                background: '#fafafa'
                                            }
                                        }}
                                        overlayLoadingTemplate={
                                            `<div class="custom-loading-overlay">
                                                <i class="fa fa-spinner fa-spin"></i> <span class='f-15'>Please wait while your data are loading...</span>
                                            </div>`
                                        }
                                        overlayNoRowsTemplate={
                                            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">This is a custom \'no rows\' overlay</span>'
                                        }
                                        onGridReady={onGridReady}
                                        enterNavigatesVerticallyAfterEdit={true}
                                        stopEditingWhenCellsLoseFocus={true}
                                        pagination={true}
                                        paginationPageSize={10}
                                        paginationPageSizeSelector={[5, 10, 15, 20]}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='d-flex mtop-2'>
                        {
                            loading ? <ButtonLoader color={'primary'} loaderText={'Submitting'} /> : <Button color='primary' onClick={onSubmit}>Submit</Button>
                        }
                        
                        <Button color='secondary' className='ml-1' onClick={handleBack}>Back</Button>
                        
                        <Button color='primary' className='trans-button' onClick={handleHistory}>History View/Update</Button>
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default EvStationConsumptionList;